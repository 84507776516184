import { useQuery } from "@tanstack/react-query";
import { fetchWithAuthorization } from "../fetchWithAutorization";

export const useGetReview = ({
  tripId,
  enabled,
}: {
  tripId?: number;
  enabled?: boolean;
}) => {
  const { data, refetch, status, error, isLoading } = useQuery({
    queryKey: ["review", tripId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await fetchWithAuthorization(`reviews/?trip=${tripId}`, {
        method: "GET",
      });

      if (res.ok) {
        return res.json();
      }

      throw new Error();
    },
    gcTime: 0,
    enabled: enabled ?? true,
  });
  return {
    data,
    refetch,
    status,
    error,
    isLoading,
  };
};
