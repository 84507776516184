export const getDurationKey = (value) => {
    switch (value) {
        case 'time_cost_max=3600': {
            return 'До 1 часа'
        };
        case 'time_cost_min=3600&time_cost_max=7200': {
            return '1-2 часа'
        };
        case 'time_cost_max=86400': {
            return 'До 1 дня'
        };
        case 'time_cost_min=86400': {
            return 'Больше 1 дня'
        };
        case 'time_cost_min=172800': {
            return 'Больше 2 дней'
        };
    }
}