import { Box } from "@mui/material";
import { WrapperProps } from "./Wrapper.props";
import React from "react";

export const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return (
    <Box>
      {children}
    </Box>
  );
};
