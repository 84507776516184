import { Box, Stack } from "@mui/material";
import React from "react";
import styles from "./CreateTourTooltip.module.css";

export const CreateTourTooltip = ({
  openPointEditor,
  openGuideEditor,
}: any) => {
  return (
    <Stack className={styles.tooltipWrapper}>
      <Box
        className={styles.tooltipBtn}
        onClick={openPointEditor}
        sx={{
          "&:hover": {
            background: "var(--Surface-Hover, rgba(255, 255, 255, 0.20))",
          },
        }}
      >
        Добавить точку
      </Box>
      <Box
        className={styles.tooltipBtn}
        onClick={openGuideEditor}
        sx={{
          "&:hover": {
            background: "var(--Surface-Hover, rgba(255, 255, 255, 0.20))",
          },
        }}
      >
        Добавить участок пути
      </Box>
    </Stack>
  );
};
