import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ToursMapMarker from "./ToursMapMarker/ToursMapMarker";
import { DefaultButton } from "../../components/ui-kit/Button/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ToursMapFilters } from "./ToursMapFilters/ToursMapFilters";
import TourCard from "../../components/ui-kit/TourCard/TourCard";
import ToursMapFiltersTag from "./ToursMapFiltersTag/ToursMapFiltersTag";
import { getDurationKey } from "../../utils/getDurationKeys";
import { MapContainer } from "../../components/MapKit/MapContainer/MapContainer";
import { Marker } from "../../components/MapKit/Marker/Marker";
import { useGetToursMap } from "../../api/hooks/useGetToursMap";
import { SearchAddressInput } from "../../components/ui-kit/SearchAddressInput/SearchAddressInput";
import maplibregl, { LngLatLike, Map } from "maplibre-gl";
import { DefaultMapMarker } from "../../components/blocks/CreateTour/MapMarker/MapMarker";
import CircleIcon from "@mui/icons-material/Circle";
import { createRoot } from "react-dom/client";

export const ToursMap = () => {
  const [map, setMap] = useState<Map | null>(null);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [currentTour, setCurrentTour] = useState<any>(null);
  const [circleRadius, setCircleRadius] = useState<any>(30);

  const [userCoord, setUserCoord] = useState<any>(null);
  const [currentSearchValue, setCurrentSearchValue] = useState<any>();

  const [tourDuration, setTourDuration] = useState<string>("");
  const [tourExpenses, setTourExpenses] = useState<number[]>([0, 0]);

  const [priceFilters, setPriceFilters] = useState<any>({
    min: -1,
    max: -1,
  });

  //`trips/?page=${page}&per_page=20&distance=lat=${circleCoord?.lat};lon=${
  //         circleCoord?.lng
  //       };rad=${circleRadius}&${tourDuration}${
  //         tourExpenses[0] ? `&expenses_min=${tourExpenses[0]}` : ""
  //       }${tourExpenses[1] > 0 ? `&expenses_max=${tourExpenses[1]}` : ""}`,

  const { data: toursMapData, refetch } = useGetToursMap({
    circleCoord: currentSearchValue?.value || {
      lat: 55.74,
      lng: 37.61,
    },
    circleRadius: circleRadius,
    tourDuration: tourDuration,
  });

  useEffect(() => {
    if (toursMapData) {
      const expensesArr = toursMapData
        ?.map((item: any) => {
          return Number(item?.trip_description?.expenses);
        })
        .filter((num: any) => num >= 0)
        .sort(function (a: number, b: number) {
          return a - b;
        });

      setTourExpenses([0, expensesArr[expensesArr?.length - 1]]);
      setPriceFilters({
        max: expensesArr[expensesArr?.length - 1],
        min: expensesArr[0],
      });
    }
  }, [toursMapData]);

  useEffect(() => {
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          // update the value of userlocation variable
          setUserCoord({ lat: latitude, lng: longitude });
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (map) {
      map.flyTo({
        center: currentSearchValue?.value,
        speed: 2,
        curve: 1,
        zoom: 10,
      });
      if (!!toursMapData && toursMapData.length > 0) {
        const latitudes = toursMapData
          ?.map((item: any) => [
            item?.point?.coordinates[1],
            item?.point?.coordinates[0],
          ])
          .map((point: any) => point[1]);
        const longitudes = toursMapData
          ?.map((item: any) => [
            item?.point?.coordinates[1],
            item?.point?.coordinates[0],
          ])
          .map((point: any) => point[0]);
        const bounds: [LngLatLike, LngLatLike] = [
          [Math.min(...(longitudes || [])), Math.min(...(latitudes || []))], // Юго-западная точка
          [Math.max(...(longitudes || [])), Math.max(...(latitudes || []))], // Северо-восточная точка
        ];

        map.fitBounds(bounds, {
          padding: {
            top: 228,
            bottom: 128,
            left: 128,
            right: 128,
          },
          maxZoom: 10,
        });
      }
    }
  }, [currentSearchValue, map, refetch, toursMapData]);

  useEffect(() => {
    if (!map || !currentTour) return;
    console.log(currentTour);
    map.flyTo({
      center: [
        currentTour?.point?.coordinates[1],
        currentTour?.point?.coordinates[0],
      ],
      speed: 2,
      curve: 1,
      zoom: 13,
    });
  }, [currentTour, map]);

  return (
    <>
      <Stack
        sx={{
          position: "absolute",
          top: "32px",
          zIndex: "1000",
          left: "168px",
        }}
      >
        <SearchAddressInput
          currentIndex={0}
          displayIndex={false}
          handleCurrentValue={setCurrentSearchValue}
          sx={{
            width: "346px",
            marginBottom: "16px",
          }}
        />
        <Box
          sx={{
            display: currentTour?.id ? "block" : "none",
          }}
        >
          <TourCard
            postedAt={currentTour?.posted_at}
            key={currentTour?.id}
            user={currentTour?.user}
            title={currentTour?.title}
            posted={currentTour?.posted}
            description={currentTour?.trip_description?.short_description}
            altDescription={currentTour?.trip_description?.description}
            expenses={currentTour?.trip_description?.expenses}
            distance={currentTour?.trip_description?.distance}
            time={currentTour?.trip_description?.time_costs}
            points={currentTour?.trip_description?.points?.length}
            picture={currentTour?.trip_description?.media}
            id={currentTour?.id}
            column
            handleClose={() => setCurrentTour(null)}
          />
        </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          zIndex: "1000",
          top: "32px",
          right: "64px",
        }}
        gap="4px"
        direction="column"
        alignItems="end"
      >
        <Stack marginBottom="16px" direction="row" gap="4px">
          {circleRadius > 0 && (
            <ToursMapFiltersTag
              text="Радиус поиска"
              value={`${circleRadius} км`}
              handleDelete={() => {
                setCircleRadius(0);
                setTimeout(() => {
                  refetch();
                }, 0);
              }}
            />
          )}
          {tourDuration !== "" && (
            <ToursMapFiltersTag
              text="Длительность тура"
              value={getDurationKey(tourDuration)}
              handleDelete={() => {
                setTourDuration("");
                refetch();
              }}
            />
          )}
          {tourExpenses[1] > 0 &&
            (tourExpenses[0] !== priceFilters?.min ||
              tourExpenses[1] !== priceFilters?.max) && (
              <ToursMapFiltersTag
                text="Доп. траты"
                value={`${tourExpenses[0]}-${tourExpenses[1]}`}
                handleDelete={() => {
                  setTourExpenses([priceFilters?.min, priceFilters?.max]);
                  refetch();
                }}
              />
            )}
        </Stack>
        {!openFilters && (
          <DefaultButton
            sx={{
              maxWidth: "138px",
            }}
            onClick={() => {
              setOpenFilters(true);
            }}
          >
            <FilterListIcon
              sx={{
                width: "16px",
                height: "16px",
                marginRight: "8px",
              }}
            />
            Фильтры
          </DefaultButton>
        )}
        <ToursMapFilters
          circleRadius={circleRadius}
          setCircleRadius={setCircleRadius}
          priceFilters={priceFilters}
          expensesRange={toursMapData
            ?.map((item: any) => {
              return Number(item?.trip_description?.expenses);
            })
            ?.filter((num: any) => num >= 0)
            ?.sort(function (a: number, b: number) {
              return a - b;
            })}
          setTourDuration={setTourDuration}
          tourDuration={tourDuration}
          toursData={toursMapData}
          tourExpenses={tourExpenses}
          setTourExpenses={setTourExpenses}
          open={openFilters}
          refetch={refetch}
          handleClear={() => {
            setTourDuration("");
            setTourExpenses([priceFilters?.min, priceFilters?.max]);
            refetch();
          }}
          handleClose={() => {
            setOpenFilters(false);
          }}
        />
      </Stack>
      <MapContainer
        mapRefSetter={setMap}
        style={{
          height: "100vh",
          width: "calc(100vw + 122px)",
          transform: "translateX(-122px)",
          position: "absolute",
        }}
        center={[37.61, 55.74]}
        zoom={10}
      >
        {toursMapData &&
          toursMapData?.length > 0 &&
          toursMapData?.map((item: any) => {
            return (
              <Marker
                key={
                  item?.id +
                  currentTour?.trip_description.id +
                  new Date().getDate()
                }
                onMarkerClick={(e) => {
                  e.stopPropagation();
                  setCurrentTour(item);
                }}
                coordinates={[
                  item?.point?.coordinates[1],
                  item?.point?.coordinates[0],
                ]}
                icon={
                  <DefaultMapMarker
                    selected={
                      currentTour?.trip_description.id ===
                      item?.trip_description.id
                    }
                    filled
                    number={
                      <CircleIcon
                        sx={{
                          width: "16px",
                          height: "16px",
                          color:
                            currentTour?.trip_description.id ===
                            item?.trip_description.id
                              ? "white"
                              : "rgba(129, 199, 40, 1)",
                        }}
                      />
                    }
                  />
                }
              />
            );
          })}
      </MapContainer>
    </>
  );
};
