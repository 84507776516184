import { useQuery } from "@tanstack/react-query";
import { fetchWithAuthorization } from "../fetchWithAutorization";

export const useGetRouteParts = ({
  routePartId,
  enabled,
  key,
}: {
  routePartId?: number;
  enabled: boolean;
  key?: number;
}) => {
  const { data, refetch, status, error, isLoading } = useQuery({
    queryKey: ["route_parts_retrieve", key],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await fetchWithAuthorization(`route_parts/${routePartId}/`, {
        method: "GET",
      });

      if (res.ok) {
        return res.json();
      }

      throw new Error();
    },
    gcTime: 0,
  });
  return {
    data,
    refetch,
    status,
    error,
    isLoading,
  };
};
