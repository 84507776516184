import { Stack } from "@mui/material";
import styles from "./CreateTourControl.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import { DefaultModal } from "../../../ui-kit/DefaultModal/DefaultModal";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { DarkButton, DefaultButton } from "../../../ui-kit/Button/Button";

export const CreateTourControl = ({
  handleOpenGuideEditor,
  openGuideEditor,
  openPointEditor,
  handleOpenPointEditor,
  handleCurrentRoutePart,
  handleStep,
  getRouteData,
  handleOnlyCreatePoint,
  handleDeleteTour,
  handlePublishTour,
  step,
  routeParts,
  previewMapData,
  routePartErrors,
}: any) => {
  const navigate = useNavigate();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const [confirmSaveModal, setConfirmSaveModal] = useState<boolean>(false);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        padding: "16px 32px",
        width: "100%",
        background: "#181A16",
        zIndex: "1000",
      }}
    >
      {(openGuideEditor || openPointEditor) && (
        <>
          {" "}
          <DarkButton
            onClick={() => {
              getRouteData();
              if (openGuideEditor) {
                handleOpenGuideEditor(false);
              }
              if (openPointEditor) {
                handleOpenPointEditor(false);
                handleOnlyCreatePoint(false);
              }
              handleCurrentRoutePart(null);
              navigate("route-parts/");
            }}
            sx={{
              width: "100%",
            }}
          >
            Назад
          </DarkButton>
          <DefaultButton
            onClick={() => {
              getRouteData();
              if (openGuideEditor) {
                handleOpenGuideEditor(false);
              }
              if (openPointEditor) {
                handleOpenPointEditor(false);
                handleOnlyCreatePoint(false);
                handleCurrentRoutePart(null);
                navigate("route-parts/");
              }
            }}
            sx={{
              width: "100%",
            }}
          >
            Сохранить
            <CheckIcon
              sx={{
                marginRight: "0 !important",
                marginLeft: "8px",
                height: "16px",
              }}
            />
          </DefaultButton>
        </>
      )}
      {!openGuideEditor && !openPointEditor && (
        <>
          {step > 0 && (
            <DarkButton
              onClick={() => {
                if (step < 3 && step > 0) {
                  if (step === 2) {
                    navigate("route-parts/");
                  }
                  if (step === 1) {
                    navigate("main/");
                  }
                  handleStep((prev: number) => prev - 1);
                }
              }}
              sx={{
                width: "100%",
              }}
            >
              <ArrowBackIcon className={styles.buttonIcon} />
              Назад
            </DarkButton>
          )}
          <DarkButton
            onClick={() => setConfirmDeleteModal(true)}
            sx={{
              width: "100%",
              color: "white",
            }}
          >
            <DeleteOutlineIcon className={styles.buttonIcon} />
            Удалить тур
          </DarkButton>
          <DarkButton
            onClick={() => setConfirmSaveModal(true)}
            sx={{
              width: "100%",
              color: "white",
            }}
          >
            <SaveIcon className={styles.buttonIcon} />
            Сохранить тур
          </DarkButton>
          {step !== 2 && (
            <DefaultButton
              onClick={() => {
                if (step === 0) {
                  navigate("route-parts/");
                  handleStep((prev: number) => (prev += 1));
                }

                if (step === 1 && routeParts?.length > 0) {
                  if (previewMapData?.length > 0) {
                    navigate("preview/");
                    handleStep((prev: number) => (prev += 1));
                  } else {
                    enqueueSnackbar(
                      "Для того, чтобы отобразить маршрут на карте, необходимо добавить координаты всем участкам  маршрута",
                      { variant: "error" }
                    );
                  }
                }
              }}
              sx={{
                width: '100%',
                opacity: step === 1 && !routeParts?.length ? 0.5 : 1,
              }}
            >
              Далее
              <ArrowForwardIcon
                // className={styles.buttonIcon}
                sx={{
                  marginRight: "0 !important",
                  marginLeft: "8px",
                  height: "16px",
                }}
              />
            </DefaultButton>
          )}
        </>
      )}
      <DefaultModal
        open={confirmDeleteModal}
        handleClose={() => setConfirmDeleteModal(false)}
        handleAction={handleDeleteTour}
        actionText="Удалить тур"
        title="Подтвердите удаление тура"
        content="Вы уверены, что хотите удалить тур? Отменить действие будет невозможно"
        altText="Закрыть"
      />
      <DefaultModal
        open={confirmSaveModal}
        handleClose={() => setConfirmSaveModal(false)}
        handleAction={handlePublishTour}
        actionText="Опубликовать тур"
        title="Ваш тур успешно создан!"
        content="Вы можете опубликовать тур или оставить его в сохраненных и опубликовать позднее."
        altText="Сохранить без публикации"
        altAction={() => {
          navigate("/tours/");
        }}
      />
    </Stack>
  );
};
