import { Slider, styled } from "@mui/material";

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "#a1b670", //color of the slider between thumbs
  "& .MuiSlider-thumb": {
    height: '20px',
    width: '20px',
    backgroundColor: 'white', //color of thumbs
    border: '4.5px #a1b670 solid'
  },
  "& .MuiSlider-rail": {
    color: "white",
  },
  
}));
