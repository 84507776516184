import { z } from "zod";

export const editProfileValidation = z.object({
  username: z.string(),
  email: z.string(),
  first_name: z.string(),
  middle_name: z.string(),
  last_name: z.string(),
  gender: z.string(),
  birthdate: z.string().nullable(),
  description: z.string(),
  show_email: z.boolean(),
  phone_number: z.string(),
  telegram_username: z.string(),
  show_telegram: z.boolean()
});
