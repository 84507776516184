import { Box, Stack } from "@mui/material";
import React from "react";
import styles from "./TourCard.module.css";
import { TourCardSlider } from "./TourCardSlider";
import { TourContent } from "./TourContent";
import { TourMetaData } from "./TourMetaData";
import { TourCardFeedback } from "./TourCardFeedback";

const TourCard = ({
  isMapOpened,
  title,
  description,
  price,
  time,
  points,
  distance,
  picture,
  user,
  altDescription,
  posted,
  selected,
  handleClick,
  expenses,
  id,
  column,
  handleClose,
  isOnlyCreated,
  created,
  routePartsCount,
  createdAt,
  postedAt,
  review,
  evaluationCount,
  handleOpenReview,
  avgRating,
  inFavorite,
  displayReviews,
}: any) => {
  return (
    <Box
      className={`tour-card-${id}`}
      sx={{
        border: selected ? "2px #A1B670 solid" : "2px rgba(0,0,0,0) solid",
      }}
      onClick={handleClick}
    >
      {review?.[0]?.id && (
        <TourCardFeedback
          user={review?.[0]?.review_user}
          isOnlyCreated={isOnlyCreated}
          postedAt={postedAt}
          createdAt={createdAt}
          review={review?.[0]}
          isToursList={true}
        />
      )}
      <Stack
        direction={column ? "column" : "row"}
        gap="16px"
        className={styles.tourCardWrapper}
        sx={{
          width: column ? "320px" : "640px",
        }}
      >
        {column && (
          <>
            {" "}
            <TourMetaData
              created={created}
              isOnlyCreated={isOnlyCreated}
              user={user}
              posted={posted}
              column={column}
              handleClose={handleClose}
              createdAt={createdAt}
              postedAt={postedAt}
              evaluationCount={evaluationCount}
              avgRating={avgRating}
              id={id}
              inFavorite={inFavorite}
            />
            <h2 className={styles.tourContentTitle}>{title}</h2>
          </>
        )}
        {picture?.length > 0 && (
          <TourCardSlider picture={picture} column={column} />
        )}

        <TourContent
          routePartsCount={routePartsCount}
          created={created}
          isOnlyCreated={isOnlyCreated}
          isMapOpened={isMapOpened}
          title={title}
          description={description}
          price={price}
          expenses={expenses}
          time={time}
          points={points}
          distance={distance}
          user={user}
          picture={picture}
          altDescription={altDescription}
          posted={posted}
          id={id}
          column={column}
          createdAt={createdAt}
          postedAt={postedAt}
          review={review?.[0]}
          evaluationCount={evaluationCount}
          handleOpenReview={handleOpenReview}
          avgRating={avgRating}
          inFavorite={inFavorite}
          displayReviews={displayReviews}
        />
      </Stack>
    </Box>
  );
};

export default TourCard;
