import { useMutation } from "@tanstack/react-query";
import { fetchWithAuthorization } from "./fetchWithAutorization";
import { useMutationQueryParams } from "./ApiInterfaces";
import { FetchError } from "./FetchError";

export const useMutationQuery = ({
  url,
  headers,
  method,
  onSuccess,
  onError,
}: useMutationQueryParams) => {
  const { data, mutate, status, error, isSuccess, isPending } = useMutation({
    mutationFn: async (data: any) => {
      const res = await fetchWithAuthorization(url, {
        headers,
        method,
        body: data instanceof FormData ? data : JSON.stringify(data),
      });

      if (res.status === 204) {
        return "Success"; // Возвращаем строку, чтобы удовлетворить react-query
      }

      if (res.ok) {
        return res.json();
      }

      throw new FetchError(res);
    },
    onSuccess,
    onError,
  });
  return {
    data,
    mutate,
    status,
    error,
    isSuccess,
    isPending,
  };
};
