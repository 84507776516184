import React from "react";
import styles from "./Profile.module.css";
import { Stack } from "@mui/material";

export const ProfileParamsItem = ({ value, param }: any) => {
  return (
    <Stack direction="row" gap="4px">
      <p
        className={styles.profileText}
        style={{
          color: "rgba(137, 160, 83, 1)",
          fontWeight: 700
        }}
      >
        {value}
      </p>
      <p
        className={styles.profileText}
        style={{
          color: "rgba(137, 160, 83, 1)",
        }}
      >
        {param}
      </p>
    </Stack>
  );
};
