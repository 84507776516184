import React from "react";
import styles from "./TourDetailCardTooltip.module.css";
import { Stack, Button } from "@mui/material";

export const TourDetailCardTooltip = ({ handleDelete, handleEdit }: any) => {
  return (
    <Stack className={styles.tooltipWrapper}>
      <Button className={styles.tooltipBtn} onClick={handleEdit}>
        Редактировать
      </Button>
      <Button className={styles.tooltipBtn} onClick={handleDelete}>
        Удалить
      </Button>
    </Stack>
  );
};
