import { Box } from "@mui/material";
import React, { useState } from "react";
import { Cropper } from "react-advanced-cropper";
import { DefaultLoader } from "../DefaultLoader/DefaultLoader";
import Skeleton from "@mui/material/Skeleton";

const AvatarPreview = ({ imgSrc, options, width, height }: any) => {
  const [avatarSrc, setIsAvatarSrc] = useState<any>(null);

  const onReadyCrop = (cropper: any) => {
    cropper.setCoordinates({
      height: options?.height,
      left: options?.left,
      top: options?.top,
      width: options?.width,
    });

    setTimeout(() => {
      const canvas = cropper.getCanvas();
      const performerImage = canvas?.toDataURL();
      setIsAvatarSrc(performerImage);
    }, 100);
  };

  return (
    <div>
      <Box
        sx={{
          position: "absolute",
          opacity: "0",
          pointerEvents: "none",
          maxHeight: height ? `${height}px` : "128px",
          maxWidth: height ? `${height}px` : "128px",
        }}
      >
        <Cropper src={imgSrc} onReady={onReadyCrop} className={"cropper"} />
      </Box>
      <Box
        sx={{
          position: "relative",
          width: width ? width : 128,
          height: height ? height : 128,
          borderRadius: "50%",
          filter:
            "drop-shadow(0px 0.4px 0.8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06))",
          "&.hide-border": {
            border: "none",
          },
          "& img": {
            // border: "3px solid #fff",
            borderRadius: "50%",
            objectFit: "cover",
            width: "100%",
            height: "100%",
          },
        }}
      >
        {avatarSrc ? (
          <img
            src={avatarSrc}
            alt=" "
            // style={{
            //   minWidth: "128px",
            //   maxWidth: "128px",
            //   minHeight: "128px",
            //   maxHeight: "128px",
            //   borderRadius: "128px",
            //   border: "2px solid rgba(255, 255, 255, 0.10)",
            //   boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.40)",
            // }}
          />
        ) : (
          <Skeleton variant="circular" width={width ? width : 128} height={height ? height : 128}/>
        )}
      </Box>
    </div>
  );
};

export default AvatarPreview;
