/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "../CreateTourMap.module.css";

import { Box, Stack } from "@mui/material";
import { useMutationQuery } from "../../../../../api/useMutationQuery";
import { DarkButton, DefaultButton } from "../../../../ui-kit/Button/Button";
import { sortByPriority } from "../../../../../utils/sortByPriority";
import { Divider } from "../../../../ui-kit/Divider/Divider";
import { PlotPoint } from "./PlotPoint/PlotPoint";
import CheckIcon from "@mui/icons-material/Check";
import { DefaultMapMarker } from "../../MapMarker/MapMarker";
import { useLocation } from "react-router";
import { useGetRouteParts } from "../../../../../api/hooks/useGetRouteParts";
import { MapContainer } from "../../../../MapKit/MapContainer/MapContainer";
import { Marker } from "../../../../MapKit/Marker/Marker";
import { CreatePlot } from "../../../../MapKit/CreatePlot/CreatePlot";
import { Map } from "maplibre-gl";
import { SearchAddressInput } from "../../../../ui-kit/SearchAddressInput/SearchAddressInput";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CreatePlotZoomControl } from "./CreatePlotZoomControl";

const CreatePlotMap = ({
  handleClose,
  handleSave,
  createPlotData,
  isOnlyCreatePlot,
}: any) => {
  const location = useLocation();

  const { data: routePartRetrieveData, refetch: getRoutePartData } =
    useGetRouteParts({
      routePartId: Number(location?.pathname?.split("/")[3]),
      enabled: !!location?.pathname?.split("/")[3],
    });

  const [map, setMap] = useState<Map | null>(null);
  const [selectedPoint, setSelectedPoint] = useState<any>();
  const [points, setPoints] = useState<any>();
  const [mapCoordinates, setMapCoordinates] = React.useState<any>({
    lat: null,
    lng: null,
  });
  const [creatingPoint, setCreatingPoint] = useState<boolean>(false);
  const [editPointLocation, setEditPointLocation] = useState<boolean>(false);
  const [updateKey, setUpdateKey] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(7);

  const { mutate: updatePlotPoint, data: createPointData } = useMutationQuery({
    url: `plots/check_point_update/${selectedPoint?.id}/`,
    method: "PATCH",
  });

  const { mutate: createCheckPoint } = useMutationQuery({
    url: `plots/${routePartRetrieveData?.data?.plot?.id}/check_point_create/`,
    method: "POST",
  });

  const { mutate: deletePlotPoint } = useMutationQuery({
    url: `plots/${routePartRetrieveData?.data?.plot?.id}/check_point_delete/`,
    method: "DELETE",
  });

  const { mutate: swapCheckPoint, status: swapCheckPointStatus } =
    useMutationQuery({
      url: `plots/check_point_swap/${selectedPoint?.id}/`,
      method: "POST",
    });

  useEffect(() => {
    if (
      swapCheckPointStatus === "error" ||
      swapCheckPointStatus === "success"
    ) {
      getRoutePartData();
    }
  }, [swapCheckPointStatus]);
  const plotPointDragStartHandler = (e: any, item: any) => {
    setSelectedPoint(item);
  };
  const plotPointDragEndHandler = (e: any) => {
    setSelectedPoint(null);
  };

  const plotPointDragLeaveHandler = (e: any) => {};

  const plotPointDragOverHandler = (e: any) => {
    e.preventDefault();
  };

  const plotPointDropHandler = (e: any, item: any) => {
    e.preventDefault();
    swapCheckPoint({
      priority: item.priority,
    });
  };

  useEffect(() => {
    setPoints(routePartRetrieveData?.data?.plot?.check_points);
    setUpdateKey((prev) => prev + 1);
  }, [
    createPlotData,
    routePartRetrieveData?.data?.plot?.check_points,
    isOnlyCreatePlot,
  ]);

  const pointsUpdate = (index: any, newValue: any) => {
    setPoints((prevState: any) => {
      prevState[index] = newValue;
      return prevState;
    });
  };

  useEffect(() => {
    if (points && createPointData?.data?.id) {
      pointsUpdate(
        points?.map((item: any) => item?.id).indexOf(createPointData?.data?.id),
        createPointData?.data
      );
      setSelectedPoint(null);
      setEditPointLocation(false);
      setCreatingPoint(false);
    }
  }, [createPointData?.data, createPointData?.data?.id, points]);

  useEffect(() => {
    if (createPointData?.data?.id) {
      setUpdateKey((prev) => prev + 1);
    }
  }, [createPointData?.data?.id]);

  useEffect(() => {
    if (points) {
      if (
        points?.sort(sortByPriority)[0]?.coordinate?.coordinates?.length !== 2
      ) {
        setSelectedPoint(points?.sort(sortByPriority)[0]);
        setEditPointLocation(true);
      }
      if (
        points?.sort(sortByPriority)[0]?.coordinate?.coordinates?.length ===
          2 &&
        points?.sort(sortByPriority)[1]?.coordinate?.coordinates?.length !== 2
      ) {
        setEditPointLocation(true);
        setSelectedPoint(points?.sort(sortByPriority)[1]);
      }
    }
  }, [points, selectedPoint]);

  useEffect(() => {
    if (creatingPoint) {
      localStorage.setItem("mode", "createPoint");
    } else if (editPointLocation) {
      localStorage.setItem("mode", "editPoint");
    } else {
      localStorage.removeItem("mode");
    }
  }, [creatingPoint, editPointLocation]);

  const [currentSearchValue, setCurrentSearchValue] = useState<any>();

  useEffect(() => {
    if (map) {
      map.flyTo({
        center: currentSearchValue?.value,
        speed: 2,
        curve: 1,
        zoom: 13,
      });

      // if (editPointLocation) {
      //   updatePlotPoint(
      //     {
      //       coordinate: {
      //         type: "Point",
      //         coordinates: [
      //           currentSearchValue?.value?.lat,
      //           currentSearchValue?.value?.lng,
      //         ],
      //       },
      //     },
      //     {
      //       onSuccess: () => {
      //         map.flyTo({
      //           center: currentSearchValue?.value,
      //           speed: 2,
      //           curve: 1,
      //         });
      //       },
      //     }
      //   );
      // }
      // if (creatingPoint) {
      //   createCheckPoint(
      //     {
      //       coordinate: {
      //         type: "Point",
      //         coordinates: [
      //           currentSearchValue?.value?.lat,
      //           currentSearchValue?.value?.lng,
      //         ],
      //       },
      //     },
      //     {
      //       onSuccess: () => {
      //         getRoutePartData();
      //       },
      //     }
      //   );
      // }
    }
  }, [currentSearchValue]);

  return (
    <>
      <Box
        sx={{
          minWidth: "304px",
          maxWidth: "304px",
          position: "absolute",
          zIndex: "1000",
          right: 16,
          top: "32px",
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        <h3 className={styles.pointsBlockTitle}>Список точек участка пути</h3>
        <DefaultButton
          disabled={
            !points?.sort(sortByPriority)[0]?.coordinate ||
            !points?.sort(sortByPriority)[1]?.coordinate
          }
          onClick={() => {
            if (editPointLocation) {
              setEditPointLocation(false);
            }
            setCreatingPoint((prev) => !prev);
          }}
          sx={{
            width: "100%",
            margin: "24px 0 24px 0",
          }}
        >
          Добавить точку
        </DefaultButton>
        <Divider />
        <Stack direction="column" gap="16px">
          {points?.sort(sortByPriority)?.map((item: any, index: number) => (
            <Box
              draggable={!(index === 0 || index === points?.length - 1)}
              onDragStart={(e) => plotPointDragStartHandler(e, item)}
              onDragEnd={(e) => plotPointDragEndHandler(e)}
              onDragLeave={(e) => plotPointDragLeaveHandler(e)}
              onDragOver={(e) => plotPointDragOverHandler(e)}
              onDrop={(e) => plotPointDropHandler(e, item)}
            >
              <PlotPoint
                disabled
                selected={selectedPoint?.id === item?.id}
                isDraggable={!(index === 0 || index === points?.length - 1)}
                pointData={item}
                index={index}
                points={points}
                handleDelete={(e: Event) => {
                  e.stopPropagation();
                  deletePlotPoint(
                    {
                      id: item?.id,
                    },
                    {
                      onSuccess: () => {
                        getRoutePartData();
                        setSelectedPoint(null);
                      },
                    }
                  );
                }}
                handleClick={() => {
                  if (selectedPoint?.id === item?.id) {
                    setSelectedPoint(null);
                  } else {
                    setSelectedPoint(item);
                  }
                  if (creatingPoint) {
                    setCreatingPoint(false);
                  }
                  setEditPointLocation((prev) => !prev);
                }}
              />
            </Box>
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          minWidth: "640px",
          maxWidth: "640px",
          position: "absolute",
          zIndex: "1000",
          left: "50%",
          top: "32px",
          transform: "translateX(-50%)",
        }}
      >
        <h3 className={styles.title}>Добавление участка пути</h3>
        {!points?.sort(sortByPriority)[0]?.coordinate && (
          <h3 className={styles.title}>Укажите координаты начала маршрута!</h3>
        )}
        {points?.sort(sortByPriority)[0]?.coordinate &&
          !points?.sort(sortByPriority)[1]?.coordinate && (
            <h3 className={styles.title}>Укажите координаты конца маршрута!</h3>
          )}
        {(editPointLocation || creatingPoint) && (
          <SearchAddressInput
            displayIndex={true}
            currentIndex={
              editPointLocation
                ? points
                    ?.sort(sortByPriority)
                    ?.map((item: any) => item?.id)
                    .indexOf(selectedPoint?.id) + 1
                : points?.length === 2
                ? 2
                : points?.length
            }
            handleCurrentValue={setCurrentSearchValue}
            sx={{
              marginTop: "24px",
            }}
          />
        )}
        <Stack
          direction="column"
          gap="8px"
          sx={{
            position: "absolute",
            zIndex: 10000,
            right: 0,
            top: "40vh",
          }}
        >
          <DefaultButton
            onClick={() => {
              setZoom((prev) => (prev += 1));
            }}
            sx={{
              minWidth: "36px !important",
              maxWidth: "36px !important",
              height: "36px",
            }}
          >
            <AddIcon
              sx={{
                width: "16px",
                height: "16px",
              }}
            />
          </DefaultButton>
          <DefaultButton
            onClick={() => {
              setZoom((prev) => (prev -= 1));
            }}
            sx={{
              minWidth: "36px !important",
              maxWidth: "36px !important",
              height: "36px",
            }}
          >
            <RemoveIcon
              sx={{
                width: "16px",
                height: "16px",
              }}
            />
          </DefaultButton>
        </Stack>
      </Box>
      <Box
        sx={{
          minWidth: "640px",
          maxWidth: "640px",
          position: "absolute",
          zIndex: "1000",
          left: "50%",
          bottom: "16px",
          transform: "translateX(-50%)",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            padding: "16px",
            width: "100%",
            background: "#181A16",
            zIndex: "1000",
          }}
        >
          <DarkButton
            onClick={() => {
              setTimeout(() => {
                handleClose();
              }, 100);
            }}
            sx={{
              width: "100%",
            }}
          >
            Отмена
          </DarkButton>
          <DefaultButton
            onClick={() => {
              setTimeout(() => {
                handleClose();
              }, 10);
            }}
            sx={{
              width: "100%",
            }}
          >
            Сохранить маршрут
            <CheckIcon
              sx={{
                // color: "white !important",
                marginLeft: "8px !important",
                height: "16px",
              }}
            />
          </DefaultButton>
        </Stack>
      </Box>

      <MapContainer
        mapRefSetter={setMap}
        center={[37.36, 55.44]}
        zoom={zoom}
        style={{
          cursor: "pointer",
          backgroundColor: "#2b363a",
          height: "100vh",
          width: "calc(100vw + 122px)",
          transform: "translateX(-122px)",
          position: "absolute",
        }}
        onMouseMove={(e) => {
          setMapCoordinates(e.lngLat);
        }}
        onMapClick={(e) => {
          const mode = localStorage.getItem("mode");
          if (mode === "editPoint") {
            updatePlotPoint({
              coordinate: {
                type: "Point",
                coordinates: [e?.lngLat?.lat, e?.lngLat?.lng],
              },
            });
          }
          if (mode === "createPoint") {
            createCheckPoint(
              {
                coordinate: {
                  type: "Point",
                  coordinates: [e?.lngLat?.lat, e?.lngLat?.lng],
                },
              },
              {
                onSuccess: () => {
                  getRoutePartData();
                },
              }
            );
            setCreatingPoint(false);
          }
        }}
      >
        {creatingPoint && (
          <Marker
            coordinates={[mapCoordinates?.lng, mapCoordinates?.lat]}
            icon={
              <DefaultMapMarker
                filled
                number={points?.length === 2 ? 2 : points?.length}
              />
            }
          />
        )}
        {editPointLocation && (
          <Marker
            coordinates={[mapCoordinates?.lng, mapCoordinates?.lat]}
            icon={
              <DefaultMapMarker
                filled
                number={
                  points
                    ?.sort(sortByPriority)
                    ?.map((item: any) => item?.id)
                    .indexOf(selectedPoint?.id) + 1
                }
              />
            }
          />
        )}
        <CreatePlot
          updateKey={updateKey}
          points={points}
          updatePlotPoint={updatePlotPoint}
          handleSelectedPoint={setSelectedPoint}
        />

        <CreatePlotZoomControl zoom={zoom} />
      </MapContainer>
    </>
  );
};

export default CreatePlotMap;
