import { Stack } from "@mui/material";
import styles from "./TourDetailCardInfo.module.css";
import { convertSeconds } from "../../../../../utils/secondsConvert";

export const TourDetailCardInfo = ({ distance, time, points, price }: any) => {
  const { hours, days } = convertSeconds(Number(time));
  return (
    <Stack className={styles.wrapper} gap="12px">
      {points && (
        <Stack gap="4px">
          <h4 className={styles.cardSubTitle}>Этапов</h4>
          <h3 className={styles.cardTitle}>{points}</h3>
        </Stack>
      )}
      {(hours > 0 || days > 0) && (
        <Stack gap="4px">
          <h4 className={styles.cardSubTitle}>Врeмя на тур</h4>
          <h3 className={styles.cardTitle}>
            {days > 0 ? `${days}д.` : ""} {hours > 0 ? `${hours}ч.` : ""}
          </h3>
        </Stack>
      )}
      {price > 0 && (
        <Stack gap="4px">
          <h4 className={styles.cardSubTitle}>Траты</h4>
          <h3 className={styles.cardTitle}>{price} ₽</h3>
        </Stack>
      )}
    </Stack>
  );
};
