import React from "react";
import styles from "./MenuItem.module.css";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";

export const MenuItem = ({
  icon,
  locateTo,
  isSelected,
}: {
  icon: React.ReactNode;
  locateTo: string;
  isSelected: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Box
      className={styles.wrapper}
      onClick={() => navigate(locateTo)}
      sx={{
        backgroundColor: isSelected ? "#81C728" : "none",
      }}
    >
      {icon}
    </Box>
  );
};
