import React, { useState } from "react";
import styles from "./TourCard.module.css";
import { Box, Stack, Tooltip } from "@mui/material";
import { TourParamsInfo } from "./TourParamsInfo";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TourMetaData } from "./TourMetaData";
import { DarkButton, DefaultButton } from "../Button/Button";
import { convertSeconds } from "../../../utils/secondsConvert";
import "../../../assets/css/main.scss";
import { useNavigate } from "react-router";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TourTag from "./TourTag";
import { useMutationQuery } from "../../../api/useMutationQuery";
import { DefaultModal } from "../DefaultModal/DefaultModal";
import PinDropIcon from "@mui/icons-material/PinDrop";
import MessageIcon from "@mui/icons-material/Message";

export const TourContent = ({
  title,
  description,
  expenses,
  time,
  user,
  altDescription,
  posted,
  id,
  column,
  created,
  isOnlyCreated,
  routePartsCount,
  createdAt,
  postedAt,
  evaluationCount,
  handleOpenReview,
  avgRating,
  review,
  inFavorite,
  displayReviews,
}: any) => {
  const { currentView, days, hours } = convertSeconds(time);
  const [successPublishModal, setSuccessPublishModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { mutate: publishTour } = useMutationQuery({
    url: `trips/${id}/publish/`,
    method: "POST",
  });
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        width: "100%",
      }}
    >
      <Stack direction="column" gap="8px">
        {!column && (
          <>
            {" "}
            <TourMetaData
              user={user}
              posted={posted}
              created={created}
              isOnlyCreated={isOnlyCreated}
              createdAt={createdAt}
              postedAt={postedAt}
              avgRating={avgRating}
              id={id}
              inFavorite={inFavorite}
              evaluationCount={evaluationCount}
            />
            <h2 className={styles.tourContentTitle}>{title}</h2>
          </>
        )}
        <Stack direction="row" gap="8px">
          {routePartsCount && (
            <TourParamsInfo
              icon={
                <PinDropIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: "rgba(255, 255, 255, 0.2)",
                  }}
                />
              }
              text={routePartsCount}
            />
          )}
          {(days > 0 || hours > 0) && (
            <TourParamsInfo
              icon={
                <ScheduleOutlinedIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: "rgba(255, 255, 255, 0.2)",
                  }}
                />
              }
              text={currentView}
            />
          )}
          {expenses && (
            <TourParamsInfo
              text={
                <Tooltip
                  title={
                    <Box
                      sx={{
                        padding: "8px 16px",
                        borderRadius: "4px",
                        background: "var(--Surface-Card, rgba(0, 0, 0, 0.60))",
                        maxWidth: "219px",
                      }}
                    >
                      <p className={styles.tourDataToolTip}>
                        Дополнительные затраты во время прохождения тура до{" "}
                        {expenses} ₽
                      </p>
                    </Box>
                  }
                  placement="right"
                >
                  <HelpOutlineIcon
                    sx={{
                      position: "relative",
                      width: "12px",
                      height: "12px",
                      color: "#A1B670",
                      top: "2px",
                    }}
                  />
                </Tooltip>
              }
              icon={
                <AccountBalanceWalletOutlinedIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: "rgba(255, 255, 255, 0.2)",
                  }}
                />
              }
            />
          )}
        </Stack>
        <Stack>{!posted && <TourTag text="Не опубликован" />}</Stack>
        {!!description && (
          <p
            className={styles.tourDescription}
            style={{
              marginTop: "0",
            }}
          >
            {description}
          </p>
        )}
        {!description && altDescription && (
          <Box
            id="custom_scroll"
            sx={{
              maxHeight: "126px",
              overflowY: "scroll",
            }}
          >
            <p
              className={styles.tourDescription}
              style={{
                marginTop: "-4px",
                paddingTop: "4px",
              }}
            >
              {altDescription}
            </p>
          </Box>
        )}
      </Stack>
      <Stack
        direction="row"
        sx={{
          color: "white",
          width: "100%",
          marginTop: "16px",
        }}
        gap="8px"
      >
        <DefaultButton
          sx={{
            whiteSpace: "nowrap",
            width: "100%",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (review?.id) {
              navigate(`/reviews/${review?.id}/`);
            } else {
              navigate(`/tours/${id}/`);
            }
          }}
        >
          <VisibilityIcon
            sx={{
              width: "16px",
              height: "16px",
              marginRight: "7px",
            }}
          />{" "}
          Открыть тур
        </DefaultButton>

        {displayReviews && (
          <DarkButton
            onClick={() => handleOpenReview(true)}
            sx={{
              whiteSpace: "nowrap",
              width: "100%",
              pointerEvents: evaluationCount < 1 ? "none" : 1,
            }}
          >
            <MessageIcon
              sx={{
                width: "20px",
                height: "20px",
                color: "#81C728",
                marginRight: "8px",
              }}
            />
            <span
              style={{
                opacity: evaluationCount < 1 ? 0.3 : 1,
              }}
            >
              Отзывы
            </span>
            <span
              style={{
                opacity: 0.6,
                marginLeft: "4px",
              }}
            >
              ({evaluationCount ?? 0})
            </span>
          </DarkButton>
        )}

        {!posted && (
          <DarkButton
            onClick={() => {
              publishTour(
                {},
                {
                  onSuccess: () => {
                    setSuccessPublishModal(true);
                  },
                }
              );
            }}
            sx={{
              width: "100%",
              color: "white",
              padding: "10px 16px",
            }}
          >
            <VisibilityIcon
              sx={{
                width: "16px",
                height: "16px",
                marginRight: "7px",
              }}
            />{" "}
            Опубликовать
          </DarkButton>
        )}
      </Stack>
      <DefaultModal
        open={successPublishModal}
        handleClose={() => setSuccessPublishModal(false)}
        handleAction={() => setSuccessPublishModal(false)}
        actionText="Ок"
        title="Ваш тур успешно опубликован!"
        content="Ваш тур опубликован и доступен для других пользователей в соответствии с настройками."
        // altText="Сохранить без публикации"
        // altAction={() => {
        //   navigate("/tours/");
        // }}
      />
    </Stack>
  );
};
