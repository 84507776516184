import { useEffect } from "react";
import polyline from "@mapbox/polyline";
import { LngLatLike, Map } from "maplibre-gl";
interface MapRouteProps {
  map?: Map;
  points: [LngLatLike, LngLatLike];
  isDashed?: boolean;
  id: number;
  onRouteClick?: () => void;
  buildAllRoutes?: boolean;
}

export const MapRoute = ({
  map,
  points,
  isDashed,
  id,
  onRouteClick,
  buildAllRoutes,
}: MapRouteProps) => {
  useEffect(() => {
    if (!map && points?.length > 1) return;
    const fetchAndUpdateMap = async () => {
      try {
        const response = await fetch(
          `https://router.project-osrm.org/route/v1/routed-foot/${points?.join(
            ";"
          )}?overview=false&alternatives=true&steps=true&geometries=polyline&overview=full`
        );
        const json = await response.json();

        if (!!map && json?.routes[0]?.geometry) {
          map.addSource(`lines-${id}`, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {
                    color: "rgb(0,125,0)",
                  },
                  geometry: {
                    type: "LineString",
                    coordinates: polyline
                      .decode(json?.routes[0]?.geometry)
                      ?.map((item: number[]) => [item?.[1], item?.[0]]),
                  },
                },
              ],
            },
          });

          map.addLayer({
            id: `lines-${id}`,
            type: "line",
            source: `lines-${id}`,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-width": 4,
              "line-color": ["get", "color"],
              "line-dasharray": isDashed ? [5, 5] : [1, 0],
            },
          });
        }

        if (map && buildAllRoutes) {
          const lastIndexes = json?.routes[0]?.legs?.map(
            (item: any) =>
              item?.steps?.[item?.steps?.length - 1]?.intersections?.[0]
                ?.location
          );

          lastIndexes?.unshift(
            json?.routes[0]?.legs[0]?.steps[0]?.intersections?.[0]?.location
          );

          lastIndexes?.forEach((item: any, index: number) => {
            map.addSource(`alternatives-${index}`, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {
                      color: "#5e5e5e",
                    },
                    geometry: {
                      type: "LineString",
                      coordinates: [item, points[index]],
                    },
                  },
                ],
              },
            });
            map.addLayer({
              id: `alternatives-${index}`,
              type: "line",
              source: `alternatives-${index}`,
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-width": 4,
                "line-color": ["get", "color"],
              },
            });
          });
        }

        if (!!map && !!onRouteClick) {
          map.on("click", `lines-${id}`, function (e: { lngLat: any }) {
            onRouteClick();
          });
        }
      } catch (error) {
        console.error("Error fetching data or updating map:", error);
      }
    };

    fetchAndUpdateMap();
  }, [id, isDashed, map, onRouteClick, points]);
  return null;
};
