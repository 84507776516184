import {
  ClickAwayListener,
  InputAdornment,
  Stack,
  SxProps,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchAddress } from "../../../api/hooks/useSearchAddress";
import _ from "lodash";
import { Input } from "../TextField/TextField";
import styles from "./SearchAddressInput.module.css";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const SearchAddressInput = ({
  handleCurrentValue,
  sx,
  onInputClick,
  currentIndex,
  displayIndex,
}: {
  handleCurrentValue: (data: {
    title: string;
    value: {
      lat: number;
      lon: number;
    };
  }) => void;
  sx?: SxProps;
  currentIndex: number;
  onInputClick?: () => void;
  displayIndex: boolean;
}) => {
  const [searchParam, setSearchParam] = useState<string>("");
  const [openList, setOpenList] = useState<boolean>(false);

  const { data: addressData } = useSearchAddress({
    search: searchParam,
  });

  useEffect(() => {
    if (addressData?.length > 0) {
      setOpenList(true);
    }
  }, [addressData]);
  return (
    <ClickAwayListener onClickAway={() => setOpenList(false)}>
      <Stack direction="column" sx={sx}>
        {displayIndex && (
          <p
            className={styles.inputLabel}
            style={{
              marginBottom: "6px",
              color: "#81C728",
            }}
          >
            Точка № {currentIndex}
          </p>
        )}
        <Input
          onClick={() => {
            setOpenList((prev) => !prev);
            if (onInputClick) {
              onInputClick();
            }
          }}
          className={styles.inputField}
          onInput={_.debounce((e) => {
            const target = e.target as HTMLInputElement;
            setSearchParam(target.value);
          }, 1000)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={
                    <p className={styles.tourDataToolTip}>
                      Поиск по названию, ключевым словам, городу
                    </p>
                  }
                  placement="right"
                >
                  <HelpOutlineIcon
                    sx={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      color: "#A1B670",
                    }}
                  />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        {!openList && (
          <p className={styles.inputLabel}>
            Поиск по названию, ключевым словам, городу
          </p>
        )}

        <Stack className={styles.list} id={"custom_scroll"}>
          {openList &&
            addressData?.map(
              (item: {
                title: string;
                value: {
                  lat: number;
                  lon: number;
                };
              }) => {
                return (
                  <Stack
                    onClick={() => {
                      handleCurrentValue(item);
                      setOpenList(false);
                    }}
                    className={styles.listItem}
                  >
                    {item?.title}
                  </Stack>
                );
              }
            )}
        </Stack>
      </Stack>
    </ClickAwayListener>
  );
};
