import { styled } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";

export const CustomDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  ".MuiInputBase-input": {
    color: "white",
  },
  ".MuiIconButton-root": {
    color: "#A1B670",
  },
  color: "white !important",
  borderBottomColor: "#A1B670 !important",
  input: {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A1B670",
    },
    "&:hover fieldset": {
      borderColor: "#A1B670",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A1B670",
    },
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: "#A1B670",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#A1B670",
  },
}));

export default CustomDatePicker;
