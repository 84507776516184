import { useQuery } from "@tanstack/react-query";

export const useSearchAddress = ({
  search,
}: {
  search?: string;
}) => {
  const { data, refetch, status, error, isLoading } = useQuery({
    queryKey: ["search_address", search],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${search}&format=json&addressdetails=100`,
        {
          method: "GET",
        }
      );

      if (res.ok) {
        return res.json();
      }

      throw new Error();
    },
    gcTime: 0,
    select: (data) => {
      return data?.map(
        (item: { lon: number; lat: number; display_name: string }) => {
          return {
            value: {
              lng: Number(item?.lon),
              lat: Number(item?.lat),
            },
            title: item?.display_name,
          };
        }
      );
    },
  });
  return {
    data,
    refetch,
    status,
    error,
    isLoading,
  };
};
