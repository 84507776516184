import { useQuery } from "@tanstack/react-query";
import { fetchWithAuthorization } from "../fetchWithAutorization";

export const useGetRoutePart = ({
  tourId,
  enabled,
  step,
}: {
  step?: number;
  tourId?: number;
  enabled: boolean;
}) => {
  const { data, refetch, status, error, isLoading } = useQuery({
    queryKey: ["routePart", step],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await fetchWithAuthorization(`trips/${tourId}/map/`, {
        method: "GET",
      });

      if (res.ok) {
        return res.json();
      }

      throw new Error();
    },
    gcTime: 0,
    enabled: enabled,
  });
  return {
    data,
    refetch,
    status,
    error,
    isLoading,
  };
};
