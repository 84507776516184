export const convertSeconds = (seconds: number) => {
  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  const hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  const mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;
  return {
    days: days,
    hours: hrs,
    currentView: `${days > 0 ? `${days}д.` : ''} ${hrs > 0 ? `${hrs}ч.` : ''}`,
  };
  // return `${days}Д ${hrs}Ч ${mnts}М ${seconds}С`;
};

export const convertToSeconds = (str: string) => {
  const timeParts = str.match(/(\d+)(Д|Ч|М|С)/g);
  let seconds = 0;

  timeParts?.forEach((part) => {
    const value = parseInt(part, 10);
    if (part.includes("Д")) {
      seconds += value * 86400;
    } else if (part.includes("Ч")) {
      seconds += value * 3600;
    } else if (part.includes("М")) {
      seconds += value * 60;
    } else if (part.includes("С")) {
      seconds += value;
    }
  });

  return seconds;
};
