import { Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./TourDetailCard.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { TourDetailCardInfo } from "./TourDetailCardInfo/TourDetailCardInfo";
import { Link, useNavigate } from "react-router-dom";
import { ImagePreviewModal } from "../../../Modals/ImagePreviewModal/ImagePreviewModal";
import AvatarPreview from "../../../ui-kit/AvatarPreview/AvatarPreview";
import { sortByPriority } from "../../../../utils/sortByPriority";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { TourDetailCardTooltip } from "./TourDetailCardTooltip";
import { useMutationQuery } from "../../../../api/useMutationQuery";
import { Divider } from "../../../ui-kit/Divider/Divider";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { useAddToFavorites } from "../../../../api/hooks/useAddToFavorites";

export const TourDetailCard = ({ data, routeData }: any) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

  const { mutate: deleteTour } = useMutationQuery({
    url: `trips/${data?.id}/`,
    method: "DELETE",
  });
  const navigate = useNavigate();

  const [currentFavoritesValue, setCurrentFavoritesValue] =
    useState<boolean>(false);

  useEffect(() => {
    setCurrentFavoritesValue(data?.in_favorite);
  }, [data]);

  const { addToFav } = useAddToFavorites({
    tripId: data?.trip_description?.id,
    onSuccess: (data: any) => setCurrentFavoritesValue(data?.data?.in_favorite),
  });
  return (
    <Stack
      className={styles.cardWrapper}
      direction="column"
      sx={{
        background: `radial-gradient(137.66% 55.36% at 50% 27.65%, 
        rgba(15, 17, 14, 0.00) 20%,
         rgba(15, 17, 14, 0.60) 41%,
          #0F110E 65%), url(${data?.trip_description?.media?.[0]?.media?.media?.original})`,
        backgroundPosition: "center",
        justifyContent: "space-between",
        backgroundSize: "cover",
        border: "1px rgba(0,0,0,0) solid",
      }}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack>
            <Stack direction="row" gap="8px" alignItems="center">
              <Link to="/tours/" style={{ width: "16px", height: "16px" }}>
                <ArrowBackIosIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "#A1B670",
                  }}
                />
              </Link>
              {data?.user?.avatar_data?.media?.original ? (
                <AvatarPreview
                  imgSrc={data?.user?.avatar_data?.media?.original}
                  options={data?.user?.avatar_data?.options}
                  height={22}
                  width={22}
                />
              ) : (
                data?.user?.id && (
                  <AccountCircleOutlinedIcon
                    sx={{
                      color: "rgba(255, 255, 255, 0.2)",
                      width: "22px",
                      height: "22px",
                    }}
                  />
                )
              )}

              <Stack direction="column" gap="2px">
                <h5
                  className={styles.tourMetaDataUserLogin}
                  style={{
                    maxWidth: "124px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data?.user?.username}
                </h5>
                <h5
                  className={styles.tourMetaDataUserLogin}
                  style={{
                    color: "white",
                    opacity: "0.6",
                  }}
                >
                  {data?.posted_at
                    ? new Date(data?.posted_at * 1000).toLocaleDateString()
                    : new Date(data?.created_at * 1000).toLocaleDateString()}
                </h5>
              </Stack>
            </Stack>
            <Stack direction="row" gap="4px">
              {!data?.posted && (
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={
                    <TourDetailCardTooltip
                      handleDelete={() => {
                        deleteTour(
                          {},
                          {
                            onSuccess: () => {
                              navigate(`/profile/${data?.user?.id}`);
                            },
                          }
                        );
                      }}
                      handleEdit={() => {
                        navigate(`/edit-tour/?user=${data?.user?.id}`);
                      }}
                    />
                  }
                >
                  <MoreVertOutlinedIcon
                    sx={{
                      width: "16px",
                      height: "16px",
                      color: "#A1B670",
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" gap="8px" alignItems="center">
            <h5
              className={styles.tourMetaDataUserLogin}
              style={{
                color: "white",
                opacity: "0.6",
                marginRight: "-8px",
              }}
            >
              Оценок: {data?.elevation_count || 0}
            </h5>
            <Divider
              sx={{
                transform: "rotate(90deg)",
                width: "16px",
              }}
            />
            <h5
              className={styles.tourMetaDataRate}
              style={{
                marginLeft: "-8px",
              }}
            >
              {data?.avg_rating ? data?.avg_rating : "--"}
            </h5>
            {currentFavoritesValue ? (
              <BookmarkAddedIcon
                onClick={() => {
                  addToFav({});
                }}
                sx={{
                  width: "24px",
                  height: "24px",
                  color: "#89A053",
                  cursor: "pointer",
                }}
              />
            ) : (
              <BookmarkAddOutlinedIcon
                onClick={() => {
                  addToFav({});
                }}
                sx={{
                  width: "24px",
                  height: "24px",
                  color: "#89A053",
                  cursor: "pointer",
                }}
              />
            )}
          </Stack>
        </Stack>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
        >
          <TourDetailCardInfo
            price={data?.trip_description?.expenses}
            points={routeData?.route_parts?.length}
            time={data?.trip_description?.time_costs}
          />
        </Stack>
      </Stack>
      <Stack className={styles.cardTextBlock} justifyContent="space-between">
        <Stack gap="16px">
          <h1 className={styles.cardTitle}>{data?.title}</h1>
          <Stack gap="14px" className={styles.cardText} id="custom_scroll">
            <p>{data?.trip_description?.description}</p>
          </Stack>
          <Stack
            direction="row"
            gap="8px"
            sx={{
              maxWidth: "270px",
              overflowX: "scroll",
              paddingBottom: "8px",
            }}
            id="custom_scroll"
          >
            {data?.trip_description?.media
              .sort(sortByPriority)
              ?.map((media: any, index: number) => {
                return (
                  <Stack
                    onClick={() => {
                      setOpenPreviewModal(true);
                      setActiveIndex(index);
                    }}
                    sx={{
                      minWidth: "48px",
                      minHeight: "48px",
                      objectFit: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      background: `url(${media?.media?.media?.original}) lightgray 50% / cover no-repeat`,
                    }}
                  />
                );
              })}
          </Stack>
        </Stack>
      </Stack>
      <ImagePreviewModal
        open={openPreviewModal}
        imagesArr={data?.trip_description?.media}
        activeIndex={activeIndex}
        handleActiveIndex={setActiveIndex}
        handleClose={() => setOpenPreviewModal(false)}
      />
    </Stack>
  );
};
