import { SvgIconProps } from "@mui/material";

export const PlotIcon: React.FC<SvgIconProps> = ({ sx, ...rest }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="#00000099"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 24.36V8C32 3.58 28.42 0 24 0C19.58 0 16 3.58 16 8V28C16 30.2 14.2 32 12 32C9.8 32 8 30.2 8 28V11.64C10.32 10.8 12 8.6 12 6C12 2.68 9.32 0 6 0C2.68 0 0 2.68 0 6C0 8.6 1.68 10.8 4 11.64V28C4 32.42 7.58 36 12 36C16.42 36 20 32.42 20 28V8C20 5.8 21.8 4 24 4C26.2 4 28 5.8 28 8V24.36C25.68 25.18 24 27.38 24 30C24 33.32 26.68 36 30 36C33.32 36 36 33.32 36 30C36 27.4 34.32 25.2 32 24.36ZM6 8C4.9 8 4 7.1 4 6C4 4.9 4.9 4 6 4C7.1 4 8 4.9 8 6C8 7.1 7.1 8 6 8ZM30 32C28.9 32 28 31.1 28 30C28 28.9 28.9 28 30 28C31.1 28 32 28.9 32 30C32 31.1 31.1 32 30 32Z"
        fill="#89A053"
      />
    </svg>
  );
};
