import { Stack } from "@mui/material";
import React from "react";
import styles from "./TourCard.module.css";

export const TourParamsInfo: React.FC<any> = ({ icon, text }) => {
  return (
    <Stack
      direction="row"
      gap="4px"
      alignItems="center"
      justifyContent="center"
    >
      <Stack>{icon}</Stack>
      <p className={styles.tourParamsInfo}>{text}</p>
    </Stack>
  );
};
