/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { DefaultMapMarker } from "../../blocks/CreateTour/MapMarker/MapMarker";
import { createRoot } from "react-dom/client";
import maplibregl, { LngLatLike } from "maplibre-gl";
import polyline from "@mapbox/polyline";

export const CreatePlot = ({
  map,
  updateKey,
  points,
  updatePlotPoint,
  handleSelectedPoint,
}: any) => {
  const markersRef = useRef([]);

  const customKey = new Date().getDate();

  useEffect(() => {
    if (!map) return;
    // Очистить старые маркеры, если они были
    markersRef.current.forEach((marker) => (marker as any).remove());
    markersRef.current = [];

    points?.forEach((item: any, index: any) => {
      if (item?.coordinate?.coordinates[0]) {
        const el = document.createElement("div");
        el.id = index;

        const root = createRoot(el);
        root.render(<DefaultMapMarker filled number={index + 1} />);

        const markerEl = new maplibregl.Marker({
          element: el,
          draggable: true,
        }).setLngLat([
          item?.coordinate?.coordinates[1],
          item?.coordinate?.coordinates[0],
        ]);

        markerEl?.on("dragend", (e) => {
          handleSelectedPoint(item);
          setTimeout(() => {
            updatePlotPoint({
              coordinate: {
                type: "Point",
                coordinates: [e?.target?._lngLat?.lat, e?.target?._lngLat?.lng],
              },
            });
          }, 10);
        });
        markerEl.addTo(map);
        (markersRef.current as any).push(markerEl);
      }
    });

    const fetchAndUpdateMap = async () => {
      try {
        const response = await fetch(
          `https://router.project-osrm.org/route/v1/routed-foot/${points
            ?.map((item: any) => [
              item?.coordinate?.coordinates[1],
              item?.coordinate?.coordinates[0],
            ])
            ?.join(
              ";"
            )}?overview=false&alternatives=true&steps=true&geometries=polyline&overview=full`
        );
        const json = await response.json();

        if (!!map && json?.routes[0]?.geometry) {
          map.addSource(`lines-${customKey}`, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {
                    color: "rgb(0,125,0)",
                  },
                  geometry: {
                    type: "LineString",
                    coordinates: polyline
                      .decode(json?.routes[0]?.geometry)
                      ?.map((item: number[]) => [item?.[1], item?.[0]]),
                  },
                },
              ],
            },
          });

          map.addLayer({
            id: `lines-${customKey}`,
            type: "line",
            source: `lines-${customKey}`,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-width": 4,
              "line-color": ["get", "color"],
            },
          });
        }

        const latitudes = points
          ?.map((item: any) => [
            item?.coordinate?.coordinates[1],
            item?.coordinate?.coordinates[0],
          ])
          .map((point: any) => point[1]);
        const longitudes = points
          ?.map((item: any) => [
            item?.coordinate?.coordinates[1],
            item?.coordinate?.coordinates[0],
          ])
          .map((point: any) => point[0]);
        const bounds: [LngLatLike, LngLatLike] = [
          [Math.min(...longitudes), Math.min(...latitudes)], // Юго-западная точка
          [Math.max(...longitudes), Math.max(...latitudes)], // Северо-восточная точка
        ];

        map.fitBounds(bounds, {
          padding: {
            top: 128,
            bottom: 128,
            left: 128,
            right: 510,
          },
        });

        if (map) {
          const lastIndexes = json?.routes[0]?.legs?.map(
            (item: any) =>
              item?.steps?.[item?.steps?.length - 1]?.intersections?.[0]
                ?.location
          );

          lastIndexes?.unshift(
            json?.routes[0]?.legs[0]?.steps[0]?.intersections?.[0]?.location
          );

          lastIndexes?.forEach((item: any, index: number) => {
            map.addSource(`alternatives-${index}`, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {
                      color: "#5e5e5e",
                    },
                    geometry: {
                      type: "LineString",
                      coordinates: [
                        item,
                        points?.map((item: any) => [
                          item?.coordinate?.coordinates[1],
                          item?.coordinate?.coordinates[0],
                        ])[index],
                      ],
                    },
                  },
                ],
              },
            });
            map.addLayer({
              id: `alternatives-${index}`,
              type: "line",
              source: `alternatives-${index}`,
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-width": 4,
                "line-color": ["get", "color"],
              },
            });
          });
        }
      } catch (error) {
        console.error("Error fetching data or updating map:", error);
      }
    };

    if (
      points?.[0]?.coordinate?.coordinates?.[0] &&
      points?.[1]?.coordinate?.coordinates?.[0]
    ) {
      if (map?.getSource(`lines-${customKey}`)) {
        map?.removeSource(`lines-${customKey}`);
      }
      if (map?.getLayer(`lines-${customKey}`)) {
        map?.removeLayer(`lines-${customKey}`);
      }
      points?.forEach((item: any, index: number) => {
        if (map?.getLayer(`alternatives-${index}`)) {
          map?.removeLayer(`alternatives-${index}`);
        }
        if (map?.getSource(`alternatives-${index}`)) {
          map?.removeSource(`alternatives-${index}`);
        }
      });
      fetchAndUpdateMap();
    }
    return () => {
      markersRef.current.forEach((marker: any) => marker.remove());
      markersRef.current = [];
      if (map?.getSource(`lines-${customKey}`)) {
        map?.removeSource(`lines-${customKey}`);
      }
      if (map?.getLayer(`lines-${customKey}`)) {
        map?.removeLayer(`lines-${customKey}`);
      }
      points?.forEach((item: any, index: number) => {
        if (map?.getLayer(`alternatives-${index}`)) {
          map?.removeLayer(`alternatives-${index}`);
        }
        if (map?.getSource(`alternatives-${index}`)) {
          map?.removeSource(`alternatives-${index}`);
        }
      });
    };
  }, [map, points, updateKey]);
  return null;
};

export default CreatePlot;
