import { Stack } from "@mui/material";
import { TourDetailMap } from "./TourDetailMap/TourDetailMap";
import { TourDetailCard } from "./TourDetailCard/TourDetailCard";
import { useParams } from "react-router-dom";
import { useGetRoutePart } from "../../../api/hooks/useGetRoutePart";
import { useGetRoute } from "../../../api/hooks/useGetRoute";
import { useGetTrip } from "../../../api/hooks/useGetTrip";
import { useGetReviewDetail } from "../../../api/hooks/useGetReviewDetail";
import { useGetAdventure } from "../../../api/hooks/useGetAdventure";

export const TourDetail = () => {
  const params = useParams();

  const { data: tourDetailData } = useGetTrip({
    tourId: Number(params?.tourid),
    enabled: !!params?.tourid,
  });

  const { data: reviewDetailData } = useGetReviewDetail({
    reviewId: Number(params?.reviewid),
    enabled: !!params?.reviewid,
  });

  const { data: adventureDetailData } = useGetAdventure({
    adventureId: reviewDetailData?.data?.adventure,
    enabled: !!reviewDetailData?.data?.adventure,
  });

  const { data: previewMapData } = useGetRoutePart({
    tourId:
      Number(params?.tourid) > 0
        ? params?.tourid
        : adventureDetailData?.data?.trip?.id,
    enabled:
      Number(params?.tourid) > 0
        ? !!params?.tourid
        : !!adventureDetailData?.data?.trip?.id,
  });

  const { data: routeRetrieveData } = useGetRoute({
    route: tourDetailData?.data?.route ?? adventureDetailData?.data?.trip?.id,
    enabled: params?.tourid
      ? !!tourDetailData?.data?.route
      : !!adventureDetailData?.data?.trip?.id,
  });

  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
      }}
    >
      <Stack
        sx={{
          backgroundColor: "rgba(0,0,0,0)",
          position: "absolute",
          zIndex: 1000,
          height: "100vh",
        }}
      >
        <TourDetailCard
          data={tourDetailData?.data ?? adventureDetailData?.data?.trip}
          routeData={routeRetrieveData?.data}
        />
      </Stack>
      <>
        {previewMapData?.data && (
          <TourDetailMap
            reviewDetailData={reviewDetailData?.data}
            routeRetrieveData={routeRetrieveData}
            points={previewMapData?.data}
            step={2}
            key={previewMapData?.data?.length * new Date().getTime()}
          />
        )}
      </>
    </Stack>
  );
};
