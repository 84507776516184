import { Box, Button, Stack } from "@mui/material";
import React from "react";
import AvatarPreview from "../../../components/ui-kit/AvatarPreview/AvatarPreview";
import Avatar from "../../../assets/img/Avatar.svg";
import styles from "./UserItem.module.css";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import { DarkBorderButton } from "../../../components/ui-kit/Button/Button";

const UserItem = ({ data, onSubscribe, onUnSubscribe }: any) => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      gap="16px"
      sx={{
        width: "640px",
      }}
    >
      <Stack
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(`/profile/${data?.id}`);
        }}
      >
        {data?.avatar_data?.media?.original ? (
          <AvatarPreview
            imgSrc={data?.avatar_data?.media?.original}
            options={data?.avatar_data?.options}
            height={80}
            width={80}
          />
        ) : (
          data?.id && (
            <Box
              sx={{
                minWidth: "80px",
                width: "80px",
                height: "80px",
                background: `url(${Avatar}) lightgray 50% / cover no-repeat`,
                backgroundSize: "70%",
                backgroundColor: "rgba(0,0,0,0)",
                borderRadius: "128px",
                border: "2px solid rgba(255, 255, 255, 0.10)",
              }}
            />
          )
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
        }}
      >
        <Stack direction="column" gap="4px">
          <h2
            className={styles.userItemTitle}
            style={{
              maxWidth: "256px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/profile/${data?.id}`);
            }}
          >
            {data?.username}
          </h2>
          <h3 className={styles.userItemSubtitle}>
            {data?.last_name} {data?.first_name}
          </h3>
          <Stack direction="row" alignItems="center">
            {data?.common_subscriptions[0] && (
              <Box
                onClick={() => {
                  navigate(`/profile/${data?.common_subscriptions[0]?.id}`);
                }}
                sx={{
                  cursor: "pointer",
                  border: "3px #282822 solid",
                  zIndex: 1000,
                  borderRadius: "50%",
                }}
              >
                {data?.common_subscriptions?.[0]?.avatar_data?.media
                  ?.original ? (
                  <AvatarPreview
                    imgSrc={
                      data?.common_subscriptions[0]?.avatar_data?.media
                        ?.original
                    }
                    options={
                      data?.common_subscriptions[0]?.avatar_data?.options
                    }
                    height={24}
                    width={24}
                  />
                ) : (
                  <Box
                    sx={{
                      minWidth: "24px",
                      width: "24px",
                      height: "24px",
                      background: `url(${Avatar}) lightgray 50% / cover no-repeat`,
                      backgroundSize: "70%",
                      backgroundColor: "#282822",
                      borderRadius: "128px",
                      border: "2px solid rgba(255, 255, 255, 0.10)",
                    }}
                  />
                )}
              </Box>
            )}
            {data?.common_subscriptions?.[1] && (
              <Box
                onClick={() => {
                  navigate(`/profile/${data?.common_subscriptions[1]?.id}`);
                }}
                sx={{
                  cursor: "pointer",
                  marginLeft: "-12px",
                  border: "3px #282822 solid",
                  zIndex: 1001,
                  borderRadius: "50%",
                }}
              >
                {data?.common_subscriptions[1]?.avatar_data?.media?.original ? (
                  <AvatarPreview
                    imgSrc={
                      data?.common_subscriptions[1]?.avatar_data?.media
                        ?.original
                    }
                    options={
                      data?.common_subscriptions[0]?.avatar_data?.options
                    }
                    height={24}
                    width={24}
                  />
                ) : (
                  <Box
                    sx={{
                      minWidth: "24px",
                      width: "24px",
                      height: "24px",
                      background: `url(${Avatar}) lightgray 50% / cover no-repeat`,
                      backgroundSize: "70%",
                      backgroundColor: "#282822",
                      borderRadius: "128px",
                      border: "2px solid rgba(255, 255, 255, 0.10)",
                    }}
                  />
                )}
              </Box>
            )}
            {data?.common_subscriptions?.[2] && (
              <Box
                onClick={() => {
                  navigate(`/profile/${data?.common_subscriptions[2]?.id}`);
                }}
                sx={{
                  cursor: "pointer",
                  marginLeft: "-12px",
                  border: "3px #282822 solid",
                  zIndex: 1002,
                  borderRadius: "50%",
                }}
              >
                {data?.common_subscriptions[2]?.avatar_data?.media?.original ? (
                  <AvatarPreview
                    imgSrc={
                      data?.common_subscriptions[2]?.avatar_data?.media
                        ?.original
                    }
                    options={
                      data?.common_subscriptions[2]?.avatar_data?.options
                    }
                    height={24}
                    width={24}
                  />
                ) : (
                  <Box
                    sx={{
                      minWidth: "24px",
                      width: "24px",
                      height: "24px",
                      background: `url(${Avatar}) lightgray 50% / cover no-repeat`,
                      backgroundSize: "70%",
                      backgroundColor: "#282822",
                      borderRadius: "128px",
                      border: "2px solid rgba(255, 255, 255, 0.10)",
                    }}
                  />
                )}
              </Box>
            )}
            <p
              className={styles.userItemSubtitle}
              style={{
                marginLeft: data?.common_subscriptions?.length > 0 ? "12px" : 0,
              }}
            >
              {data?.common_subscriptions?.length ?? 0} общих
            </p>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="start" gap="64px">
          <Stack>
            <p className={styles.userItemParam}>
              Создано туров:{" "}
              <span className={styles.userItemParamValue}>
                {data?.posted_count ?? 0}
              </span>
            </p>
          </Stack>
          <Stack>
            <DarkBorderButton
              onClick={() => {
                if (!data?.is_subscribed) {
                  onSubscribe();
                }
                if (data?.is_subscribed) {
                  onUnSubscribe();
                }
              }}
              sx={{
                padding: "10px 16px",
                whiteSpace: "nowrap",
                fontSize: "12px",
                lineHeight: "12px",
                color: "white",
                border: "1px  rgba(137, 160, 83, 1) solid",
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.3)",
                },
              }}
            >
              {data?.is_subscribed ? "Отписаться" : "Подписаться"}
            </DarkBorderButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserItem;
