import { useQuery } from "@tanstack/react-query";
import { useGetQueryParams } from "./ApiInterfaces";
import { fetchWithAuthorization } from "./fetchWithAutorization";

export const useGetQuery = ({
  url,
  queryKey,
  headers,
  enabled,
}: useGetQueryParams) => {
  const { data, refetch, status, error, isLoading } = useQuery({
    queryKey: queryKey,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await fetchWithAuthorization(url, {
        headers,
        method: "GET",
      });

      if (res.ok) {
        return res.json();
      }

      throw new Error();
    },
    enabled,
    gcTime: 0,
  });
  return {
    data,
    refetch,
    status,
    error,
    isLoading,
  };
};
