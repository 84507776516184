/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styles from "./Subscribers.module.css";
import { Box, InputAdornment, Stack } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";
import { Input } from "../../components/ui-kit/TextField/TextField";
import { TabberItem } from "../../components/ui-kit/Tabber/TabberItem";
import { Tabber } from "../../components/ui-kit/Tabber/Tabber";
import { Divider } from "../../components/ui-kit/Divider/Divider";
import { fetchWithAuthorization } from "../../api/fetchWithAutorization";
import UserItem from "./UserItem/UserItem";
import _ from "lodash";
import { useMutationQuery } from "../../api/useMutationQuery";
import { DarkBorderButton } from "../../components/ui-kit/Button/Button";
import { DefaultLoader } from "../../components/ui-kit/DefaultLoader/DefaultLoader";
import { useMe } from "../../api/hooks/useMe";
import { useGetUser } from "../../api/hooks/useGetUser";

const tabs = [
  {
    id: 0,
    title: "Подписчики",
    value: "subscribers",
  },
  {
    id: 1,
    title: "Подписки",
    value: "subscriptions",
  },
];
const Subscribers = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { mutate: subscribe } = useMutationQuery({
    url: `users/subscribe/`,
    method: "POST",
  });

  const { mutate: unsubscribe } = useMutationQuery({
    url: `users/unsubscribe/`,
    method: "POST",
  });

  const [currentTab, setCurrentTab] = useState<string>("subscribers");
  const [usersList, setUsersList] = useState<any>([]);
  const [usersListMeta, setUsersListMeta] = useState<any>(null);
  const [currentQueryParams, setCurrentQueryParams] = useState<any>(
    `subscribers=${params?.userid}`
  );
  const [search, setSearch] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);

  const [refetchKey, setRefetchKey] = useState<number>(0);

  const { data: userData } = useGetUser({
    userId: Number(params?.userid),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchWithAuthorization(
      `users/?page=${1}&${currentQueryParams}&${
        search ? `search=${search}` : null
      }`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setUsersList([]);

        setUsersList([...usersList, ...res.data]);
        setUsersListMeta(res.meta);
      })
      .finally(() => {
        // Устанавливаем загрузку в false после завершения запроса вне зависимости от результата
        setIsLoading(false);
      });
  }, [currentTab, search, refetchKey]);

  const scrollToBottomListener = (e: any) => {
    const target = e.target;
    if (page < Math.ceil(usersListMeta?.total / 20)) {
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        setPage((prev) => prev + 1);
        setTimeout(() => {
          setIsLoading(true);
          fetchWithAuthorization(
            `users/?page=${page + 1}&${currentQueryParams}`,
            {
              method: "GET",
            }
          )
            .then((res) => res.json())
            .then((res) => {
              setUsersList([...usersList, ...res.data]);
            })
            .finally(() => {
              // Устанавливаем загрузку в false после завершения запроса вне зависимости от результата
              setIsLoading(false);
            });
        }, 100);
      }
    }
  };

  const { data: isMe } = useMe({
    enabled: true,
  });

  useEffect(() => {
    const stack = document.querySelector("#users");
    stack?.addEventListener("scroll", scrollToBottomListener);

    return () => {
      stack?.removeEventListener("scroll", scrollToBottomListener);
    };
  }, [usersList, usersListMeta]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams) {
      setCurrentTab(urlParams?.get("tab") || "subscribers");
      setCurrentQueryParams(`${urlParams?.get("tab")}=${params?.userid}`);
    }
  }, []);

  return (
    <Box className={styles.subscribersWrapper}>
      <Stack
        sx={{
          width: "640px",
          flexDirection: "column",
          alignItems: "center",
          gap: "32px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <Stack direction="row" gap="6px">
            {" "}
            <h1 className={styles.subscribersTitle}>Данные подписок </h1>{" "}
            <h1
              className={styles.subscribersTitle}
              style={{
                color: "#7aca14",
                maxWidth: "324px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {userData?.data?.username}
            </h1>
          </Stack>

          <DarkBorderButton
            onClick={() => {
              navigate(`/profile/${params?.userid}`);
            }}
          >
            <Stack direction="row" alignItems="center">
              <AccountCircleOutlinedIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  marginRight: "8px",
                }}
              />{" "}
              {isMe?.data?.id === params?.userid
                ? "В мой аккаунт"
                : "В аккаунт"}
            </Stack>
          </DarkBorderButton>
        </Stack>
        <Input
          sx={{
            width: "100%",
          }}
          onChange={_.debounce((e) => {
            setUsersList([]);
            setSearch(e.target.value);
          }, 1000)}
          label="Поиск"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlinedIcon
                  sx={{
                    color: "#A1B670",
                    width: "16px",
                    height: "24px ",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Stack
          direction="column"
          alignItems="flex-start"
          sx={{
            width: "100%",
          }}
        >
          <Tabber>
            {tabs.map((item: any) => {
              return (
                <TabberItem
                  selected={item?.value === currentTab}
                  handleClick={() => {
                    if (item?.value !== currentTab) {
                      setUsersList([]);
                      setUsersListMeta(null);
                      setCurrentTab(item?.value);
                      if (item?.value === "subscriptions") {
                        setCurrentQueryParams(
                          `subscriptions=${params?.userid}`
                        );
                      }
                      if (item?.value === "subscribers") {
                        setCurrentQueryParams(`subscribers=${params?.userid}`);
                      }
                    }
                  }}
                  title={
                    <Stack direction="row" alignItems="center" gap="4px">
                      {item?.value === "subscriptions" ? (
                        <UpgradeOutlinedIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "#a1b670",
                          }}
                        />
                      ) : (
                        <FileDownloadOutlinedIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "#a1b670",
                          }}
                        />
                      )}
                      {item?.title}
                    </Stack>
                  }
                />
              );
            })}
          </Tabber>
          <Divider
            sx={{
              marginTop: "2px",
            }}
          />
          <Stack
            key={refetchKey}
            id={"users"}
            sx={{
              paddingTop: "16px",
              paddingBottom: "16px",
              gap: "16px",
              overflowY: "scroll",
              flexGrow: 1,
              maxHeight: "calc(100vh - 232px)",
              width: "100%",
            }}
          >
            {isLoading ? (
              <Stack
                sx={{
                  width: "100%",
                  alignItems: "center",
                  position: "relative",
                  justifyContent: "centers",
                  marginTop: "30vh",
                }}
                direction="column"
              >
                <Stack
                  sx={{
                    maxWidth: "280px",
                  }}
                >
                  <DefaultLoader />
                </Stack>
              </Stack>
            ) : (
              <>
                {usersList?.map((item: any) => {
                  return (
                    <UserItem
                      data={item}
                      onUnSubscribe={() => {
                        unsubscribe(
                          {
                            id: item?.id,
                          },
                          {
                            onError: () => {
                              setUsersList([]);
                              setRefetchKey((prev) => (prev += 1));
                            },
                            onSuccess: () => {
                              setUsersList([]);
                              setRefetchKey((prev) => (prev += 1));
                            },
                          }
                        );
                      }}
                      onSubscribe={() => {
                        subscribe(
                          {
                            id: item?.id,
                          },
                          {
                            onError: () => {
                              setUsersList([]);
                              setRefetchKey((prev) => (prev += 1));
                            },
                            onSuccess: () => {
                              setUsersList([]);
                              setRefetchKey((prev) => (prev += 1));
                            },
                          }
                        );
                      }}
                    />
                  );
                })}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Subscribers;
