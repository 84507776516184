import { Box, Stack } from "@mui/material";
import React from "react";
import styles from "./PlotPoint.module.css";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import { DefaultMapMarker } from "../../../MapMarker/MapMarker";
import { PlotIcon } from "../../../../../../assets/icons/PlotIcon";

export const PlotPoint = ({
  pointData,
  handleClick,
  isDraggable,
  index,
  points,
  handleDelete,
  title,
  role,
  selected,
  previewImage,
  audio,
}: any) => {
  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderRadius: "16px",
        background: selected
          ? "rgba(255, 255, 255, 0.30)"
          : "rgba(255, 255, 255, 0.10)",
        backdropFilter: "blur(8px)",
        padding: "12px",
        width: "304px",
        border: selected
          ? "2px #A1B670 solid"
          : "2px rgba(255, 255, 255, 0.10) solid",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.30)",
          border: "2px #A1B670 solid",
        },
      }}
    >
      <Stack direction="row" gap="12px" alignItems="center">
        {isDraggable && (
          <DragIndicatorIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#89A053",
            }}
          />
        )}
        <DefaultMapMarker number={index + 1} icon filled isLegend />
        {previewImage && (
          <Stack
            sx={{
              width: "64px",
              height: "64px",
              borderRadius: "10px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${previewImage?.media?.original})`,
            }}
          />
        )}
        {points[index]?.type_of_route === "plot" && (
          <Box
            sx={{
              width: "64px",
              height: "64px",
              background: "#00000099",
              borderRadius: "12px",
              border: "1px #FFFFFF26 solid",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlotIcon />
          </Box>
        )}
        <Stack direction="column" gap="2px">
          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
            sx={{
              alignItems: "start",
            }}
          >
            {role === "preview" ? (
              <>
                {" "}
                <p
                  className={styles.coordinates}
                  style={{
                    width: previewImage ? "158px" : "228px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {title}
                </p>
              </>
            ) : (
              <>
                <p className={styles.coordinates}>
                  {pointData?.coordinate?.coordinates?.[0]?.toFixed(4)}
                </p>
                <p className={styles.coordinates}>
                  {pointData?.coordinate?.coordinates?.[1]?.toFixed(4)}
                </p>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      {isDraggable && (
        <Stack
          sx={{
            justifySelf: "flex-end",
          }}
        >
          <DeleteOutlineIcon
            onClick={handleDelete}
            sx={{
              color: "white",
              backgroundColor: "#89A053",
              padding: "8px",
              borderRadius: "4px",
              minWidth: "36px",
              minHeight: "36px",
              cursor: isDraggable ? "pointer" : "not-allowed",
              opacity: isDraggable ? 1 : 0,
              pointerEvents: isDraggable ? "all" : "none",
            }}
          />
        </Stack>
      )}
      {audio && (
        <Stack direction="column" alignSelf="start">
          <AudiotrackOutlinedIcon
            sx={{
              width: "12px",
              height: "12px",
              color: "#FFF",
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
