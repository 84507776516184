export const getLobbyStatus = (value) => {
    switch (value) {
        case 'not_started': {
            return 'Не начато'
        };
        case 'started': {
            return 'Начато'
        };
        case 'ready_check': {
            return 'Проверка готовности'
        };
        case 'finished': {
            return 'Завершено'
        };
    }
}