import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DefaultButton } from "../../../components/ui-kit/Button/Button";
import styles from "./AvatarModal.module.css";
import { Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { useRef, useState } from "react";
import { useMutationQuery } from "../../../api/useMutationQuery";
import { CircleStencil, Cropper, CropperRef } from "react-advanced-cropper";

export const AvatarModal = ({
  open,
  handleClose,
  currentAvatarImg,
  refetch,
}: any) => {
  const [crop, setCrop] = useState(null);

  const { mutate: avatarUpload, status: avatarData } = useMutationQuery({
    url: "users/97/avatar_upload/",
    method: "POST",
  });

  const cropper = useRef<CropperRef>(null);

  return (
    <Dialog
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "366px",
          borderRadius: "20px",
          border: "1px solid rgba(255, 255, 255, 0.00)",

          background: "var(--Surface-Card-2, rgba(255, 255, 255, 0.10))",

          boxShadow: " 0px 8px 12px 0px rgba(0, 0, 0, 0.40)",
          backdropFilter: "blur(8px)",
          padding: "32px",
        },
      }}
      open={open}
    >
      <h2 className={styles.title}>Добавление фото</h2>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 18,
          top: 20,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <p
        className={styles.content}
        style={{
          paddingTop: "32px",
          paddingBottom: "32px",
        }}
      >
        Выберите область аватарки
      </p>

      {currentAvatarImg && (
        <Cropper
          ref={cropper}
          src={URL.createObjectURL(currentAvatarImg)}
          className={"cropper"}
          stencilComponent={CircleStencil}
        />
      )}

      <Stack
        gap="12px"
        sx={{
          marginTop: "32px",
        }}
      >
        <DefaultButton
          onClick={() => {
            const formData: FormData = new FormData();

            formData.append("media", currentAvatarImg);
            formData.append("options", JSON.stringify(cropper?.current?.getCoordinates()));

            avatarUpload(formData, {
              onSuccess: () => {
                handleClose();
                refetch();
              },
            });
          }}
        >
          Подтвердить{" "}
          <CheckIcon
            sx={{
              width: "16px",
              height: "16px",
              marginLeft: "8px",
            }}
          />{" "}
        </DefaultButton>
      </Stack>
    </Dialog>
  );
};
