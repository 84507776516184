/* eslint-disable jsx-a11y/alt-text */
import { Box, Dialog, Stack } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { Mousewheel, FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

export const ImagePreviewModal = ({
  open,
  handleClose,
  imagesArr,
  activeIndex,
  handleActiveIndex,
}: any) => {
  const [firstSwiper, setFirstSwiper] = useState<any>(null);
  const sliderRef = useRef(null);

  console.log(imagesArr, activeIndex);

  const secondSlideTo = (index: number): void => firstSwiper?.slideTo(index);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slideNext();
  }, []);

  return (
    // <ClickAwayListener onClickAway={handleClose}>
    <Dialog
      onClose={handleClose}
      sx={{
        ">div": { background: "rgba(0, 0, 0, 0.85)" },
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "1312px",
          backgroundColor: "rgba(0,0,0,0)",
          boxShadow: "none",
          maxHeight: "100vh !important",
        },
      }}
      open={open}
    >
      <Stack
        sx={{
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.10)",
            borderRadius: "50%",
            height: "32px",
            width: "32px",
            marginBottom: "32px",
          }}
        >
          <CloseIcon
            onClick={(e) => {
              handleClose();
            }}
            sx={{
              position: "relative",
              left: "5px",
              top: "5px",
              zIndex: 10000,
              color: "white",
              height: "22px",
              width: "22px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Stack>
      <Box
        onClick={handleClose}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {imagesArr?.length > 1 && (
          <ChevronLeftIcon
            className="prev-arrow"
            onClick={(e) => {
              e.stopPropagation();
              handlePrev();
            }}
            sx={{
              zIndex: 10000,
              padding: "4px",
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.10)",
              borderRadius: "50%",
              cursor: "pointer",
              height: "32px",
              width: "32px",
            }}
          />
        )}
        <Swiper
          initialSlide={activeIndex}
          ref={sliderRef}
          slidesPerView={1}
          onSlideChange={(value: any) => {
            handleActiveIndex(value.activeIndex);
          }}
          onSwiper={setFirstSwiper}
        >
          {imagesArr?.map((item: any) => (
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",

                height: "70vh",
                // ...(window.innerHeight >= 868 && {
                //   // Добавляем медиазапрос
                //   height: 500,
                // }),
              }}
            >
              <img
                onClick={(e) => {
                  e.stopPropagation();
                }}
                src={item?.media?.media?.original}
                style={{
                  maxHeight: 868,
                  maxWidth: 952,
                  ...(window.innerHeight <= 868 && {
                    // Добавляем медиазапрос
                    maxHeight: 500,
                  }),
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {imagesArr?.length > 1 && (
          <ChevronRightIcon
            className="next-arrow"
            onClick={(e) => {
              e.stopPropagation();
              handleNext();
            }}
            sx={{
              position: "relative",
              zIndex: 10000,
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.10)",
              borderRadius: "50%",
              cursor: "pointer",
              width: "32px",
              height: "32px",
            }}
          />
        )}
      </Box>
      {imagesArr?.length > 0 && (
        <Stack
          sx={{
            marginTop: "32px",
            height: "48px",
          }}
        >
          <Swiper
            draggable={false}
            slidesPerView={10}
            spaceBetween={16}
            modules={[Mousewheel, FreeMode]}
            mousewheel
            freeMode
          >
            {imagesArr?.map((image: any, index: number) => (
              <SwiperSlide
                key={`modal${index}`}
                onClick={() => {
                  secondSlideTo(index);
                }}
                style={{
                  minWidth: "48px",
                  maxWidth: "48px",
                }}
              >
                <Box
                  sx={{
                    opacity: activeIndex === index ? 1 : 0.2,
                    minWidth: "48px",
                    maxWidth: "48px",
                    height: "48px",
                    objectFit: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    background: `url(${image?.media?.media?.original}) lightgray 50% / cover no-repeat`,
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Stack>
      )}
    </Dialog>
    // </ClickAwayListener>
  );
};
