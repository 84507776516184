import React, { useEffect, useMemo, useState } from "react";
import styles from "./CreateTourMap.module.css";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Stack } from "@mui/material";
import { DefaultMapMarker } from "../MapMarker/MapMarker";
import { DarkButton, DefaultButton } from "../../../ui-kit/Button/Button";
import { MapContainer } from "../../../MapKit/MapContainer/MapContainer";
import { CreateCheckpoint } from "../../../MapKit/CreateCheckpoint/CreateCheckpoint";
import { Marker } from "../../../MapKit/Marker/Marker";
import { SearchAddressInput } from "../../../ui-kit/SearchAddressInput/SearchAddressInput";
import { Map } from "maplibre-gl";

export const CreateCheckpointMap = ({
  handleClose,
  handleMapCoordinates,
  mapCoordinates,
  handleSave,
  defaultCoordinates,
}: any) => {
  const [map, setMap] = useState<Map | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [updateKey, setUpdateKey] = useState<number>(0);
  const [currentSearchValue, setCurrentSearchValue] = useState<any>();

  useEffect(() => {
    if (!defaultCoordinates) {
      setEditMode(true);
    } else {
      handleMapCoordinates({
        lat: defaultCoordinates[0],
        lng: defaultCoordinates[1],
      });
    }
  }, [defaultCoordinates, handleMapCoordinates]);

  const mapCenter = useMemo(() => {
    if (defaultCoordinates) {
      return [defaultCoordinates[1], defaultCoordinates[0]];
    }
    return [37.36, 55.44];
  }, [defaultCoordinates]);

  useEffect(() => {
    const url = new URL(window.location.href);

    if (editMode) {
      url.searchParams.set("mode", "edit");
    } else {
      url.searchParams.delete("mode");
    }

    window.history.replaceState(null, "", url.toString());
  }, [editMode, updateKey]);

  useEffect(() => {
    if (map) {
      map.flyTo({
        center: currentSearchValue?.value,
        speed: 2,
        curve: 1,
        zoom: 13,
      });
    }
  }, [currentSearchValue]);

  return (
    <>
      <Box className={styles.titleBlock}>
        <h3 className={styles.title}>Добавление точки</h3>
        <SearchAddressInput
          currentIndex={0}
          displayIndex={false}
          handleCurrentValue={setCurrentSearchValue}
          sx={{
            marginTop: "24px",
          }}
        />
      </Box>
      <Box className={styles.controlBlock}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            padding: "16px 32px",
            width: "100%",
            background: "#181A16",
            zIndex: "1000",
          }}
        >
          <DarkButton
            onClick={handleClose}
            sx={{
              width: "100%",
              color: "white",
            }}
          >
            Отмена
          </DarkButton>
          <DefaultButton
            onClick={() => {
              handleClose();
              handleSave();
            }}
            sx={{
              width: "100%",
            }}
          >
            Далее
            <CheckIcon
              sx={{
                height: "16px",
                marginLeft: "8px",
              }}
            />
          </DefaultButton>
        </Stack>
      </Box>
      <MapContainer
        mapRefSetter={setMap}
        style={{
          height: "100vh",
          width: "calc(100vw + 122px)",
          transform: "translateX(-122px)",
          position: "absolute",
        }}
        center={mapCenter as any}
        zoom={10}
        onMouseMove={(e) => {
          const urlParams = new URLSearchParams(window.location.search);
          const mode = urlParams.get("mode");
          if (mode === "edit") {
            handleMapCoordinates(e.lngLat);
          }
        }}
        onMapClick={(e) => {
          const urlParams = new URLSearchParams(window.location.search);
          const mode = urlParams.get("mode");
          if (mode === "edit") {
            setEditMode(false);
          } else {
            handleMapCoordinates(e.lngLat);
            setUpdateKey((prev) => (prev += 1));
          }
        }}
      >
        {editMode ? (
          <Marker
            coordinates={[mapCoordinates?.lng, mapCoordinates?.lat]}
            icon={<DefaultMapMarker filled />}
          />
        ) : (
          <CreateCheckpoint
            updateKey={updateKey}
            coordinates={[mapCoordinates?.lng, mapCoordinates?.lat]}
            handleMapCoordinates={handleMapCoordinates}
          />
        )}
      </MapContainer>
    </>
  );
};
