import { Stack, Divider, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import AudioPlayer from "../../../ui-kit/Audio/Audio";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PinDropIcon from "@mui/icons-material/PinDrop";
import styles from "../CreateTour.module.css";
import UploadIcon from "@mui/icons-material/Upload";
import { Input } from "../../../ui-kit/TextField/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { sortByPriority } from "../../../../utils/sortByPriority";
import { useMutationQuery } from "../../../../api/useMutationQuery";
import { useForm } from "react-hook-form";
import { CreatePointInterface } from "./CreatePointInterface";
import { enqueueSnackbar } from "notistack";
import { imagesFormats } from "../../../../utils/imgFormats";
import _ from "lodash";
import { DefaultButton } from "../../../ui-kit/Button/Button";
import { useLocation } from "react-router";
import { DefaultLoader } from "../../../ui-kit/DefaultLoader/DefaultLoader";
import { useGetRouteParts } from "../../../../api/hooks/useGetRouteParts";

export const CreateEditablePoint = ({
  handleOpenPointEditor,
  handleOpenMap,
  editRoutePart,
  editCheckpoint,
  getRoutePartData,
  handleCurrentRoutePart,
  routePartKey,
}: any) => {
  const location = useLocation();

  const { data: routePartRetrieveData, isLoading } = useGetRouteParts({
    routePartId: Number(location?.pathname?.split("/")[3]),
    enabled: !!location?.pathname?.split("/")[3],
    key: routePartKey,
  });

  useEffect(() => {
    handleCurrentRoutePart(routePartRetrieveData?.data);
  }, [handleCurrentRoutePart, routePartRetrieveData]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [localPointImages, setLocalPointImages] = React.useState<any>([]);
  const [currentImageLocalPoint, setCurrentImageLocalPoint] =
    React.useState<any>(null);

  const { mutate: uploadPointMedia } = useMutationQuery({
    url: `check_points/${routePartRetrieveData?.data?.check_point?.id}/media_upload/`,
    method: "POST",
  });

  const { mutate: uploadPointAudioGuide } = useMutationQuery({
    url: `check_points/${routePartRetrieveData?.data?.check_point?.id}/audio_guide_upload/`,
    method: "POST",
  });

  const { mutate: deletePointAudioGuide } =
    useMutationQuery({
      url: `check_points/${routePartRetrieveData?.data?.check_point?.id}/audio_guide_delete/`,
      method: "DELETE",
    });

  const { mutate: deletePointMedia } = useMutationQuery({
    url: `check_points/${routePartRetrieveData?.data?.check_point?.id}/media_delete/`,
    method: "DELETE",
  });

  const { mutate: swapPointMedia } = useMutationQuery({
    url: `check_points/media_swap/${currentImageLocalPoint?.id}/`,
    method: "POST",
  });

  const localPointImagesDragStartHandler = (e: any, item: any) => {
    setCurrentImageLocalPoint(item);
  };

  const localPointImagesDragEndHandler = (e: any) => {
    setCurrentImageLocalPoint(null);
  };

  const localPointImagesDragLeaveHandler = (e: any) => {};

  const localPointImagesDragOverHandler = (e: any) => {
    e.preventDefault();
  };

  const localPointImagesDropHandler = (e: any, item: any) => {
    e.preventDefault();
    swapPointMedia(
      {
        priority: item.priority,
      },
      {
        onSuccess: () => {
          getRoutePartData();
        },
        onError: () => {
          getRoutePartData();
        },
      }
    );
    setLocalPointImages(
      localPointImages.map((image: any) => {
        if (image.id === item.id) {
          return { ...image, order: currentImageLocalPoint.priority };
        }
        if (image.id === currentImageLocalPoint.id) {
          return { ...image, order: item.priority };
        }
        return image;
      })
    );
  };

  React.useEffect(() => {
    setLocalPointImages(routePartRetrieveData?.data?.check_point?.media);
  }, [routePartRetrieveData?.data?.check_point?.media]);

  const handleImageUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && evt.target.files.length > 0) {
      if (imagesFormats?.includes(evt.target.files[0].type?.split("/")[1])) {
        if (evt.target.files[0]?.size / 1024 / 1024 <= 40) {
          const formData: FormData = new FormData();

          formData.append("media", evt.target.files[0]);

          uploadPointMedia(formData, {
            onSuccess: () => {
              getRoutePartData();
            },
            onError: () => {
              getRoutePartData();
            },
          });
        } else {
          enqueueSnackbar("Размер файла не может превышать 40МБ", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Неподдерживаемый формат изображения", {
          variant: "error",
        });
      }
    }
  };

  const { register, setValue, watch } = useForm<CreatePointInterface>();

  useEffect(() => {
    setValue("title", routePartRetrieveData?.data?.title);
    setValue(
      "description",
      routePartRetrieveData?.data?.check_point?.description
    );
  }, [routePartRetrieveData?.data, setValue]);

  useEffect(() => {
    const debouncedEditRoutePart = _.debounce((data) => {
      editRoutePart(data);
    }, 500);

    if (title) {
      debouncedEditRoutePart({ title });
    }

    return () => {
      debouncedEditRoutePart.cancel(); // Для очистки при размонтировании
    };
  }, [editRoutePart, title]);

  useEffect(() => {
    const debouncedEditCheckpoint = _.debounce((data) => {
      editCheckpoint(data);
    }, 500);

    if (description) {
      debouncedEditCheckpoint({ description });
    }

    return () => {
      debouncedEditCheckpoint.cancel(); // Для очистки при размонтировании
    };
  }, [description, editCheckpoint]);

  return (
    <Stack
      sx={{
        height: "calc(100vh - 118px)",
        overflowY: "auto",
      }}
    >
      <h1
        className={styles.title}
        style={{
          marginBottom: "32px",
        }}
      >
        Точка тура
      </h1>

      {isLoading ? (
        <Stack
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <DefaultLoader />
        </Stack>
      ) : (
        <>
          {" "}
          <Stack>
            <Stack
              gap="16px"
              direction="row"
              sx={{
                marginBottom: "32px",
              }}
            >
              <DefaultButton
                onClick={() => {
                  handleOpenPointEditor(false);
                  handleOpenMap(true);
                }}
                sx={{
                  width: "50%",
                }}
              >
                <PinDropIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                Настроить координаты
              </DefaultButton>
              {!!routePartRetrieveData?.data?.check_point?.coordinate
                ?.coordinates[0] && (
                <Stack
                  sx={{
                    maxWidth: "50%",
                  }}
                >
                  <p className={styles.editPlotSubtitle}>
                    Координаты точки:{" "}
                    <span
                      style={{
                        color: "#7ACA14",
                      }}
                    >
                      {
                        routePartRetrieveData?.data?.check_point?.coordinate
                          ?.coordinates[0]
                      }
                      ,{" "}
                      {
                        routePartRetrieveData?.data?.check_point?.coordinate
                          ?.coordinates[1]
                      }
                    </span>
                  </p>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Input
            label="Название точки"
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 255 }}
            helperText={
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  className={styles.formDescription}
                  style={{
                    opacity: "0.5",
                  }}
                >
                  {watch("title")?.length ?? 0} / 255
                </p>
              </Stack>
            }
            variant="outlined"
            sx={{
              width: "100%",
              marginBottom: "16px",
            }}
            {...register("title", {
              onChange: (e) => setTitle(e.target.value),
            })}
          />
          <Input
            inputProps={{ style: { color: "white" } }}
            label="Описание точки"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            multiline
            sx={{
              width: "100%",
              marginBottom: "16px",
            }}
            {...register("description", {
              onChange: (e) => setDescription(e.target.value),
            })}
          />
          <Divider
            sx={{
              marginTop: "16px",
              marginBottom: "16px",
            }}
          />
          <h1 className={styles.title}>Изображения для превью точки</h1>
          <Stack
            direction="row"
            gap="16px"
            sx={{
              marginTop: "16px",
            }}
          >
            <Stack direction="row" alignItems="end" gap="16px" flexWrap="wrap">
              {localPointImages?.sort(sortByPriority).map((item: any) => (
                <Stack
                  onDragStart={(e) => localPointImagesDragStartHandler(e, item)}
                  onDragEnd={(e) => localPointImagesDragEndHandler(e)}
                  onDragLeave={(e) => localPointImagesDragLeaveHandler(e)}
                  onDragOver={(e) => localPointImagesDragOverHandler(e)}
                  onDrop={(e) => localPointImagesDropHandler(e, item)}
                  draggable={true}
                  sx={{
                    cursor: "grab",
                    width: "111px",
                    height: "111px",
                    borderRadius: "20px",
                    border: "1px solid rgba(255, 255, 255, 0.00)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${item.media?.media?.original})`,
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <DragIndicatorIcon
                    sx={{
                      position: "relative",
                      transform: "translateX(35px) translateY(35px)",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      color: "white",
                      minWidth: "40px",
                      minHeight: "40px",
                      padding: "8px",
                      borderRadius: "50%",
                      cursor: "grab",
                    }}
                  />
                  <DeleteOutlineIcon
                    onClick={() => {
                      deletePointMedia(
                        { id: item.id },
                        {
                          onSuccess: () => {
                            getRoutePartData();
                          },
                          onError: () => {
                            getRoutePartData();
                          },
                        }
                      );
                    }}
                    sx={{
                      position: "relative",
                      transform: "translateY(-200%) translateY(12px)",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      color: "white",
                      maxWidth: "28px",
                      maxHeight: "28px",
                      left: "80px",
                      padding: "4px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              ))}
              <Button
                component="label"
                sx={{
                  border: "1px #a1b670 dotted",
                  width: "111px",
                  height: "111px",
                  borderRadius: "20px",
                }}
              >
                <UploadIcon
                  sx={{
                    color: "#a1b670",
                  }}
                />
                <input
                  value={""}
                  type="file"
                  accept=".jpeg, .png, .gif, .tiff, .webp, .postscript, .svg, .raw, .jpg"
                  hidden
                  onChange={(evt) => {
                    handleImageUpload(evt);
                  }}
                />
              </Button>
            </Stack>
          </Stack>
          <Divider
            sx={{
              marginTop: "32px",
              marginBottom: "32px",
            }}
          />
          <h1 className={styles.title}>Аудиогид</h1>
          <Stack
            sx={{
              marginTop: "32px",
              marginBottom: "32px",
            }}
          >
            <AudioPlayer
              handleUploadPointAudioGuide={uploadPointAudioGuide}
              handleDeletePointAudioGuide={() => {
                deletePointAudioGuide(
                  {},
                  {
                    onSuccess: () => {
                      getRoutePartData();
                    },
                    onError: () => {
                      getRoutePartData();
                    },
                  }
                );
              }}
              getRoutePartData={getRoutePartData}
              audioGuide={
                routePartRetrieveData?.data?.check_point?.audio_guide?.media
                  ?.original
              }
              type="point"
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
