import React, { useState } from "react";
import styles from "./TourCard.module.css";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router";
import AvatarPreview from "../AvatarPreview/AvatarPreview";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Divider } from "../Divider/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { useMutationQuery } from "../../../api/useMutationQuery";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const TourCardFeedback = ({
  user,
  isOnlyCreated,
  postedAt,
  createdAt,
  review,
  isToursList,
  darkDisplay,
}: any) => {
  const navigate = useNavigate();
  const { mutate: handleLike } = useMutationQuery({
    url: `reviews/${review?.id}/like/`,
    method: "POST",
    onSuccess: (response) => {
      setReviewData(response?.data);
    },
  });
  const { mutate: handleDislike } = useMutationQuery({
    url: `reviews/${review?.id}/dislike/`,
    method: "POST",
    onSuccess: (response) => {
      setReviewData(response?.data);
    },
  });
  const [reviewData, setReviewData] = useState(review);
  const [expanded, setExpanded] = useState<boolean>(false);

  const groupLinks = (urls: any) => {
    if (urls?.length <= 3) {
      return urls;
    }

    const result = urls.slice(0, 3);

    for (let i = 3; i < urls.length; i += 2) {
      result.push(urls.slice(i, i + 2));
    }

    return result;
  };

  const currentMedia = groupLinks(
    review?.review_media?.map((item: any) => item?.media?.media?.original)
  );

  return (
    <Stack
      sx={{
        background: "var(--Surface-Card, #00000099)",
        transform: isToursList ? "translateY(16px)" : "0px",
        padding: isToursList ? "16px" : "0px",
        borderRadius: isToursList ? "4px" : "16px",
      }}
    >
      <Stack
        sx={{
          background: darkDisplay ? " #00000099" : "#FFFFFF1A",
          padding: "16px",
          gap: "16px",
          borderRadius: isToursList ? "4px" : "16px",
        }}
      >
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Stack
            direction="row"
            gap="8px"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/profile/${user?.id}`);
            }}
          >
            {user?.avatar_data?.media?.original ? (
              <AvatarPreview
                imgSrc={user?.avatar_data?.media?.original}
                options={user?.avatar_data?.options}
                height={22}
                width={22}
              />
            ) : (
              user?.id && (
                <AccountCircleOutlinedIcon
                  sx={{
                    color: "rgba(255, 255, 255, 0.2)",
                    width: "22px",
                    height: "22px",
                  }}
                />
              )
            )}

            <Stack direction="column" gap="2px">
              <h5
                className={styles.tourMetaDataUserLogin}
                style={{
                  maxWidth: "224px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {user?.username}
              </h5>
              <h5
                className={styles.tourMetaDataUserLogin}
                style={{
                  color: "white",
                  opacity: "0.6",
                }}
              >
                {new Date(createdAt * 1000).toLocaleDateString()}
              </h5>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <p
              className={styles.tourDataToolTip}
              style={{
                opacity: "0.6",
              }}
            >
              Оценка пользователя
            </p>{" "}
            <Divider
              sx={{
                transform: "rotate(90deg)",
                width: "16px",
              }}
            />
            <p className={styles.tourMetaDataRate}>{review?.evaluation / 2}</p>
          </Stack>
        </Stack>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={8}
          freeMode={true}
          modules={[FreeMode, Mousewheel]}
          style={{
            maxWidth: "570px",
            height: darkDisplay ? "48px" : "104px",
            margin: darkDisplay ? "18px 0 0 0" : 0,
          }}
        >
          {darkDisplay &&
            review?.review_media?.map((item: any) => {
              return (
                <SwiperSlide
                  style={{
                    minWidth: "48px",
                    maxWidth: "48px",
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      minWidth: "48px",
                      minHeight: "48px",
                      objectFit: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      background: `url(${item?.media?.media?.original}) lightgray 50% / cover no-repeat`,
                    }}
                  />
                </SwiperSlide>
              );
            })}
          {currentMedia?.map((item: string[]) => (
            <SwiperSlide
              style={{
                minWidth: item?.length !== 2 ? "104px" : "48px",
                maxWidth: item?.length !== 2 ? "104px" : "48px",
              }}
            >
              {item?.length !== 2 ? (
                <Box
                  sx={{
                    minWidth: "104px",
                    maxWidth: "104px",
                    minHeight: "104px",
                    objectFit: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    background: `url(${item}) lightgray 50% / cover no-repeat`,
                  }}
                />
              ) : (
                <Stack
                  sx={{
                    maxWidth: "48px",
                    minHeight: "104px",
                    direction: "column",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "48px",
                      maxWidth: "48px",
                      minHeight: "48px",
                      objectFit: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      background: `url(${item[0]}) lightgray 50% / cover no-repeat`,
                    }}
                  />
                  <Box
                    sx={{
                      minWidth: "48px",
                      minHeight: "48px",
                      objectFit: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      background: `url(${item?.[1]}) lightgray 50% / cover no-repeat`,
                    }}
                  />
                </Stack>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <Stack>
          <p
            className={
              expanded
                ? styles.tourFeedbackTextExpanded
                : styles.tourFeedbackText
            }
          >
            {reviewData?.text}
          </p>
        </Stack>
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            marginTop: "8px",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              gap: "6px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          >
            <p className={styles.tourFeedbackMore}>{expanded ? 'Скрыть' : 'Раскрыть'}</p>
            <ExpandMoreIcon
              className={styles.tourFeedbackMoreIcon}
              style={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </Stack>
          <Stack
            sx={{
              gap: "8px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p className={styles.tourFeedbackMore}>
              {reviewData?.dislike_count}
            </p>
            {reviewData?.reaction_type === "disliked" ? (
              <ThumbDownAltIcon
                className={styles.tourFeedbackLikeIcon}
                onClick={() => handleDislike({})}
              />
            ) : (
              <ThumbDownOffAltIcon
                className={styles.tourFeedbackLikeIcon}
                onClick={() => handleDislike({})}
              />
            )}

            <Divider
              sx={{
                transform: "rotate(90deg)",
                width: "16px",
              }}
            />
            {reviewData?.reaction_type === "liked" ? (
              <ThumbUpAltIcon
                className={styles.tourFeedbackLikeIcon}
                onClick={() => {
                  handleLike({});
                }}
              />
            ) : (
              <ThumbUpOffAltIcon
                className={styles.tourFeedbackLikeIcon}
                onClick={() => {
                  handleLike({});
                }}
              />
            )}
            <p className={styles.tourFeedbackMore}>{reviewData?.like_count}</p>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
