/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";


export const ToursCalculateZoom = ({ map, selectedCard, zoom }: any) => {
  useEffect(() => {
    if (selectedCard?.point && map) {
      map.easeTo({
        center: [
          selectedCard?.point?.coordinates?.[1],
          selectedCard?.point?.coordinates?.[0],
        ],
        zoom: 5,
        speed: 2,
        curve: 1,
        padding: {
          top: 10,
          bottom: 25,
          left: 750,
          right: 5,
        },
      });
    }
  }, [selectedCard]);

  useEffect(() => {
    if (map && zoom) {
      map.easeTo({
        center: [
          selectedCard?.point?.coordinates?.[1],
          selectedCard?.point?.coordinates?.[0],
        ],
        zoom: zoom,
        speed: 2,
        curve: 1,
      });
    }
  }, [zoom]);

  return null;
};
