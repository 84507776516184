import { SvgIcon, SvgIconProps } from "@mui/material";

export const LoginLogo: React.FC<SvgIconProps> = ({ sx, ...rest }) => {
  return (
    <svg width="183" height="36" viewBox="0 0 183 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.24609V34.746H5.04572V23.0269H8.63827L13.7647 34.746H19.4159L13.5629 22.2995C15.2313 21.6798 16.55 20.6292 17.5187 19.1474C18.5144 17.6657 19.0123 15.955 19.0123 14.0153C19.0123 12.2372 18.6086 10.7016 17.8013 9.40841C16.994 8.08832 15.8637 7.06458 14.4106 6.33719C12.9843 5.60979 11.289 5.24609 9.32449 5.24609H0ZM9.32449 18.5817H5.04572V9.69129H9.32449C10.7507 9.69129 11.8675 10.0954 12.6748 10.9036C13.4822 11.6849 13.8858 12.776 13.8858 14.1769C13.8858 15.5509 13.4822 16.6285 12.6748 17.4098C11.8675 18.191 10.7507 18.5817 9.32449 18.5817Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3614 34.059C29.7607 34.7864 31.4022 35.1501 33.286 35.1501C35.6541 35.1501 37.6589 34.5844 39.3005 33.4529C40.9689 32.2944 41.9915 30.7588 42.3683 28.846H37.4033C37.2149 29.6004 36.7709 30.1796 36.0712 30.5837C35.3715 30.9609 34.4566 31.1495 33.3264 31.1495C31.8463 31.1495 30.7295 30.7588 29.976 29.9775C29.2225 29.1693 28.8458 27.9839 28.8458 26.4214V24.8454H42.5297V20.8447C42.5297 19.0935 42.1395 17.5714 41.3591 16.2783C40.6056 14.9582 39.5292 13.9344 38.1299 13.207C36.7574 12.4796 35.1428 12.1159 33.286 12.1159C31.4022 12.1159 29.7607 12.4796 28.3614 13.207C26.962 13.9344 25.8721 14.9582 25.0917 16.2783C24.3382 17.5714 23.9615 19.0935 23.9615 20.8447V26.4214C23.9615 28.1725 24.3382 29.7081 25.0917 31.0282C25.8721 32.3214 26.962 33.3316 28.3614 34.059ZM37.6455 21.6157V20.8043C37.6455 19.2687 37.2822 18.0967 36.5556 17.2885C35.829 16.4534 34.7392 16.0358 33.286 16.0358C31.8328 16.0358 30.7295 16.4534 29.976 17.2885C29.2225 18.1237 28.8458 19.3091 28.8458 20.8447V21.6933L37.6455 21.6157Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.987 35.1501C52.6996 35.1501 50.8966 34.544 49.578 33.3316C48.2594 32.1193 47.6001 30.4894 47.6001 28.4419C47.6001 26.2597 48.3266 24.576 49.7798 23.3906C51.233 22.2052 53.2916 21.6125 55.9558 21.6125H61.4859V19.7132C61.4859 18.6356 61.136 17.8004 60.4364 17.2077C59.7367 16.5881 58.7814 16.2783 57.5704 16.2783C56.4671 16.2783 55.5521 16.5207 54.8255 17.0057C54.0989 17.4906 53.6684 18.1506 53.5338 18.9858H48.6092C48.8514 16.8844 49.7933 15.2141 51.4348 13.9748C53.0764 12.7356 55.1754 12.1159 57.7319 12.1159C60.4498 12.1159 62.5892 12.8029 64.15 14.1769C65.7377 15.5239 66.5316 17.3559 66.5316 19.6728V34.746H61.6473V30.8666H61.5493C61.3332 32.0079 60.7605 32.9509 59.8309 33.6953C58.6199 34.6652 57.0053 35.1501 54.987 35.1501ZM56.642 31.3515C58.0683 31.3515 59.2254 30.9878 60.1135 30.2604C61.0284 29.533 61.4859 28.5901 61.4859 27.4317V24.7241H56.0365C55.0139 24.7241 54.1931 25.0205 53.5742 25.6132C52.9553 26.2059 52.6458 26.9871 52.6458 27.957C52.6458 29.0077 52.9956 29.8428 53.6953 30.4625C54.4219 31.0552 55.4041 31.3515 56.642 31.3515Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M119.144 34.059C120.543 34.7595 122.185 35.1097 124.069 35.1097C125.979 35.1097 127.621 34.7595 128.993 34.059C130.366 33.3316 131.429 32.3214 132.182 31.0282C132.963 29.7081 133.353 28.1591 133.353 26.381V20.8447C133.353 19.0935 132.963 17.5714 132.182 16.2783C131.429 14.9582 130.366 13.9479 128.993 13.2474C127.621 12.52 125.979 12.1564 124.069 12.1564C122.185 12.1564 120.543 12.52 119.144 13.2474C117.772 13.9479 116.695 14.9582 115.915 16.2783C115.161 17.5714 114.784 19.107 114.784 20.8851V26.381C114.784 28.1591 115.161 29.7081 115.915 31.0282C116.695 32.3214 117.772 33.3316 119.144 34.059ZM127.177 29.6138C126.45 30.3412 125.414 30.7049 124.069 30.7049C122.75 30.7049 121.714 30.3412 120.96 29.6138C120.207 28.8595 119.83 27.7819 119.83 26.381V20.8851C119.83 19.4572 120.207 18.3796 120.96 17.6522C121.714 16.9248 122.75 16.5611 124.069 16.5611C125.414 16.5611 126.45 16.9248 127.177 17.6522C127.93 18.3796 128.307 19.4572 128.307 20.8851V26.381C128.307 27.7819 127.93 28.8595 127.177 29.6138Z" fill="white"/>
    <path d="M148.272 35.1501C145.447 35.1501 143.227 34.3689 141.612 32.8063C139.997 31.2168 139.19 29.0616 139.19 26.3406V12.52H144.236V26.3002C144.236 27.728 144.586 28.8326 145.285 29.6138C145.985 30.3682 146.981 30.7453 148.272 30.7453C149.537 30.7453 150.519 30.3682 151.219 29.6138C151.946 28.8326 152.309 27.728 152.309 26.3002V12.52H157.355V26.3406C157.355 29.0616 156.534 31.2168 154.892 32.8063C153.251 34.3689 151.044 35.1501 148.272 35.1501Z" fill="white"/>
    <path d="M164.161 12.52V34.746H169.206V20.8447C169.206 19.4168 169.556 18.3123 170.256 17.531C170.982 16.7497 172.005 16.3591 173.324 16.3591C174.615 16.3591 175.624 16.7497 176.351 17.531C177.105 18.3123 177.481 19.4168 177.481 20.8447V22.0974H182.769V20.481C182.769 17.8678 182.083 15.8203 180.711 14.3385C179.338 12.8568 177.454 12.1159 175.059 12.1159C172.96 12.1159 171.373 12.7221 170.296 13.9344C169.649 14.6632 169.197 15.6061 168.938 16.7632H168.924V12.52H164.161Z" fill="white"/>
    <path d="M80.5356 34.4296C79.0824 34.4296 77.8042 34.1333 76.7008 33.5406C75.6244 32.9479 74.7767 32.1127 74.1578 31.0351C73.5388 29.9305 73.2294 28.6643 73.2294 27.2365V9.49611H66.125V4.92969H78.2751V27.2365C78.2751 28.0447 78.5038 28.6913 78.9613 29.1762C79.4457 29.6342 80.0916 29.8632 80.8989 29.8632H85.357V34.4296H80.5356Z" fill="white"/>
    <path d="M121.097 5.244L114.369 9.7341V0.753906L121.097 5.244Z" fill="#6DB512"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M89.7012 10.8577C89.7012 6.518 93.2152 3 97.5501 3H114.369V7.4901H98.6713C96.1943 7.4901 94.1863 9.50038 94.1863 11.9802V16.4703H89.7012V10.8577Z" fill="#6DB512"/>
    <rect x="103.156" y="7.48828" width="4.48508" height="8.98019" fill="#6DB512"/>
    <rect x="103.156" y="20.9609" width="4.48508" height="13.4703" fill="#6DB512"/>
    <rect opacity="0.7" x="89.7012" y="18.7148" width="4.48508" height="6.73514" fill="#6DB512"/>
    <rect opacity="0.4" x="89.7012" y="27.6953" width="4.48508" height="6.73514" fill="#6DB512"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M105.399 23.2028C107.876 23.2028 109.884 21.1926 109.884 18.7128C109.884 16.2329 107.876 14.2227 105.399 14.2227C102.922 14.2227 100.914 16.2329 100.914 18.7128C100.914 21.1926 102.922 23.2028 105.399 23.2028ZM105.399 20.9578C106.638 20.9578 107.642 19.9527 107.642 18.7128C107.642 17.4728 106.638 16.4677 105.399 16.4677C104.161 16.4677 103.157 17.4728 103.157 18.7128C103.157 19.9527 104.161 20.9578 105.399 20.9578Z" fill="white"/>
    </svg>
    
  );
};
