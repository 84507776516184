import { Box, Stack, Typography } from "@mui/material";
import styles from "./Menu.module.css";
import { useLocation } from "react-router-dom";
import { MenuItem } from "./MenuItem/MenuItem";
import { ToursMapIcon } from "../../../assets/icons/ToursMapIcon";
import { ToursListIcon } from "../../../assets/icons/ToursListIcon";
import { CreateTourIcon } from "../../../assets/icons/CreateTourIcon";
import AvatarPreview from "../../ui-kit/AvatarPreview/AvatarPreview";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

export const Menu = ({ userData }: any) => {
  const location = useLocation();
  return (
    <Box className={styles.menuWrapper}>
      <Stack></Stack>
      <Box className={styles.menuContent}>
        <MenuItem
          icon={
            <ToursListIcon
              color={location.pathname === "/tours" ? "#101110" : "#81C728"}
            />
          }
          locateTo="/tours"
          isSelected={location.pathname === "/tours"}
        />
        <MenuItem
          icon={
            <ToursMapIcon
              color={location.pathname === "/tours-map" ? "#101110" : "#81C728"}
            />
          }
          locateTo="/tours-map"
          isSelected={location.pathname === "/tours-map"}
        />
        <MenuItem
          icon={
            <CreateTourIcon
              color={
                location.pathname.split("/")[1] === "create-tour"
                  ? "#101110"
                  : "#81C728"
              }
            />
          }
          locateTo="/create-tour/main/"
          isSelected={location.pathname.split("/")[1] === "create-tour"}
        />
        <Stack
          sx={{
            position: "absolute",
            bottom: 32,
          }}
        >
          <MenuItem
            icon={
              userData?.avatar_data?.media?.original ? (
                <AvatarPreview
                  imgSrc={userData?.avatar_data?.media?.original}
                  options={userData?.avatar_data?.options}
                  height={24}
                  width={24}
                />
              ) : (
                <AccountCircleOutlinedIcon
                  sx={{
                    color: "rgba(255, 255, 255, 0.2)",
                    width: "24px",
                    height: "24px",
                  }}
                />
              )
            }
            locateTo={`/profile/${userData?.id}/`}
            isSelected={false}
          />
        </Stack>
      </Box>
    </Box>
  );
};
