import styles from "./CreateTourPoint.module.css";
import { Box, Stack, Tooltip } from "@mui/material";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import DeleteIcon from "@mui/icons-material/Delete";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Mock from "../../../../assets/img/ImageMock.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PlotIcon } from "../../../../assets/icons/PlotIcon";

export const TourPoint = ({
  title,
  description,
  image,
  audioGuide,
  handleOpen,
  handleDelete,
  points,
  renderKey,
  type,
  routePartsErrors,
  id,
}: any) => {
  const isError =
    routePartsErrors?.map((item: any) => item?.id)?.indexOf(id) !== -1;

  const errorText =
    routePartsErrors?.[
      routePartsErrors?.map((item: any) => item?.id)?.indexOf(id)
    ]?.errorText;

  return (
    <Box className={styles.wrapper}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap="16px"
      >
        <Stack direction="row" alignItems="center" gap="8px">
          <DragIndicatorIcon
            sx={{
              width: "24px",
              height: "24px",
              opacity: "0.2",
            }}
          />
          {type === "check_point" && (
            <Box
              sx={{
                minWidth: "90px",
                height: "90px",
                borderRadius: "12px",
                border: "1px solid rgba(255, 255, 255, 0.00)",
                background: `url(${
                  image?.media?.media?.original
                    ? image?.media?.media?.original
                    : Mock
                }) 50% / cover no-repeat`,
                boxShadow: "0px 8px 12px 0px rgba(0, 0, 0, 0.40)",
              }}
            />
          )}
          {type === "plot" && (
            <Box
              sx={{
                width: "90px",
                height: "90px",
                background: "#00000099",
                borderRadius: "12px",
                border: "2px #FFFFFF33 solid",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PlotIcon />
            </Box>
          )}
        </Stack>
        <Stack
          sx={{
            height: "90px",
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Stack>
              <h3
                className={styles.title}
                style={{
                  maxWidth: "calc(100vw - 800px)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </h3>
            </Stack>
            <Stack
              gap="4px"
              justifyContent="start"
              flexDirection="row"
              className={styles.subtitleBlock}
            >
              <p
                className={styles.subtitle}
                style={{
                  opacity: "0.7",
                  fontWeight: 400,
                  marginTop: "6px",
                }}
              >
                {description}
              </p>
            </Stack>
          </Stack>
          {/* <Stack
            direction="row"
            sx={{
              color: "white",
            }}
            gap="8px"
          >
            {audioGuide && (
              <MusicNoteIcon
                sx={{
                  width: "16px",
                  height: "16px",

                  opacity: 1,
                }}
              />
            )}
          </Stack> */}
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {isError && (
          <Tooltip
            title={
              <Box
                sx={{
                  padding: "8px 16px",
                  borderRadius: "4px",
                  background: "var(--Surface-Card, rgba(0, 0, 0, 0.60))",
                  maxWidth: "219px",
                }}
              >
                {errorText}
              </Box>
            }
          >
            <InfoOutlinedIcon
              sx={{
                marginTop: "2px",
                marginRight: "16px",
                width: "24px",
                height: "24px",
                color: "#FC6949",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        )}
        <EditTwoToneIcon
          onClick={handleOpen}
          sx={{
            marginRight: "16px",
            width: "24px",
            height: "24px",
            color: "#a1b670",
            cursor: "pointer",
            "&:hover": {
              color: "rgba(255,255,255,0.9)",
            },
            "&:active": {
              color: "rgba(255,255,255,1)",
            },
          }}
        />
        <DeleteIcon
          onClick={handleDelete}
          sx={{
            width: "24px",
            height: "24px",
            color: "#a1b670",
            cursor: "pointer",
            "&:hover": {
              color: "rgba(255,255,255,0.9)",
            },
            "&:active": {
              color: "rgba(255,255,255,1)",
            },
          }}
        />
      </Box>
    </Box>
  );
};
