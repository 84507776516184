import { Stack } from "@mui/material";
import styles from "./TourCard.module.css";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useNavigate } from "react-router";
import AvatarPreview from "../AvatarPreview/AvatarPreview";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "../Divider/Divider";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { useState } from "react";
import { useAddToFavorites } from "../../../api/hooks/useAddToFavorites";

export const TourMetaData = ({
  user,
  column,
  handleClose,
  created,
  isOnlyCreated,
  postedAt,
  createdAt,
  evaluationCount,
  avgRating,
  id,
  inFavorite,
}: any) => {
  const navigate = useNavigate();

  const [currentFavoritesValue, setCurrentFavoritesValue] =
    useState<boolean>(inFavorite);

  const { addToFav } = useAddToFavorites({
    tripId: id,
    onSuccess: (data: any) => setCurrentFavoritesValue(data?.data?.in_favorite),
  });

  return (
    <Stack className={styles.tourMetaData}>
      <Stack
        direction="row"
        gap="8px"
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(`/profile/${user?.id}`);
        }}
      >
        {user?.avatar_data?.media?.original ? (
          <AvatarPreview
            imgSrc={user?.avatar_data?.media?.original}
            options={user?.avatar_data?.options}
            height={22}
            width={22}
          />
        ) : (
          user?.id && (
            <AccountCircleOutlinedIcon
              sx={{
                color: "rgba(255, 255, 255, 0.2)",
                width: "22px",
                height: "22px",
              }}
            />
          )
        )}

        <Stack direction="column" gap="2px">
          <h5
            className={styles.tourMetaDataUserLogin}
            style={{
              maxWidth: "224px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {user?.username}
          </h5>
          <h5
            className={styles.tourMetaDataUserLogin}
            style={{
              color: "white",
              opacity: "0.6",
            }}
          >
            {isOnlyCreated
              ? new Date(createdAt * 1000).toLocaleDateString()
              : new Date(postedAt * 1000).toLocaleDateString()}
          </h5>
        </Stack>
      </Stack>
      <Stack direction="row" gap="8px" alignItems="center">
        <h5
          className={styles.tourMetaDataUserLogin}
          style={{
            color: "white",
            opacity: "0.6",
            marginRight: "-8px",
          }}
        >
          Оценок: {evaluationCount || 0}
        </h5>
        <Divider
          sx={{
            transform: "rotate(90deg)",
            width: "16px",
          }}
        />
        <h5
          className={styles.tourMetaDataRate}
          style={{
            marginLeft: "-8px",
          }}
        >
          {avgRating ?? "--"}
        </h5>
        {currentFavoritesValue ? (
          <BookmarkAddedIcon
            onClick={() => {
              addToFav({});
            }}
            sx={{
              width: "24px",
              height: "24px",
              color: "#89A053",
              cursor: "pointer",
            }}
          />
        ) : (
          <BookmarkAddOutlinedIcon
            onClick={() => {
              addToFav({});
            }}
            sx={{
              width: "24px",
              height: "24px",
              color: "#89A053",
              cursor: "pointer",
            }}
          />
        )}
        {column && (
          <CloseIcon
            onClick={() => {
              handleClose();
            }}
            sx={{
              height: "16px",
              width: "16px",
              color: "#a1b670",
              cursor: "pointer",
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
