import { Box, Stack } from "@mui/material";
import styles from "./Profile.module.css";

const EmptyProfile = ({ text }: any) => {
  return (
    <Stack
      sx={{
        paddingTop: "64px",
        width: "100%",
        paddingBottom: "64px",
        borderRadius: "4px",
        background: "rgba(0, 0, 0, 0.10)",
      }}
      alignItems="center"
      direction="column"
    >
      <Box
        sx={{
          padding: "32px",
          marginBottom: "32px",
          borderRadius: "1000px",
          border:
            " 2px solid var(--Outline-Input-enable, rgba(137, 160, 83, 0.50))",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
        >
          <path
            d="M35.1667 26.6654H48.5L56.5 18.6654L48.5 10.6654H35.1667V5.33203H29.8333V10.6654H11.1667V26.6654H29.8333V31.9987H16.5L8.5 39.9987L16.5 47.9987H29.8333V58.6654H35.1667V47.9987H53.8333V31.9987H35.1667V26.6654ZM16.5 15.9987H46.2867L48.9533 18.6654L46.2867 21.332H16.5V15.9987ZM48.5 42.6654H18.7133L16.0467 39.9987L18.7133 37.332H48.5V42.6654Z"
            fill="#89A053"
          />
        </svg>
      </Box>
      <h3 className={styles.emptyElemetTitle}>{text}</h3>
    </Stack>
  );
};

export default EmptyProfile;
