import { LngLatLike, Map } from "maplibre-gl";
import { useEffect } from "react";

interface FitBoundsOnMapProps {
  map?: Map;
  points: any[];
  params?: any;
}

export const FitBoundsOnMap = ({
  map,
  points,
  params,
}: FitBoundsOnMapProps) => {
  useEffect(() => {
    if (!map && !points) return;
    const latitudes = points.map((point) => point[1]);
    const longitudes = points.map((point) => point[0]);
    const bounds: [LngLatLike, LngLatLike] = [
      [Math.min(...longitudes), Math.min(...latitudes)], // Юго-западная точка
      [Math.max(...longitudes), Math.max(...latitudes)], // Северо-восточная точка
    ];
    if (map) {
      map.fitBounds(bounds, params);
    }
  }, [map, points, params]);
  return null;
};
