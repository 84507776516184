/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, ClickAwayListener, Tooltip } from "@mui/material";
import { Divider } from "../../../ui-kit/Divider/Divider";
import AddIcon from "@mui/icons-material/Add";
import { sortByPriority } from "../../../../utils/sortByPriority";
import { TourPoint } from "../CreateTourPoint/CreateTourPoint";
import { CreateTourTooltip } from "../CreateTourTooltip/CreateTourTooltip";
import { useMutationQuery } from "../../../../api/useMutationQuery";
import { useEffect, useState } from "react";
import styles from "./RouteParts.module.css";
import { DefaultButton } from "../../../ui-kit/Button/Button";
import { useNavigate } from "react-router";
import { DefaultLoader } from "../../../ui-kit/DefaultLoader/DefaultLoader";

export const RouteParts = ({
  routeRetrieveData,
  tourData,
  createPoint,
  createPlot,
  getRoutePartData,
  handleTooltipClose,
  handleOpenGuideEditor,
  handleOnlyCreatePlot,
  handleOnlyCreatePoint,
  handleOpenPointEditor,
  handleCurrentRoutePart,
  deleteRoutePart,
  getRouteData,
  currentRoutePart,
  setRoutePartErrors,
  routePartErrors,
  isRoutesLoading,
}: any) => {
  const [openTooltip, setOpenTooltip] = useState<any>(null);
  const [renderkey, setRenderKey] = useState<number>(0);

  const handleTooltipOpen = (index: any) => {
    setOpenTooltip(index);
  };

  const [createdRoutePart, setCreatedRoutePart] = useState<any>(null);

  const { mutate: swapRoutePart, status: swapRoutePartStatus } =
    useMutationQuery({
      url: `route_parts/${currentRoutePart?.id}/swap/`,
      method: "POST",
    });

  const { mutate: swapCreatedRoutePart } = useMutationQuery({
    url: `route_parts/${createdRoutePart?.id}/swap/`,
    method: "POST",
  });

  useEffect(() => {
    if (swapRoutePartStatus === "error" || swapRoutePartStatus === "success") {
      getRouteData();
    }
  }, [swapRoutePartStatus]);
  const routePartsDragStartHandler = (e: any, item: any) => {
    handleCurrentRoutePart(item);
  };
  const routePartsDragEndHandler = (e: any) => {
    handleCurrentRoutePart(null);
  };

  const routePartsDragLeaveHandler = (e: any) => {};

  const routePartsDragOverHandler = (e: any) => {
    e.preventDefault();
  };

  const routePartsDropHandler = (e: any, item: any) => {
    e.preventDefault();
    swapRoutePart({
      priority: item.priority,
    });
    setRenderKey((prev) => (prev += 1));
  };
  let errors: any[] = [];

  useEffect(() => {
    routeRetrieveData?.data?.route_parts?.forEach((item: any) => {
      if (item?.type_of_route === "check_point") {
        if (!item?.title && item?.check_point?.coordinate) {
          errors.push({
            id: item?.id,
            errorText: "Необходимо добавить название точки",
          });
        }
        if (item?.title && !item?.check_point?.coordinate) {
          errors.push({
            id: item?.id,
            errorText: "Необходимо добавить координаты точки",
          });
        }
        if (!item?.title && !item?.check_point?.coordinate) {
          errors.push({
            id: item?.id,
            errorText: "Необходимо добавить координаты и название точки",
          });
        }
      }
      if (item?.type_of_route === "plot") {
        if (
          !item?.title &&
          item?.plot?.check_points[0]?.coordinate &&
          item?.plot?.check_points[1]?.coordinate
        ) {
          errors.push({
            id: item?.id,
            errorText: "Необходимо добавить название участку пути",
          });
        }
        if (
          item?.title &&
          (!item?.plot?.check_points[0]?.coordinate ||
            !item?.plot?.check_points[1]?.coordinate)
        ) {
          errors.push({
            id: item?.id,
            errorText: "Необходимо добавить координаты участку пути",
          });
        }
        if (
          !item?.title &&
          (!item?.plot?.check_points[0]?.coordinate ||
            !item?.plot?.check_points[1]?.coordinate)
        ) {
          errors.push({
            id: item?.id,
            errorText: "Необходимо добавить координаты и название участку пути",
          });
        }
      }
    });
    setRoutePartErrors(errors);
  }, [routeRetrieveData?.data?.route_parts]);

  const navigate = useNavigate();

  return (
    <ClickAwayListener onClickAway={() => handleTooltipOpen(null)}>
      <Box onClick={() => handleTooltipOpen(null)}>
        {isRoutesLoading ? (
          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              position: "relative",
              top: "30vh",
            }}
            direction="column"
          >
            <Stack
              sx={{
                maxWidth: "280px",
              }}
            >
              <DefaultLoader />
            </Stack>
          </Stack>
        ) : (
          <>
            {" "}
            {routeRetrieveData?.data?.route_parts.length > 0 ? (
              <>
                {routeRetrieveData?.data?.route_parts
                  ?.sort(sortByPriority)
                  .map((routePart: any, index: number) => (
                    <Stack
                      sx={{
                        marginTop: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      <Stack
                        direction="row"
                        sx={{
                          width: "100%",
                          marginBottom: "16px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Divider
                          sx={{
                            width: "50%",
                            position: "relative",
                            top: -3,
                          }}
                        />
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={openTooltip === routePart?.priority}
                          onClose={() => handleTooltipOpen(false)}
                          title={
                            <CreateTourTooltip
                              openGuideEditor={() => {
                                createPlot(
                                  {
                                    route: tourData?.data?.route,
                                  },
                                  {
                                    onSuccess: (data: any) => {
                                      setCreatedRoutePart(data?.data);

                                      setTimeout(() => {
                                        swapCreatedRoutePart(
                                          {
                                            priority: routePart?.priority,
                                          },
                                          {
                                            onSuccess: () => {
                                              navigate(
                                                `route-parts/${data?.data?.id}?type=${data?.data?.type_of_route}`
                                              );
                                            },
                                          }
                                        );
                                      }, 400);
                                    },
                                  }
                                );
                              }}
                              openPointEditor={() => {
                                createPoint(
                                  {
                                    route: tourData?.data?.route,
                                  },
                                  {
                                    onSuccess: (data: any) => {
                                      setCreatedRoutePart(data?.data);

                                      setTimeout(() => {
                                        swapCreatedRoutePart(
                                          {
                                            priority: routePart?.priority,
                                          },
                                          {
                                            onSuccess: () => {
                                              navigate(
                                                `route-parts/${data?.data?.id}?type=${data?.data?.type_of_route}`
                                              );
                                            },
                                          }
                                        );
                                      }, 400);
                                    },
                                  }
                                );
                              }}
                            />
                          }
                        >
                          <DefaultButton
                            onClick={(e) => {
                              e.stopPropagation();
                              if (openTooltip === routePart?.priority) {
                                handleTooltipOpen(null);
                              } else {
                                handleTooltipOpen(routePart?.priority);
                              }
                            }}
                            sx={{
                              marginTop: "16px",
                              width: "36px",
                              height: "24px",
                              transform: "translateY(-12px)",
                            }}
                          >
                            +
                          </DefaultButton>
                        </Tooltip>

                        <Divider
                          sx={{
                            width: "50%",
                            position: "relative",
                            top: -3,
                          }}
                        />
                      </Stack>
                      <Stack
                        direction="column"
                        draggable={true}
                        onDragStart={(e) =>
                          routePartsDragStartHandler(e, routePart)
                        }
                        onDragEnd={(e) => routePartsDragEndHandler(e)}
                        onDragLeave={(e) => routePartsDragLeaveHandler(e)}
                        onDragOver={(e) => routePartsDragOverHandler(e)}
                        onDrop={(e) => routePartsDropHandler(e, routePart)}
                      >
                        <TourPoint
                          id={routePart?.id}
                          routePartsErrors={routePartErrors}
                          image={
                            routePart?.check_point?.media?.sort(
                              sortByPriority
                            )?.[0]
                          }
                          title={routePart?.title}
                          description={
                            routePart?.type_of_route === "plot"
                              ? `Участок из ${routePart?.plot?.check_points?.length} точек`
                              : routePart?.check_point?.description
                          }
                          audioGuide={
                            routePart?.type_of_route === "plot"
                              ? !!routePart?.plot?.audio_guide
                              : !!routePart?.check_point?.audio_guide
                          }
                          handleOpen={() => {
                            navigate(
                              `route-parts/${routePart.id}?type=${routePart?.type_of_route}`
                            );
                            handleCurrentRoutePart(routePart);

                            routePart?.type_of_route === "plot"
                              ? handleOpenGuideEditor(true)
                              : handleOpenPointEditor(true);
                            routePart?.type_of_route === "plot"
                              ? handleOnlyCreatePlot(false)
                              : handleOnlyCreatePoint(false);
                          }}
                          handleDelete={() => {
                            handleCurrentRoutePart(routePart);
                            setTimeout(() => {
                              deleteRoutePart({});
                            }, 100);
                          }}
                          points={routePart?.plot?.check_points}
                          type={routePart?.type_of_route}
                          renderKey={renderkey}
                        />
                      </Stack>
                    </Stack>
                  ))}
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    marginTop: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Divider
                    sx={{
                      width: "50%",
                      position: "relative",
                      top: -3,
                    }}
                  />
                  <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={openTooltip === -1}
                    onClose={() => handleTooltipOpen(null)}
                    title={
                      <CreateTourTooltip
                        openGuideEditor={() => {
                          createPlot(
                            {
                              route: tourData?.data?.route,
                            },
                            {
                              onSuccess: (data: any) => {
                                navigate(
                                  `route-parts/${data?.data?.id}?type=${data?.data?.type_of_route}`
                                );
                              },
                            }
                          );
                        }}
                        openPointEditor={() => {
                          createPoint(
                            {
                              route: tourData?.data?.route,
                            },
                            {
                              onSuccess: (data: any) => {
                                navigate(
                                  `route-parts/${data?.data?.id}?type=${data?.data?.type_of_route}`
                                );
                              },
                            }
                          );
                        }}
                      />
                    }
                  >
                    <DefaultButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (openTooltip === -1) {
                          handleTooltipOpen(null);
                        } else {
                          handleTooltipOpen(-1);
                        }
                      }}
                      sx={{
                        marginTop: "16px",
                        width: "36px",
                        height: "24px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      +
                    </DefaultButton>
                  </Tooltip>

                  <Divider
                    sx={{
                      width: "50%",
                      position: "relative",
                      top: -3,
                    }}
                  />
                </Stack>
              </>
            ) : (
              <Stack
                sx={{
                  width: "100%",
                  alignItems: "center",
                  position: "relative",
                  top: "30vh",
                  display: routeRetrieveData?.data
                    ? "flex"
                    : "none",
                }}
                direction="column"
              >
                <Stack
                  sx={{
                    maxWidth: "280px",
                  }}
                >
                  <h5 className={styles.emptyPageTitle}>
                    Для создания тура необходимо добавить хотя бы 1 этап тура.
                  </h5>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    marginTop: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Divider
                    sx={{
                      width: "calc(50% - 60px)",
                      position: "relative",
                      right: 8,
                      top: -3,
                    }}
                  />
                  <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={openTooltip === -1}
                    onClose={() => handleTooltipOpen(null)}
                    title={
                      <CreateTourTooltip
                        openGuideEditor={() => {
                          createPlot(
                            {
                              route: tourData?.data?.route,
                            },
                            {
                              onSuccess: (data: any) => {
                                navigate(
                                  `route-parts/${data?.data?.id}?type=${data?.data?.type_of_route}`
                                );
                              },
                            }
                          );
                        }}
                        openPointEditor={() => {
                          createPoint(
                            {
                              route: tourData?.data?.route,
                            },
                            {
                              onSuccess: (data: any) => {
                                navigate(
                                  `route-parts/${data?.data?.id}?type=${data?.data?.type_of_route}`
                                );
                              },
                            }
                          );
                        }}
                      />
                    }
                  >
                    <DefaultButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (openTooltip === -1) {
                          handleTooltipOpen(null);
                        } else {
                          handleTooltipOpen(-1);
                        }
                      }}
                      sx={{
                        marginTop: "16px",
                        width: "125px",
                        height: "24px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      <AddIcon
                        sx={{
                          width: "16px",
                          height: "16px",
                          marginRight: "8px",
                        }}
                      />
                      <span
                        style={{
                          position: "relative",
                        }}
                      >
                        Добавить
                      </span>
                    </DefaultButton>
                  </Tooltip>

                  <Divider
                    sx={{
                      width: "calc(50% - 60px)",
                      position: "relative",
                      left: 8,
                      top: -3,
                    }}
                  />
                </Stack>
              </Stack>
            )}
          </>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default RouteParts;
