import { Stack } from "@mui/material";
import { Divider } from "../../../ui-kit/Divider/Divider";
import React, { useEffect, useState } from "react";
import AudioPlayer from "../../../ui-kit/Audio/Audio";
import PinDropIcon from "@mui/icons-material/PinDrop";
import styles from "../CreateTour.module.css";
import { useMutationQuery } from "../../../../api/useMutationQuery";
import { Input } from "../../../ui-kit/TextField/TextField";
import { useForm } from "react-hook-form";
import { CreatePointInterface } from "../CreatePoint/CreatePointInterface";
import _ from "lodash";
import { DefaultButton } from "../../../ui-kit/Button/Button";
import { useLocation } from "react-router";
import { DefaultLoader } from "../../../ui-kit/DefaultLoader/DefaultLoader";
import { useGetRouteParts } from "../../../../api/hooks/useGetRouteParts";

export const CreateEditablePlot = ({
  handleOpenMap,
  handleCurrentRoutePart,
  getRoutePartData,
  editRoutePart,
}: any) => {
  const [title, setTitle] = useState<string | null>(null);

  const location = useLocation();

  const { data: routePartRetrieveData, isLoading } = useGetRouteParts({
    routePartId: Number(location?.pathname?.split("/")[3]),
    enabled: !!location?.pathname?.split("/")[3],
  });

  useEffect(() => {
    handleCurrentRoutePart(routePartRetrieveData?.data);
  }, [handleCurrentRoutePart, routePartRetrieveData]);

  const { mutate: uploadPlotAudioGuide } =
    useMutationQuery({
      url: `plots/${routePartRetrieveData?.data?.plot?.id}/audio_guide_upload/`,
      method: "POST",
    });

  const { mutate: deletePlotAudioGuide } =
    useMutationQuery({
      url: `plots/${routePartRetrieveData?.data?.plot?.id}/audio_guide_delete/`,
      method: "DELETE",
    });

  const { register, setValue, watch } = useForm<CreatePointInterface>();

  useEffect(() => {
    setValue("title", routePartRetrieveData?.data?.title);
  }, [routePartRetrieveData?.data, setValue]);

  useEffect(() => {
    const debouncedEditRoutePart = _.debounce((data) => {
      editRoutePart(data);
    }, 500);

    if (title) {
      debouncedEditRoutePart({ title });
    }

    return () => {
      debouncedEditRoutePart.cancel(); // Для очистки при размонтировании
    };
  }, [editRoutePart, title]);
  return (
    <Stack
      sx={{
        height: "calc(100vh - 118px)",
        overflowY: "auto",
      }}
    >
      <h1
        className={styles.title}
        style={{
          marginBottom: "32px",
        }}
      >
        Участок пути
      </h1>

      {isLoading ? (
        <Stack
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <DefaultLoader />
        </Stack>
      ) : (
        <>
          {" "}
          <Stack>
            <Stack
              gap="16px"
              direction="row"
              sx={{
                marginTop: "32px",
              }}
            >
              <DefaultButton
                onClick={handleOpenMap}
                sx={{
                  width: "50%",
                }}
              >
                <PinDropIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                Настроить координаты
              </DefaultButton>
              {routePartRetrieveData?.data?.plot?.check_points[0]?.coordinate
                ?.coordinates[0] &&
                routePartRetrieveData?.data?.plot?.check_points[
                  routePartRetrieveData?.data?.plot?.check_points?.length - 1
                ]?.coordinate?.coordinates[0] && (
                  <Stack
                    sx={{
                      maxWidth: "50%",
                    }}
                  >
                    <p className={styles.editPlotSubtitle}>
                      Координаты начала:{" "}
                      <span
                        key={
                          routePartRetrieveData?.data?.plot?.check_points[0]
                            ?.coordinate?.coordinates[0]
                        }
                        style={{
                          color: "#7ACA14",
                        }}
                      >
                        {
                          routePartRetrieveData?.data?.plot?.check_points[0]
                            ?.coordinate?.coordinates[0]
                        }
                        ,
                        {
                          routePartRetrieveData?.data?.plot?.check_points[0]
                            ?.coordinate?.coordinates[1]
                        }
                      </span>
                    </p>
                    <p className={styles.editPlotSubtitle}>
                      Координаты окончания:{" "}
                      <span
                        key={
                          routePartRetrieveData?.data?.plot?.check_points[
                            routePartRetrieveData?.data?.plot?.check_points
                              ?.length - 1
                          ]?.coordinate?.coordinates[0]
                        }
                        style={{
                          color: "#7ACA14",
                        }}
                      >
                        {
                          routePartRetrieveData?.data?.plot?.check_points[
                            routePartRetrieveData?.data?.plot?.check_points
                              ?.length - 1
                          ]?.coordinate?.coordinates[0]
                        }
                        ,
                        {
                          routePartRetrieveData?.data?.plot?.check_points[
                            routePartRetrieveData?.data?.plot?.check_points
                              ?.length - 1
                          ]?.coordinate?.coordinates[1]
                        }
                      </span>
                    </p>
                  </Stack>
                )}
            </Stack>
          </Stack>
          <Divider
            sx={{
              marginTop: "32px",
              marginBottom: "32px",
            }}
          />
          <Input
            required
            label="Название"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            inputProps={{ maxLength: 255 }}
            helperText={
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  className={styles.formDescription}
                  style={{
                    opacity: "0.5",
                  }}
                >
                  {watch("title")?.length ?? 0} / 255
                </p>
              </Stack>
            }
            sx={{
              width: "100%",
              marginBottom: "16px",
            }}
            {...register("title", {
              onChange: (e) => {
                setTitle(e.target.value);
              },
            })}
          />
          <h1
            className={styles.title}
            style={{
              marginTop: "32px",
            }}
          >
            Аудиогид
          </h1>
          <Stack
            sx={{
              marginTop: "16px",
            }}
          >
            <AudioPlayer
              handleUploadPointAudioGuide={uploadPlotAudioGuide}
              getRoutePartData={getRoutePartData}
              audioGuide={
                routePartRetrieveData?.data?.plot?.audio_guide?.media?.original
              }
              type="plot"
              handleDeletePointAudioGuide={() =>
                deletePlotAudioGuide(
                  {},
                  {
                    onSuccess: () => {
                      getRoutePartData();
                    },
                    onError: () => {
                      getRoutePartData();
                    },
                  }
                )
              }
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default CreateEditablePlot;
