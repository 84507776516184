import { Box, ClickAwayListener, Stack, Tooltip } from "@mui/material";
import styles from "./TourList.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useGetReview } from "../../api/hooks/useGetReviews";
import { TourCardFeedback } from "../../components/ui-kit/TourCard/TourCardFeedback";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useGetReviewMedia } from "../../api/hooks/useGetReviewMedia";
import { MediaSlider } from "../../components/ui-kit/MediaSlider/MediaSlider";

const filtersParams = [
  {
    id: 0,
    title: "Сначала новые",
  },
  {
    id: 1,
    title: "Сначала положительные",
  },
  {
    id: 2,
    title: "Сначала отрицательные",
  },
  {
    id: 3,
    title: "Сначала с фото",
  },
  {
    id: 4,
    title: "Сначала полезные",
  },
];

export const ToursListReview = ({
  open,
  selectedCard,
  handleOpenReview,
}: any) => {
  const { data: reviewsData } = useGetReview({
    tripId: selectedCard?.trip_description?.id,
    enabled: !!selectedCard?.id,
  });

  const { data: reviewMedia } = useGetReviewMedia({
    tripId: selectedCard?.trip_description?.id,
    enabled: !!selectedCard?.id,
  });

  const [selectedFilter, setSelectedFilter] = useState(filtersParams[0]);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  return (
    <Box
      className={styles.toursListReviewWrapper}
      sx={{
        display: open ? "flex" : "none",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap="16px" alignItems="center">
          <h3 className={styles.toursListReviewCurrentTourTitle}>
            Отзывы{" "}
            <span
              style={{
                opacity: 0.6,
              }}
            >
              ({reviewsData?.data?.length})
            </span>
          </h3>
          <ClickAwayListener
            onClickAway={() => {
              setOpenTooltip(false);
            }}
          >
            <Tooltip
              placement="bottom-start"
              disableFocusListener
              disableHoverListener
              disableTouchListener
              PopperProps={{
                disablePortal: true,
              }}
              open={openTooltip}
              title={
                <Box className={styles.tourFeedbackTooltip}>
                  {filtersParams.map((item) => (
                    <Box
                      onClick={() => {
                        setSelectedFilter(item);
                        setOpenTooltip(false);
                      }}
                      key={item?.id}
                      className={styles.tourFeedbackTooltipItem}
                      sx={{
                        "&:hover": {
                          background:
                            "var(--Surface-Hover, rgba(255, 255, 255, 0.20))",
                        },
                      }}
                    >
                      {item?.title}
                    </Box>
                  ))}
                </Box>
              }
            >
              <p
                className={styles.tourFeedbackMore}
                onClick={() => {
                  setOpenTooltip(true);
                }}
              >
                {selectedFilter?.title}
                <ExpandMoreIcon
                  className={styles.tourFeedbackMoreIcon}
                  style={{
                    transform: openTooltip ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </p>
            </Tooltip>
          </ClickAwayListener>
        </Stack>
        <CloseIcon
          onClick={() => handleOpenReview(false)}
          sx={{
            color: "#81C728",
            width: "16px",
            height: "16px",
            cursor: "pointer",
          }}
        />
      </Stack>
      <Stack>
        <p
          className={styles.toursListReviewCurrentTourText}
          style={{
            marginBottom: "8px",
          }}
        >
          Фотографии и видео пользователя
        </p>
        <MediaSlider
          media={reviewMedia?.data?.map(
            (item: any) => item?.media?.media?.original
          )}
        />
      </Stack>

      <Stack className={styles.toursListReviewCurrentTour}>
        <img
          className={styles.toursListReviewCurrentTourimage}
          alt="card img"
          src={
            selectedCard?.trip_description?.media?.[0]?.media?.media?.original
          }
        />
        <Stack>
          <h5 className={styles.toursListReviewCurrentTourTitle}>
            {selectedCard?.title}
          </h5>
          <p
            className={styles.toursListReviewCurrentTourSubtitle}
            onClick={() => {
              const currentCard = document.querySelector(
                `.tour-card-${selectedCard?.trip_description?.id}`
              );
              if (currentCard) {
                currentCard.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }}
          >
            Показать карточку тура
          </p>
        </Stack>
      </Stack>

      <Stack gap="16px">
        {reviewsData?.data?.map((item: any) => (
          <TourCardFeedback
            user={item?.author}
            postedAt={item?.posted_at}
            createdAt={item?.created_at}
            review={item}
          />
        ))}
      </Stack>
    </Box>
  );
};
