/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";

import { Input } from "../../../ui-kit/TextField/TextField";
import {
  Stack,
  Box,
  Button,
  InputAdornment,
  Autocomplete,
  TextField,
} from "@mui/material";
import { sortByPriority } from "../../../../utils/sortByPriority";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import styles from "../CreateTour.module.css";
import { useMutationQuery } from "../../../../api/useMutationQuery";
import { useForm } from "react-hook-form";
import { ICreateTourMain } from "./CreateTourMainInterface";
import { zodResolver } from "@hookform/resolvers/zod";
import { createTourMainValidation } from "./CreateTourMainValidation";
import { convertSeconds } from "../../../../utils/secondsConvert";

import { enqueueSnackbar } from "notistack";
import { imagesFormats } from "../../../../utils/imgFormats";
import { Divider } from "../../../ui-kit/Divider/Divider";
import { DefaultLoader } from "../../../ui-kit/DefaultLoader/DefaultLoader";

export const CreateTourMain = ({
  tourData,
  editTitle,
  editDescription,
  createTour,
  isTourDataLoading,
}: any) => {
  const [localTourImages, setLocalTourImages] = React.useState<any>([]);
  const [title, setTitle] = React.useState<string | null>(null);
  const [description, setDescription] = React.useState<string | null>(null);
  const [short_description, setShortDescription] = React.useState<
    string | null
  >(null);
  const [expenses, setExpenses] = React.useState<number | null>(null);
  const [time_costs, setTimeCosts] = React.useState<number | null>(null);

  const [currentImageLocalTour, setCurrentImageLocalTour] =
    React.useState<any>(null);

  const {
    mutate: uploadDescriptionMedia,
    status: uploadDescriptionMediaStatus,
  } = useMutationQuery({
    url: `trips_description/${tourData?.data?.trip_description?.id}/media_upload/`,
    method: "POST",
  });

  const {
    mutate: deleteDescriptionMedia,
    status: deleteDescriptionMediaStatus,
  } = useMutationQuery({
    url: `trips_description/${tourData?.data?.trip_description?.id}/media_delete/`,
    method: "DELETE",
  });

  const { mutate: swapDescriptionMedia } = useMutationQuery({
    url: `trips_description/media_swap/${currentImageLocalTour?.id}/`,
    method: "POST",
  });

  const localTourImagesDragStartHandler = (e: any, item: any) => {
    setCurrentImageLocalTour(item);
  };
  const localTourImagesDragEndHandler = (e: any) => {
    setCurrentImageLocalTour(null);
  };

  const localTourImagesDragLeaveHandler = (e: any) => {};

  const localTourImagesDragOverHandler = (e: any) => {
    e.preventDefault();
  };

  const localTourImagesDropHandler = (e: any, item: any) => {
    e.preventDefault();
    swapDescriptionMedia(
      {
        priority: item.priority,
      },
      {
        onSuccess: (data) => {
          setLocalTourImages(data?.data);
        },
      }
    );
  };

  const handleImageUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && evt.target.files.length > 0) {
      if (imagesFormats?.includes(evt.target.files[0].type?.split("/")[1])) {
        if (evt.target.files[0]?.size / 1024 / 1024 <= 40) {
          const formData: FormData = new FormData();

          formData.append("media", evt.target.files[0]);

          uploadDescriptionMedia(formData);
        } else {
          enqueueSnackbar("Размер файла не может превышать 40МБ", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Неподдерживаемый формат изображения", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    setLocalTourImages(tourData?.data?.trip_description?.media);
  }, [tourData?.data?.trip_description?.media]);

  useEffect(() => {
    if (
      deleteDescriptionMediaStatus === "error" ||
      deleteDescriptionMediaStatus === "success"
    ) {
      createTour({});
    }
  }, [deleteDescriptionMediaStatus]);

  useEffect(() => {
    if (
      uploadDescriptionMediaStatus === "error" ||
      uploadDescriptionMediaStatus === "success"
    ) {
      createTour({});
    }
  }, [uploadDescriptionMediaStatus]);

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICreateTourMain>({
    mode: "all",
    resolver: zodResolver(createTourMainValidation),
  });

  useEffect(() => {
    setValue("title", tourData?.data?.title);
    setValue("description", tourData?.data?.trip_description?.description);
    setValue(
      "short_description",
      tourData?.data?.trip_description?.short_description
    );
    setValue("expenses", tourData?.data?.trip_description?.expenses);

    const { hours, days } = convertSeconds(
      Number(tourData?.data?.trip_description?.time_costs)
    );

    setValue("days", days);

    setValue("hours", hours);
  }, [tourData?.data]);

  useEffect(() => {
    const debouncedEditTourTitle = _.debounce((data) => {
      editTitle(data);
    }, 500);

    const debouncedEditTourDescription = _.debounce((data) => {
      editDescription(data);
    }, 500);

    if (title) {
      debouncedEditTourTitle({ title });
    }

    if (description) {
      debouncedEditTourDescription({ description });
    }

    if (short_description) {
      debouncedEditTourDescription({ short_description });
    }

    if (expenses) {
      debouncedEditTourDescription({ expenses });
    }

    if (time_costs) {
      debouncedEditTourDescription({ time_costs });
    }

    return () => {
      debouncedEditTourTitle.cancel();
      debouncedEditTourDescription.cancel();
    };
  }, [title, description, short_description, time_costs, expenses]);

  return (
    <Stack>
      {isTourDataLoading ? (
        <Stack
          sx={{
            width: "100%",
            alignItems: "center",
            position: "relative",
            top: "30vh",
          }}
          direction="column"
        >
          <Stack
            sx={{
              maxWidth: "280px",
            }}
          >
            <DefaultLoader />
          </Stack>
        </Stack>
      ) : (
        <>
          {" "}
          <Input
            error={watch("title")?.length === 0}
            InputLabelProps={{ shrink: true }}
            key={tourData?.data?.title}
            label="Название тура"
            variant="outlined"
            helperText={
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  className={styles.formDescription}
                  style={{
                    opacity: "0.5",
                  }}
                >
                  {watch("title")?.length ?? 0} / 255
                </p>
              </Stack>
            }
            required
            inputProps={{ maxLength: 255 }}
            sx={{
              width: "100%",
              marginBottom: "16px",
              marginTop: "16px",
            }}
            {...register("title", {
              onChange: (evt) => {
                setTitle(evt.target.value);
              },
            })}
          />
          <Input
            InputLabelProps={{ shrink: true }}
            label="Полное описание тура"
            variant="outlined"
            rows={2}
            multiline
            inputProps={{ style: { color: "white" } }}
            sx={{
              width: "100%",
              marginBottom: "16px",
            }}
            {...register("description", {
              onChange: (evt) => {
                setDescription(evt.target.value);
              },
            })}
          />
          <Input
            helperText={
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className={styles.formDescription}
                  style={{
                    opacity: "0.5",
                  }}
                >
                  Текст, который виден на карточке тура
                </p>
                <p
                  className={styles.formDescription}
                  style={{
                    opacity: "0.5",
                  }}
                >
                  {watch("short_description")?.length ?? 0} / 320
                </p>
              </Stack>
            }
            InputLabelProps={{ shrink: true }}
            label="Описание тура на карточке"
            variant="outlined"
            multiline
            inputProps={{ style: { color: "white" }, maxLength: 320 }}
            sx={{
              width: "100%",
              marginBottom: "32px",
            }}
            {...register("short_description", {
              onChange: (evt) => {
                setShortDescription(evt.target.value);
              },
            })}
          />
          <Input
            error={!!errors["expenses"]?.message}
            helperText={errors["expenses"]?.message}
            InputLabelProps={{ shrink: Number(watch("expenses")) >= 0 }}
            label="Предполагаемые затраты на тур"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CurrencyRubleIcon
                    sx={{
                      color: "#92918A",
                      width: "16px",
                      height: "24px ",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              marginBottom: "16px",
            }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onFocus={(evt) => {
              if (Number(evt.target.value) === 0) {
                setValue("expenses", " ");
              }
            }}
            {...register("expenses", {
              valueAsNumber: true,
              onChange: (evt) => {
                if (!isNaN(evt.target.value) && evt.target.value?.length > 0) {
                  setExpenses(evt.target.value);
                }
              },
              onBlur: (evt) => {
                if (evt.target.value.length === 0) {
                  setValue("expenses", " ");
                  setExpenses(evt.target.value);
                }
              },
            })}
          />
          <Divider
            sx={{
              marginTop: "32px",
              marginBottom: "32px",
            }}
          />
          <h1 className={styles.title}>Примерное время прохождения тура</h1>
          <Stack direction="row" gap="16px">
            <Input
              InputLabelProps={{ shrink: Number(watch("days")) >= 0 }}
              error={!!errors["days"]?.message}
              helperText={errors["days"]?.message}
              label="Дни"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              variant="outlined"
              sx={{
                marginTop: "16px",
                width: "100%",
                marginBottom: "16px",
              }}
              onFocus={(evt) => {
                if (Number(evt.target.value) === 0) {
                  setValue("days", " ");
                }
              }}
              {...register("days", {
                valueAsNumber: true,
                onChange: (evt) => {
                  setTimeCosts(
                    evt.target.value * 86400 + Number(watch("hours")) * 3600
                  );
                },
                onBlur: (evt) => {
                  if (evt.target.value.length === 0) {
                    setValue("days", " ");
                    setTimeCosts(
                      evt.target.value * 86400 + Number(watch("hours")) * 3600
                    );
                  }
                },
              })}
            />

            <Input
              InputLabelProps={{ shrink: Number(watch("hours")) >= 0 }}
              error={!!errors["hours"]?.message}
              helperText={errors["hours"]?.message}
              label="Часы"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              variant="outlined"
              sx={{
                marginTop: "16px",
                width: "100%",
                marginBottom: "16px",
              }}
              onFocus={(evt) => {
                if (Number(evt.target.value) === 0) {
                  setValue("hours", " ");
                }
              }}
              {...register("hours", {
                valueAsNumber: true,
                onChange: (evt) => {
                  setTimeCosts(
                    evt.target.value * 3600 + Number(watch("days")) * 86400
                  );
                },
                onBlur: (evt) => {
                  if (evt.target.value.length === 0) {
                    setValue("hours", " ");
                    setTimeCosts(
                      evt.target.value * 3600 + Number(watch("days")) * 86400
                    );
                  }
                },
              })}
            />
          </Stack>
          <Divider
            sx={{
              marginTop: "32px",
              marginBottom: "32px",
            }}
          />
          <h1 className={styles.title}>Изображения для карточки тура</h1>
          <Stack
            direction="row"
            gap="16px"
            sx={{
              marginTop: "16px",
            }}
          >
            <Stack direction="row" alignItems="end" gap="16px" flexWrap="wrap">
              {localTourImages?.sort(sortByPriority).map((item: any) => (
                <Stack
                  onDragStart={(e) => localTourImagesDragStartHandler(e, item)}
                  onDragEnd={(e) => localTourImagesDragEndHandler(e)}
                  onDragLeave={(e) => localTourImagesDragLeaveHandler(e)}
                  onDragOver={(e) => localTourImagesDragOverHandler(e)}
                  onDrop={(e) => localTourImagesDropHandler(e, item)}
                  draggable={true}
                  sx={{
                    width: "111px",
                    height: "111px",
                    borderRadius: "20px",
                    border: "1px solid rgba(255, 255, 255, 0.00)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${item?.media?.media?.original})`,
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <DragIndicatorIcon
                    sx={{
                      position: "relative",
                      transform: "translateX(35px) translateY(35px)",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      color: "white",
                      minWidth: "40px",
                      minHeight: "40px",
                      padding: "8px",
                      borderRadius: "50%",
                      cursor: "grab",
                    }}
                  />
                  <DeleteOutlineIcon
                    onClick={() => {
                      deleteDescriptionMedia({ id: item.id });
                    }}
                    sx={{
                      position: "relative",
                      transform: "translateY(-200%) translateY(12px)",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      color: "white",
                      maxWidth: "28px",
                      maxHeight: "28px",
                      left: "80px",
                      padding: "4px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              ))}
              <Button
                component="label"
                sx={{
                  border: "1px #a1b670 dotted",
                  width: "111px",
                  height: "111px",
                  borderRadius: "20px",
                }}
              >
                <UploadIcon
                  sx={{
                    color: "#a1b670",
                  }}
                />
                <input
                  type="file"
                  value={""}
                  hidden
                  accept=".jpeg, .png, .gif, .tiff, .webp, .postscript, .svg, .raw, .jpg"
                  onChange={(evt) => {
                    handleImageUpload(evt);
                  }}
                />
              </Button>
            </Stack>
          </Stack>
          <Divider
            sx={{
              marginTop: "32px",
              marginBottom: "32px",
            }}
          />
        </>
      )}
    </Stack>
  );
};
