import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

export const Input = styled(TextField)(({ theme }) => ({

  color: "white !important",
  borderBottomColor: "#A1B670 !important",
  input: {
    // height: '15px',
    color: "white",
  },
  "& label": {
    color: "white",
    // top: -2
  },
  "& label.Mui-focused": {
    color: "white",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A1B670",
    },
    "&:hover fieldset": {
      borderColor: "#A1B670",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A1B670",
    },
    "&.Mui-error fieldset": {
      borderColor: "red !important",
    },
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: "#A1B670",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#A1B670",
  },
  "& .Mui-disabled fieldset": {
    borderColor: "white !important",
  },
  "& .Mui-disabled": {
    color: "white !important",
    "& label": {
      color: "white !important",
    },
    input: {
      // height: '48px !important',
      color: "white !important",

    },
  },
}));
