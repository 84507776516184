export const PlotArrow = ({ color }: { color: string }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 3.69167L12.075 5.01667L13.25 3.83333L9.91667 0.5L6.58333 3.83333L7.75833 5.00833L9.08333 3.69167V8.83333H2.41667C1.5 8.83333 0.75 9.58333 0.75 10.5V15.5H2.41667V10.5H9.08333C10 10.5 10.75 9.75 10.75 8.83333V3.69167Z"
        fill={color}
      />
    </svg>
  );
};
