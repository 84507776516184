/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./LoginPage.module.css";
import { LoginLogo } from "../../assets/icons/LoginLogo";
import { Input } from "../../components/ui-kit/TextField/TextField";
import {
  DarkBorderButton,
  DefaultButton,
} from "../../components/ui-kit/Button/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { z } from "zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinearProgress from "@mui/material/LinearProgress";
import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import { textConstants } from "../../utils/textContanstants";
import { useMe } from "../../api/hooks/useMe";

type Inputs = {
  login: string;
};

export const LoginPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isEmailLogin, setIsEmailLogin] = useState<boolean>(false);

  const [authData, setAuthData] = useState<any>(null);

  const [policyUrl, setPolicyUrl] = useState<string>("");

  const regex = /^[0-9a-zA-Z_.:-]+@[a-zA-Z_]+\.[a-zA-Z_]{2,}$/;

  const schema = z.object({
    login: z.string().regex(regex, { message: "Некорректный email" }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<Inputs> = (values): void => {
    handleAuth(values);
  };

  const navigate = useNavigate();

  const authMutation = useMutation({
    mutationFn: (data) => {
      return fetch("https://tripper.bulltech.ru/api/users/authenticate/", {
        method: "POST",
        headers: new Headers({
          "content-type": "application/json",
          "User-Agent": window?.navigator?.userAgent,
        }),
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((json) => setAuthData(json));
    },
  });

  const authMutationTelegram = useMutation({
    mutationFn: () => {
      return fetch(
        "https://tripper.bulltech.ru/api/users/authenticate_telegram/",
        {
          method: "GET",
          headers: new Headers({
            "content-type": "application/json",
            "User-Agent": window?.navigator?.userAgent,
          }),
        }
      )
        .then((response) => response.json())

        .then((json) => window.location.replace(json.data.url));
    },
  });

  const { refetch: checkMe, status: checkMeStatus } = useMe({
    enabled: false,
  });

  const handleAuth = async (data: any) => {
    authMutation.mutate(data);
  };

  useEffect(() => {
    fetch(`https://tripper.bulltech.ru/api/policy/1/`)
      .then((response) => response.json())
      .then((data) => {
        setPolicyUrl(data?.data?.privacy_policy);
      });
  }, []);

  useEffect(() => {
    if (authMutation.status === "success") {
      setIsLoading(true);
      setIsEmailLogin(true);
      Cookies.set("refresh", authData?.data?.refresh);
      Cookies.set("access", authData?.data?.access);

      const intervalId = setInterval(() => {
        checkMe();
      }, 3000);

      if (checkMeStatus === "success") {
        navigate("/tours");
      }

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [authData?.data]);

  useEffect(() => {
    if (checkMeStatus === "success") {
      navigate("/tours");
    }
  }, [checkMeStatus]);

  useEffect(() => {
    if (Cookies.get("refresh") && Cookies.get("access")) {
      setIsLoading(true);
      const intervalId = setInterval(() => {
        checkMe();
      }, 3000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  return (
    <Box className={styles.wrapper}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          maxWidth: "366px",
        }}
      >
        <Box className={isLoading ? styles.fadeInTop : styles.noFade}>
          <Box className={styles.loaderWrapper}>
            <Stack direction="column" alignItems="center">
              <LoginLogo />
              {isLoading && (
                <>
                  {isEmailLogin ? (
                    <h3 className={styles.loaderText}>
                      На вашу почту отправлено письмо, с ссылкой для входа.
                      Пожалуйста,
                      <span
                        style={{
                          color: "#89A053",
                        }}
                      >
                        {" "}
                        перейдите по ней
                      </span>
                       для входа в аккаунт. Вы можете это сделать с {" "}
                      <span
                        style={{
                          color: "#89A053",
                        }}
                      >
                        {" "}
                        любого устройства
                      </span>
                    </h3>
                  ) : (
                    <Box
                      sx={{
                        width: "183px",
                        marginTop: "28px",
                        marginBottom: "26px",
                      }}
                    >
                      <LinearProgress
                        sx={{
                          height: "2px",
                          backgroundColor: "#A1B67030",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#A1B670",
                          },
                        }}
                      />
                    </Box>
                  )}
                  <DarkBorderButton
                    sx={{
                      width: "100%",
                      minWidth: "366px",
                      height: "40px",
                    }}
                    onClick={() => {
                      Cookies.remove("access");
                      Cookies.remove("refresh");
                      setIsLoading(false);
                      reset();
                    }}
                  >
                    <ArrowBackIcon
                      sx={{
                        width: "17px",
                        height: "17px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    Отмена
                  </DarkBorderButton>
                </>
              )}
            </Stack>
          </Box>
        </Box>
        {!isLoading && (
          <Stack sx={{ marginTop: "28px", marginBottom: "48px" }}>
            <Stack gap="16px">
              <h1 className={styles.title}>{textConstants["authTextMain"]}</h1>
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Input
                  // required
                  autoComplete="off"
                  label="Ваш email *"
                  variant="outlined"
                  error={!!errors.login?.message}
                  helperText={errors.login?.message}
                  sx={{
                    width: "100%",
                    marginBottom: "12px",
                    marginTop: "48px",
                    "& label": {
                      top: "-3px",
                    },
                  }}
                  onClick={() => {
                    setValue("login", "");
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    endAdornment: (
                      <IconButton>
                        <CloseIcon
                          sx={{
                            position: "absolute",
                            width: "16px",
                            height: "16px",
                            color: "white",
                            display:
                              watch("login")?.length > 0 ? "block" : "none",
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    ),
                  }}
                  {...register("login")}
                />
                <DefaultButton
                  type="submit"
                  sx={{
                    width: "100%",
                    marginBottom: "48px",
                  }}
                >
                  Войти через почту
                  <ArrowForwardIcon
                    sx={{
                      marginLeft: "8px",
                      height: "16px",
                      width: "16px",
                    }}
                  />
                </DefaultButton>
                <DarkBorderButton
                  onClick={() => {
                    authMutationTelegram.mutate();
                  }}
                  sx={{
                    width: "100%",
                  }}
                >
                  <TelegramIcon
                    sx={{
                      marginRight: "8px",
                      height: "18px",
                      width: "18px",
                    }}
                  />
                  Войти через Telegram
                </DarkBorderButton>
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>
      {!isLoading && (
        <Box className={styles.userPolicyText}>
          Нажимая на кнопку войти вы подтверждаете <br /> согласие с{" "}
          <a
            href={policyUrl}
            onClick={() => {
              window.open(policyUrl);
            }}
            style={{
              color: "#A1B670",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Политикой использования приложения
          </a>
        </Box>
      )}
    </Box>
  );
};
