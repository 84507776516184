export const getCurrentTabProfileParam = (value, data, isMe) => {
    switch (value) {
        case 'posts': {
            return data?.posted_count || 0
        };
        case 'passed': {
            return data?.lobby_count || 0
        };
        case 'created': {
            return isMe ? data?.created_count || 0 : data?.posted_count || 0
        };
    }
}