/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./Profile.module.css";
import { useNavigate, useParams } from "react-router";
import { Divider } from "../../components/ui-kit/Divider/Divider";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ProfileParamsItem } from "./ProfileParamsItem";
import { TabberItem } from "../../components/ui-kit/Tabber/TabberItem";
import { Tabber } from "../../components/ui-kit/Tabber/Tabber";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TelegramIcon from "@mui/icons-material/Telegram";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { fetchWithAuthorization } from "../../api/fetchWithAutorization";
import {
  DarkBorderButton,
  DefaultButton,
} from "../../components/ui-kit/Button/Button";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import TourCard from "../../components/ui-kit/TourCard/TourCard";
import AvatarPreview from "../../components/ui-kit/AvatarPreview/AvatarPreview";
import Avatar from "../../assets/img/Avatar.svg";
import { getLobbyStatus } from "../../utils/getLobbyStatus";
import { getCurrentTabProfileParam } from "../../utils/getCurrentTabProfileParam";
import { useMutationQuery } from "../../api/useMutationQuery";
import { ImagePreviewModal } from "../../components/Modals/ImagePreviewModal/ImagePreviewModal";
import EmptyProfile from "./EmptyProfile";
import { DefaultLoader } from "../../components/ui-kit/DefaultLoader/DefaultLoader";
import { getProfileTabsCurrentTitle } from "../../utils/getProfileTabsCurrentTitle";
import { useMe } from "../../api/hooks/useMe";
import { useGetUser } from "../../api/hooks/useGetUser";

const tabs = [
  {
    id: 0,
    title: "Публикации",
    value: "posts",
  },
  {
    id: 2,
    title: "Приключения",
    value: "passed",
  },
  {
    id: 3,
    title: "Созданные",
    value: "created",
  },
];

export const Profile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<any>("posts");
  const [currentQueryParams, setCurrentQueryParams] = useState<string>(
    `&user=${params?.userid}&posted=true`
  );
  const [currentUrl, setCurrentUrl] = useState<string>("trips");
  const [toursData, setToursData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [toursMetaData, setToursMetaData] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [isToursLoading, setIsToursLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsToursLoading(true);

    fetchWithAuthorization(`${currentUrl}/?page=${1}${currentQueryParams}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        setToursData([...toursData, ...res.data]);
        setToursMetaData(res.meta);
      })
      .finally(() => {
        setIsToursLoading(false);
      });
  }, [currentTab]);

  const scrollToBottomListener = (e: any) => {
    const target = e.target;
    if (page < Math.ceil(toursMetaData?.total / 20)) {
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        setPage((prev) => prev + 1);

        setTimeout(() => {
          setIsToursLoading(true);

          fetchWithAuthorization(
            `trips/feed/?page=${page + 1}${currentQueryParams}`,
            {
              method: "GET",
            }
          )
            .then((res) => res.json())
            .then((res) => {
              setToursData([...toursData, ...res.data]);
            })
            .finally(() => {
              // Устанавливаем загрузку в false после завершения запроса вне зависимости от результата
              setIsToursLoading(false);
            });
        }, 100);
      }
    }
  };

  useEffect(() => {
    const stack = document.querySelector("#tours");
    stack?.addEventListener("scroll", scrollToBottomListener);

    return () => {
      stack?.removeEventListener("scroll", scrollToBottomListener);
    };
  }, [toursData, toursMetaData]);

  const { data: userData, isLoading: isUserDataLoading } = useGetUser({
    userId: Number(params?.userid),
  });

  const { data: isMe, isLoading: isMeLoading } = useMe({
    enabled: true,
  });

  const { mutate: subscribe } = useMutationQuery({
    url: `users/subscribe/`,
    method: "POST",
  });

  const { mutate: unsubscribe } = useMutationQuery({
    url: `users/unsubscribe/`,
    method: "POST",
  });

  const getCurrentTabIcon = (tab: string) => {
    switch (tab) {
      case "posts":
        return (
          <ListAltIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#a1b670",
            }}
          />
        );
      case "favorite":
        return (
          <BookmarkBorderOutlinedIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#a1b670",
            }}
          />
        );
      case "passed":
        return (
          <DoneAllOutlinedIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#a1b670",
            }}
          />
        );
      case "created":
        return (
          <CreateOutlinedIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#a1b670",
            }}
          />
        );
    }
  };

  const postsTitle = useMemo(
    () => getProfileTabsCurrentTitle(currentTab, isMe, params as any),
    [isMe?.data?.id, params?.userid, currentTab]
  );

  return (
    <Box className={styles.profileWrapper}>
      {isMeLoading && isUserDataLoading ? (
        <Stack
          sx={{
            width: "100%",
            alignItems: "center",
            position: "relative",
            top: "45vh",
          }}
          direction="column"
        >
          <Stack
            sx={{
              maxWidth: "280px",
            }}
          >
            <DefaultLoader />
          </Stack>
        </Stack>
      ) : (
        <>
          <Stack>
            <Stack
              direction="row"
              sx={{
                width: "640px",
                gap: "16px",
              }}
            >
              <Stack>
                {userData?.data?.avatar_data?.media?.original ? (
                  <AvatarPreview
                    imgSrc={userData?.data?.avatar_data?.media?.original}
                    options={userData?.data?.avatar_data?.options}
                  />
                ) : (
                  userData?.data?.id && (
                    <Box
                      sx={{
                        minWidth: "128px",
                        width: "128px",
                        height: "128px",
                        background: `url(${Avatar}) lightgray 50% / cover no-repeat`,
                        backgroundSize: "70%",
                        backgroundColor: "rgba(0,0,0,0)",
                        borderRadius: "128px",
                        border: "2px solid rgba(255, 255, 255, 0.10)",
                        boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.40)",
                      }}
                    />
                  )
                )}
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  height: "fit-content",
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                  }}
                  justifyContent="space-between"
                >
                  <Stack>
                    <Stack direction="row" alignItems="center" gap="8px">
                      <h2
                        className={styles.profileUsername}
                        style={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {userData?.data?.username ??
                          userData?.data?.email ??
                          userData?.data?.telegram_username}
                      </h2>
                    </Stack>
                    {(userData?.data?.last_name ||
                      userData?.data?.first_name) && (
                      <p
                        style={{
                          marginTop: "8px",
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.profileText}
                      >{`(${userData?.data?.last_name} ${userData?.data?.first_name})`}</p>
                    )}
                    <Divider
                      sx={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        background: "rgba(137, 160, 83, 0.20)",
                      }}
                    />
                    <Stack gap="16px">
                      {userData?.data?.email?.length > 0 &&
                        userData?.data?.show_email && (
                          <Stack direction="row" gap="4px">
                            <EmailOutlinedIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "rgba(137, 160, 83, 1)",
                              }}
                            />{" "}
                            <p
                              className={styles.profileText}
                              style={{
                                color: "rgba(137, 160, 83, 1)",
                              }}
                            >
                              {" "}
                              {userData?.data?.email}
                            </p>
                          </Stack>
                        )}
                      {userData?.data?.telegram_username &&
                        userData?.data?.show_telegram && (
                          <Stack direction="row" gap="4px">
                            <TelegramIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "rgba(137, 160, 83, 1)",
                              }}
                            />{" "}
                            <p
                              className={styles.profileText}
                              style={{
                                color: "rgba(137, 160, 83, 1)",
                              }}
                            >
                              {" "}
                              {userData?.data?.telegram_username}
                            </p>
                          </Stack>
                        )}
                    </Stack>
                  </Stack>
                  <Stack>
                    {isMe?.data?.id === userData?.data?.id ? (
                      <DarkBorderButton
                        className={styles.buttonWrapper}
                        onClick={() => navigate(`/edit-profile/`)}
                        sx={{
                          padding: "10px 16px",
                          whiteSpace: "nowrap",
                          width: "100%",
                          fontSize: "12px",
                          color: "white",
                          border: "1px  rgba(137, 160, 83, 1) solid",
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.3)",
                          },
                        }}
                      >
                        Редактировать профиль
                      </DarkBorderButton>
                    ) : !userData?.data?.is_subscribed ? (
                      <DefaultButton
                        onClick={() => {
                          subscribe(
                            {
                              id: Number(params?.userid),
                            },
                            {
                              onSuccess: () => {
                                window.location.replace(
                                  `/profile/${params?.userid}`
                                );
                              },
                              onError: () => {
                                window.location.replace(
                                  `/profile/${params?.userid}`
                                );
                              },
                            }
                          );
                        }}
                      >
                        <AddCircleOutlinedIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                          }}
                        />{" "}
                        Подписаться
                      </DefaultButton>
                    ) : (
                      <DarkBorderButton
                        onClick={() => {
                          unsubscribe(
                            {
                              id: Number(params?.userid),
                            },
                            {
                              onSuccess: () => {
                                window.location.replace(
                                  `/profile/${params?.userid}`
                                );
                              },
                              onError: () => {
                                window.location.replace(
                                  `/profile/${params?.userid}`
                                );
                              },
                            }
                          );
                        }}
                      >
                        <RemoveCircleOutlineIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                          }}
                        />
                        Отписаться
                      </DarkBorderButton>
                    )}
                  </Stack>
                </Stack>

                {userData?.data?.description?.length > 0 && (
                  <Stack
                    sx={{
                      marginTop: "16px",
                      marginBottom: "16px",
                      maxWidth: "500px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <p className={styles.profileText}>
                      {userData?.data?.description}
                    </p>
                  </Stack>
                )}
                <Stack>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "16px",
                      marginTop: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(
                          `/profile/${userData?.data?.id}/subscribers?tab=subscribers`
                        );
                      }}
                    >
                      <ProfileParamsItem
                        value={userData?.data?.subscribers_count ?? 0}
                        param="Подписчиков"
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(
                          `/profile/${userData?.data?.id}/subscribers?tab=subscriptions`
                        );
                      }}
                    >
                      <ProfileParamsItem
                        value={userData?.data?.subscriptions_count ?? 0}
                        param="Подписки"
                      />
                    </Box>
                  </Box>
                </Stack>
              </Stack>
            </Stack>

            <Divider
              sx={{
                minHeight: "1px",
                marginTop: "24px",
                marginBottom: "16px",
                background: "rgba(137, 160, 83, 0.20)",
              }}
            />

            <Stack>
              <p
                className={styles.profileText}
                style={{
                  marginBottom: "8px",
                }}
              >
                Фотографии и видео пользователя
              </p>
              <Stack
                id="custom_scroll"
                direction="row"
                gap="8px"
                sx={{
                  marginBottom: "16px",
                  maxWidth: "640px",
                  overflowX: "scroll",
                  paddingBottom: "8px",
                }}
              >
                {userData?.data?.medias?.map((media: any, index: number) => {
                  return (
                    <Stack
                      onClick={() => {
                        setActiveIndex(index);
                        setOpenPreviewModal(true);
                      }}
                      sx={{
                        minWidth: "48px",
                        minHeight: "48px",
                        borderRadius: "4px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${media?.media?.original})`,
                      }}
                    />
                  );
                })}
              </Stack>
            </Stack>
            <Tabber>
              {tabs.map((item: any) => (
                <TabberItem
                  selected={item?.value === currentTab}
                  handleClick={() => {
                    if (item?.value !== currentTab) {
                      setToursData([]);
                      setToursMetaData(null);
                      setCurrentTab(item?.value);
                      if (item.value === "posts") {
                        setCurrentUrl("trips");

                        setCurrentQueryParams(
                          `&user=${params?.userid}&posted=true`
                        );
                      }
                      // if (item.value === "passed") {
                      //   setCurrentQueryParams(`&user=${params?.userid}&posted=true`);
                      // }
                      if (item.value === "created") {
                        setCurrentUrl("trips");
                        setCurrentQueryParams(`&user=${params?.userid}`);
                      }
                      if (item.value === "passed") {
                        setCurrentUrl("adventures");
                        setCurrentQueryParams(`&user=${params?.userid}`);
                      }
                    }
                  }}
                  key={item?.id}
                  title={
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      gap="4px"
                    >
                      {getCurrentTabIcon(item.value)}
                      {item?.title}{" "}
                      <span
                        style={{
                          opacity: "0.6",
                        }}
                      >
                        {" "}
                        (
                        {getCurrentTabProfileParam(
                          item?.value,
                          userData?.data,
                          isMe?.data?.id === userData?.data?.id
                        )}
                        )
                      </span>
                    </Stack>
                  }
                />
              ))}
            </Tabber>
            <Divider
              sx={{
                marginTop: "2px",
              }}
            />
            <Stack
              id={"tours"}
              sx={{
                paddingTop: "16px",
                gap: "16px",
                overflowY: "scroll",
                flexGrow: 1,
              }}
            >
              {isToursLoading ? (
                <Stack
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    position: "relative",
                    top: "10vh",
                  }}
                  direction="column"
                >
                  <Stack
                    sx={{
                      maxWidth: "280px",
                    }}
                  >
                    <DefaultLoader />
                  </Stack>
                </Stack>
              ) : (
                <>
                  {(currentTab === "posts" || currentTab === "created") &&
                    (toursData?.length > 0 ? (
                      toursData?.map((item: any) => (
                        <TourCard
                          displayReviews={false}
                          isOnlyCreated={currentTab === "created"}
                          key={item.id}
                          user={item?.user}
                          title={item?.title}
                          posted={item?.posted}
                          created={item?.created_at}
                          description={
                            item?.trip_description?.short_description
                          }
                          altDescription={item?.trip_description?.description}
                          expenses={item?.trip_description?.expenses}
                          distance={item?.trip_description?.distance}
                          time={item?.trip_description?.time_costs}
                          points={item?.trip_description?.points?.length}
                          picture={item?.trip_description?.media}
                          id={item?.trip_description?.id}
                          evaluationCount={item?.evaluation_count}
                          avgRating={item?.avg_rating}
                          review={toursData
                            ?.filter((item) => item?.object_type === "review")
                            ?.filter(
                              (trip) => trip?.trip_description?.id === item?.id
                            )}
                          inFavorite={item?.in_favorite}
                        />
                      ))
                    ) : (
                      <EmptyProfile text={postsTitle} />
                    ))}
                  {currentTab === "passed" &&
                    toursData?.length > 0 &&
                    toursData?.map((item: any) => (
                      <Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{
                            background: "rgba(255, 255, 255, 0.10)",
                            padding: "16px",
                          }}
                        >
                          <Stack gap="16px">
                            <Stack>
                              <p
                                className={styles.profileText}
                                style={{
                                  marginBottom: "8px",
                                }}
                              >
                                Статус тура:
                              </p>
                              <ProfileParamsItem
                                value={getLobbyStatus(item?.lobby?.status)}
                              />
                            </Stack>
                            <Stack>
                              <p
                                className={styles.profileText}
                                style={{
                                  marginBottom: "8px",
                                }}
                              >
                                Дата начала:
                              </p>
                              <ProfileParamsItem
                                value={new Date(
                                  item?.lobby?.start_time * 1000
                                ).toLocaleDateString()}
                              />
                            </Stack>
                          </Stack>
                          <Stack>
                            <p className={styles.profileText}>
                              Участники тура:
                            </p>

                            {item?.lobby?.lobby_members?.map((member: any) => {
                              return (
                                <Stack
                                  direction="row"
                                  gap="8px"
                                  alignItems="center"
                                  sx={{
                                    marginTop: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate(`/profile/${member?.user?.id}`);
                                  }}
                                >
                                  <AvatarPreview
                                    imgSrc={
                                      member?.user?.avatar_data?.media?.original
                                    }
                                    options={member?.user?.avatar_data?.options}
                                    height={32}
                                    width={32}
                                  />{" "}
                                  <p className={styles.subtitle}>
                                    {member?.user?.username}
                                  </p>
                                </Stack>
                              );
                            })}
                          </Stack>
                          <Stack>
                            <p
                              className={styles.profileText}
                              style={{
                                textAlign: "right",
                                opacity: "0.6",
                              }}
                            >
                              топ-3
                            </p>

                            {item?.lobby?.lobby_members?.map((member: any) => {
                              return (
                                <Stack
                                  direction="row"
                                  gap="4px"
                                  alignItems="center"
                                  sx={{
                                    height: "32px",
                                    marginTop: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate(`/profile/${member?.user?.id}`);
                                  }}
                                >
                                  <p
                                    className={styles.subtitle}
                                    style={{
                                      color: "rgba(255, 255, 255, 0.6)",
                                      opacity: 0.6,
                                    }}
                                  >
                                    Пройдено
                                  </p>
                                  <p className={styles.percent}>
                                    {member?.complete_percent}%
                                  </p>
                                </Stack>
                              );
                            })}
                          </Stack>
                        </Stack>
                        <TourCard
                          key={item?.trip?.id}
                          user={item?.trip?.user}
                          title={item?.trip?.title}
                          posted={item?.trip?.posted}
                          description={
                            item?.trip?.trip_description?.short_description
                          }
                          altDescription={
                            item?.trip?.trip_description.description
                          }
                          expenses={item?.trip?.trip_description.expenses}
                          distance={item?.trip?.trip_description.distance}
                          time={item?.trip?.trip_description.time_costs}
                          points={item?.trip?.trip_description.points?.length}
                          picture={item?.trip?.trip_description?.media}
                          id={item?.trip_description?.id}
                          evaluationCount={item?.evaluation_count}
                          avgRating={item?.avg_rating}
                          review={toursData
                            ?.filter((item) => item?.object_type === "review")
                            ?.filter(
                              (trip) => trip?.trip_description?.id === item?.id
                            )}
                          inFavorite={item?.in_favorite}
                        />
                      </Stack>
                    ))}
                  {currentTab === "passed" && toursData?.length === 0 && (
                    <EmptyProfile text={postsTitle} />
                  )}
                </>
              )}
            </Stack>
          </Stack>
          <ImagePreviewModal
            open={openPreviewModal}
            imagesArr={userData?.data?.medias?.map((item: string) => ({
              media: item,
            }))}
            activeIndex={activeIndex}
            handleActiveIndex={setActiveIndex}
            handleClose={() => setOpenPreviewModal(false)}
          />
        </>
      )}
    </Box>
  );
};
