import React, { useState } from "react";

import { ToursCalculateZoom } from "./ToursCalculateZoom";
import { DefaultMapMarker } from "../../CreateTour/MapMarker/MapMarker";
import { DefaultButton } from "../../../ui-kit/Button/Button";
import { Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { MapContainer } from "../../../MapKit/MapContainer/MapContainer";
import { Marker } from "../../../MapKit/Marker/Marker";
import CircleIcon from "@mui/icons-material/Circle";

const ToursMap = ({ data, selectedCard }: any) => {
  const [zoom, setZoom] = useState<number>(5);

  return (
    <>
      {" "}
      <Stack
        direction="column"
        gap="8px"
        sx={{
          position: "absolute",
          zIndex: 10000,
          right: 16,
          bottom: 32,
        }}
      >
        <DefaultButton
          onClick={() => {
            setZoom((prev) => (prev += 1));
          }}
          sx={{
            minWidth: "36px !important",
            maxWidth: "36px !important",
            height: "36px",
          }}
        >
          <AddIcon
            sx={{
              width: "16px",
              height: "16px",
            }}
          />
        </DefaultButton>
        <DefaultButton
          onClick={() => {
            setZoom((prev) => (prev -= 1));
          }}
          sx={{
            minWidth: "36px !important",
            maxWidth: "36px !important",
            height: "36px",
          }}
        >
          <RemoveIcon
            sx={{
              width: "16px",
              height: "16px",
            }}
          />
        </DefaultButton>
      </Stack>
      <MapContainer
        style={{
          width: "100vw",
          height: "100vh",
          pointerEvents: "none",
        }}
        center={[37, 55]}
        zoom={0}
      >
        {data?.length > 0 &&
          data?.map((item: any, index: number) => (
            <Marker
              coordinates={[
                item?.point?.coordinates?.[1],
                item?.point?.coordinates?.[0],
              ]}
              icon={
                <DefaultMapMarker
                  selected={
                    selectedCard?.trip_description.id ===
                    item?.trip_description.id
                  }
                  filled
                  number={
                    <CircleIcon
                      sx={{
                        width: "16px",
                        height: "16px",
                        color:
                          selectedCard?.trip_description.id ===
                          item?.trip_description.id
                            ? "white"
                            : "rgba(129, 199, 40, 1)",
                      }}
                    />
                  }
                />
              }
            />
          ))}
        <ToursCalculateZoom selectedCard={selectedCard} zoom={zoom} />
      </MapContainer>
    </>
  );
};

export default ToursMap;
