import { Box, Stack } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ToursMapFiltersTag.module.css";

const ToursMapFiltersTag = ({ handleDelete, text, value }: any) => {
  return (
    <Stack className={styles.wrapper} direction="row" gap="4px" alignItems="center">
      <Stack>
        <p className={styles.text}>{text}:</p>
      </Stack>
      <Stack>
        {" "}
        <p className={styles.value}>{value}</p>
      </Stack>
      <CloseIcon onClick={handleDelete} sx={{
        backgroundColor: '#A1B670',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        color: 'white',
        cursor: 'pointer'
      }}/>
    </Stack>
  );
};

export default ToursMapFiltersTag;
