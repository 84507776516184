/* eslint-disable react-hooks/exhaustive-deps */
import maplibregl, { Map } from "maplibre-gl";
import { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { DefaultMapMarker } from "../../blocks/CreateTour/MapMarker/MapMarker";

export const CreateCheckpoint = ({
  map,
  updateKey,
  coordinates,
  handleMapCoordinates,
}: {
  map?: Map;
  updateKey: number;
  coordinates: any;
  handleMapCoordinates: (coord: any) => void;
}) => {
  const markersRef = useRef([]);
  useEffect(() => {
    if (!map) return;
    map.flyTo({
      center: coordinates,
      speed: 2,
      curve: 1,
    });
    const el = document.createElement("div");

    const root = createRoot(el);
    root.render(<DefaultMapMarker filled />);

    const markerEl = new maplibregl.Marker({
      element: el,
      draggable: true,
    }).setLngLat(coordinates);
    markerEl.addTo(map);
    (markersRef.current as any).push(markerEl);
    markerEl?.on("dragend", (e) => {
      handleMapCoordinates(e?.target?._lngLat);
      map.flyTo({
        center: [e?.target?._lngLat?.lng, e?.target?._lngLat?.lat],
        speed: 2,
        curve: 1,
      });
    });

    return () => {
      markersRef.current.forEach((marker: any) => marker.remove());
      markersRef.current = [];
    };
  }, [map, updateKey]);

  return null;
};
