import { styled } from "@mui/material/styles";
import { Step, StepLabel } from "@mui/material";

const StyledStep = styled(Step)(({ theme }) => ({
  "& .MuiStepLabel-label": {
    color: "white",
  },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "#81C728",
  },
  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
    color: "white",
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: "white",
    fill: "#81C728",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: "white", // Just text label (ACTIVE)
  },
  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
    fill: "white", // circle's number (ACTIVE)
  },
  "& .MuiStepIcon-root .Mui-completed": {
    fill: "#a1b67050",
    color: "#81C728",
    
  },
  "&  .MuiStepIcon-root": {
    color: "#a1b67050",
    backgroundColor: "#231E22 !important",
    borderRadius: '1000px'
    // cursor: "pointer",
  },
}));

const StyledStepLabel = styled(StepLabel)({
  color: "white",
});

const CustomStep = ({ label, ...props }: any) => {
  return (
    <StyledStep {...props}>
      <StyledStepLabel>{label}</StyledStepLabel>
    </StyledStep>
  );
};

export default CustomStep;
