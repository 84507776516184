import {
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./ToursMapFilrers.module.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { convertSeconds } from "../../../utils/secondsConvert";
import { CustomSlider } from "../../../components/ui-kit/CustomSlider/CustomSlider";
import { CustomRadio } from "../../../components/ui-kit/CustomRadio/CustomRadio";
import _ from "lodash"; // Import Lodash
import ToursMapFiltersTag from "../ToursMapFiltersTag/ToursMapFiltersTag";
import { getDurationKey } from "../../../utils/getDurationKeys";

export const ToursMapFilters = ({
  open,
  handleClose,
  toursData,
  setTourDuration,
  tourDuration,
  tourExpenses,
  setTourExpenses,
  refetch,
  priceFilters,
  handleClear,
  circleRadius,
  setCircleRadius,
}: any) => {
  return (
    <Stack
      className={styles.toursMapFiltersWrapper}
      sx={{
        display: open ? "flex" : "none",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h4 className={styles.toursMapFiltersTitle}>Фильтры</h4>
        <ExpandLessIcon
          onClick={() => {
            handleClose();
          }}
          sx={{
            cursor: "pointer",
            width: "16px",
            height: "16px",
            marginRight: "8px",
            color: "#a1b670",
          }}
        />
      </Stack>
      <Stack direction="row" justifyContent="end">
        <Button
          onClick={handleClear}
          sx={{
            marginTop: "16px",
            fontSize: "12px",
            width: "50%",
            color: "white",
            background: "rgba(0,0,0,0)",
            border: "1px rgba(0,0,0,0) solid !important",

            "&:hover": {
              border: "1px #a1b670 solid !important",
            },
          }}
        >
          Очистить Все фильтры
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center">
        <p
          className={styles.filtersSubtitle}
          style={{
            marginBottom: "16px",
            marginTop: "16px",
            minWidth: "50%",
          }}
        >
          Радиус поиска, км
        </p>
        <Stack width="100%" key={priceFilters?.max}>
          <CustomSlider
            // defaultValue={tourExpenses}
            value={circleRadius}
            onChange={(e: any) => {
              setCircleRadius(e?.target.value);
            }}
            onChangeCommitted={_.throttle((e: any) => {
              refetch();
            }, 2000)}
            // sx={{
            //   pointerEvents: toursData?.length > 1 ? "all" : "none",
            // }}
            min={0}
            max={100}
            step={1}
            valueLabelDisplay="auto"
          />

          <Stack direction="row" justifyContent="space-between">
            <p className={styles.filtersMark}>0</p>
            <p className={styles.filtersMark}>100</p>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <p
          className={styles.filtersSubtitle}
          style={{
            marginBottom: "16px",
            marginTop: "16px",
            minWidth: "50%",
          }}
        >
          Доп. траты, ₽
        </p>
        <Stack width="100%" key={priceFilters?.max}>
          {tourExpenses && priceFilters?.max > 0 && (
            <CustomSlider
              defaultValue={tourExpenses}
              value={tourExpenses}
              onChange={(e: any) => {
                setTourExpenses(e?.target.value);
              }}
              onChangeCommitted={_.throttle((e: any) => {
                refetch();
              }, 1500)}
              sx={{
                pointerEvents: toursData?.length > 1 ? "all" : "none",
              }}
              min={priceFilters?.min || 0}
              max={priceFilters?.max || 0}
              step={100}
              valueLabelDisplay="auto"
            />
          )}
          {priceFilters?.min === 0 && priceFilters?.max === 0 && (
            <CustomSlider
              defaultValue={[0, 100]}
              value={[0, 100]}
              sx={{
                pointerEvents: "none",
              }}
              min={0}
              max={100}
              step={100}
              valueLabelDisplay="auto"
            />
          )}

          {tourExpenses && priceFilters?.max > 0 && (
            <Stack direction="row" justifyContent="space-between">
              <p className={styles.filtersMark}>
                {" "}
                {priceFilters?.min === -1 ? 0 : priceFilters?.min}
              </p>
              <p className={styles.filtersMark}>
                {priceFilters?.max === -1 ? 0 : priceFilters?.max}
              </p>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Box>
        <p
          className={styles.filtersSubtitle}
          style={{
            marginBottom: "16px",
            marginTop: "16px",
          }}
        >
          Оценка тура пользователями
        </p>
        <Stack direction="row" gap="24px">
          <RadioGroup
            onChange={(e) => {
              setTourDuration(e.target.value);
            }}
          >
            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value=""
              control={<CustomRadio checked={tourDuration === ""} />}
              label={<span className={styles.editProfileLabel}>Любая</span>}
            />
            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value="time_cost_min=3600&time_cost_max=7200"
              control={
                <CustomRadio
                  checked={
                    tourDuration === "time_cost_min=3600&time_cost_max=7200"
                  }
                />
              }
              label={
                <span className={styles.editProfileLabel}>3,5 и выше</span>
              }
            />
          </RadioGroup>
          <RadioGroup
            onChange={(e) => {
              setTourDuration(e.target.value);
            }}
          >
            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value="time_cost_max=3600"
              control={
                <CustomRadio checked={tourDuration === "time_cost_max=3600"} />
              }
              label={
                <span className={styles.editProfileLabel}>2,5 и выше</span>
              }
            />

            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value="time_cost_max=86400"
              control={
                <CustomRadio checked={tourDuration === "time_cost_max=86400"} />
              }
              label={
                <span className={styles.editProfileLabel}>4,5 и выше</span>
              }
            />
          </RadioGroup>
        </Stack>
      </Box>
      <Box>
        <p
          className={styles.filtersSubtitle}
          style={{
            marginBottom: "16px",
            marginTop: "16px",
          }}
        >
          Длительность тура
        </p>
        <Stack direction="row" gap="24px">
          <RadioGroup
            onChange={(e) => {
              setTourDuration(e.target.value);
            }}
          >
            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value=""
              control={<CustomRadio checked={tourDuration === ""} />}
              label={<span className={styles.editProfileLabel}>Любая</span>}
            />
            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value="time_cost_min=3600&time_cost_max=7200"
              control={
                <CustomRadio
                  checked={
                    tourDuration === "time_cost_min=3600&time_cost_max=7200"
                  }
                />
              }
              label={<span className={styles.editProfileLabel}>1-2 часа</span>}
            />
            <FormControlLabel
              sx={{
                maxHeight: "24px",
              }}
              value="time_cost_min=86400"
              control={
                <CustomRadio checked={tourDuration === "time_cost_min=86400"} />
              }
              label={
                <span className={styles.editProfileLabel}>Больше 1 дня</span>
              }
            />
          </RadioGroup>
          <RadioGroup
            onChange={(e) => {
              setTourDuration(e.target.value);
            }}
          >
            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value="time_cost_max=3600"
              control={
                <CustomRadio checked={tourDuration === "time_cost_max=3600"} />
              }
              label={<span className={styles.editProfileLabel}>До 1 часа</span>}
            />

            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value="time_cost_max=86400"
              control={
                <CustomRadio checked={tourDuration === "time_cost_max=86400"} />
              }
              label={<span className={styles.editProfileLabel}>До 1 дня</span>}
            />
            <FormControlLabel
              sx={{
                maxHeight: "24px",
                marginBottom: "8px",
              }}
              value="time_cost_min=172800"
              control={
                <CustomRadio
                  checked={tourDuration === "time_cost_min=172800"}
                />
              }
              label={
                <span className={styles.editProfileLabel}>Больше 2 дней</span>
              }
            />
          </RadioGroup>
        </Stack>
      </Box>
    </Stack>
  );
};
