type UserData = {
    data: {
      id: string;
    };
  };
  
  type Params = {
    userid: string;
  };
  
  export const getProfileTabsCurrentTitle = (
    currentTab: string,
    isMe: UserData | null,
    params: Params
  ): string => {
    if (currentTab === "posts") {
      return isMe?.data?.id == params?.userid
        ? "У вас пока нет ни одной публикации"
        : "Пользователь пока не добавил публикаций";
    } else if (currentTab === "created") {
      return isMe?.data?.id == params?.userid
        ? "У вас пока нет созданных туров"
        : "У пользователя пока нет созданных туров";
    } else if (currentTab === "passed") {
      return isMe?.data?.id == params?.userid
        ? "У вас пока нет пройденных приключений"
        : "У пользователя пока нет пройденных приключений";
    } else {
      return "";
    }
  };