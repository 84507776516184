import Cookies from "js-cookie";
import { enqueueSnackbar } from "notistack";
import { currentErrors } from "./errorsHandlers";

export class FetchError extends Error {
  response: Response;

  constructor(response: Response) {
    super(
      response
        ? `Request failed with status code ${response.status}`
        : "Network Error"
    );
    this.response = response;
  }
}

export async function fetchWithAuthorization(
  url: string,
  options: RequestInit
): Promise<Response> {
  const token = Cookies.get("access");
  const base_url = "https://tripper.bulltech.ru/api/";
  if (!token) {
    // Redirect to login if no token is found
    if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }

  const headers: any = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    ...options.headers,
  };

  if (options.body instanceof FormData) {
    delete headers["Content-Type"];
  }

  const response = await fetch(`${base_url}${url}`, { ...options, headers });

  if (response.status === 401) {
    try {
      const refreshToken = Cookies.get("refresh");
      if (!refreshToken) {
        Cookies.remove("access");
        Cookies.remove("refresh");
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
      }

      const refreshResponse = await fetch(`${base_url}users/refresh/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (refreshResponse.ok) {
        const data = await refreshResponse.json();

        Cookies.remove("access");

        Cookies.set("access", data.data.access);

        return fetchWithAuthorization(url, options);
      } else {
        Cookies.remove("access");
        Cookies.remove("refresh");
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
      }
    } catch (error) {
      Cookies.remove("access");
      Cookies.remove("refresh");
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
  } else if (!response.ok) {
    const error = await response.text();
    const errorJSON = JSON.parse(error);

    if (window.location.pathname !== "/login") {
      if (errorJSON.errors.length > 0) {
        errorJSON.errors.forEach((item: any) => {
          if (item?.message?.length > 0) {
            enqueueSnackbar(
              Object.keys(currentErrors).includes(item.message)
                ? currentErrors[item.message]
                : item.message,
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar(errorJSON.message, {
              variant: "error",
            });

            return;
          }
        });
      }
    }
    return errorJSON;
  }

  return response;
}
