import { Stack } from "@mui/material";
import { Wrapper } from "./components/Wrapper/Wrapper";
import { Menu } from "./components/blocks/Menu/Menu";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ToursList } from "./pages/ToursList/ToursList";
import { TourDetail } from "./components/blocks/TourDetail/TourDetail";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { useEffect } from "react";
import { CreateTour } from "./components/blocks/CreateTour/CreateTour";
import { Profile } from "./pages/Profile/Profile";
import { EditProfile } from "./pages/EditProfile/EditProfile";
import { ToursMap } from "./pages/ToursMap/ToursMap";
import Subscribers from "./pages/Subscribers/Subscribers";
import { useMe } from "./api/hooks/useMe";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: isMe } = useMe({
    enabled: location.pathname !== "/" && location.pathname !== "/login",
  });

  return (
    <Wrapper>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "#282822",
        }}
      >
        {location.pathname !== "/login" && <Menu userData={isMe?.data} />}
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="tours-map/" element={<ToursMap />} />
          <Route path="tours" element={<ToursList />} />
          <Route path="tours/:tourid" element={<TourDetail />} />
          <Route path="reviews/:reviewid" element={<TourDetail />} />

          <Route path="create-tour/*" element={<CreateTour />} />
          <Route path="edit-tour/*" element={<CreateTour isEdit />} />
          <Route path="profile/:userid" element={<Profile />} />
          <Route path="profile/:userid/subscribers" element={<Subscribers />} />
          <Route path="edit-profile/" element={<EditProfile />} />
        </Routes>
      </Stack>
    </Wrapper>
  );
}

export default App;
