/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect } from "react";
import maplibregl, { LngLatLike, Map } from "maplibre-gl";
import { createRoot } from "react-dom/client";

export const Marker = ({
  map,
  icon,
  isDraggable,
  onDragEnd,
  onDragStart,
  coordinates,
  onMarkerClick,
}: {
  map?: Map;
  icon: ReactElement;
  isDraggable?: boolean;
  onDragEnd?: any;
  coordinates: LngLatLike;
  onMarkerClick?: (e: any) => void;
  onDragStart?: any;
}) => {
  useEffect(() => {
    if (!map) return;
    const el = document.createElement("div");
    const root = createRoot(el);
    root.render(icon);

    const markerEl = new maplibregl.Marker({
      element: el,
      draggable: isDraggable,
    }).setLngLat(coordinates);

    if (onDragEnd) {
      markerEl.on("dragend", onDragEnd);
    }

    if (onDragStart) {
      markerEl.on("dragstart", onDragStart);
    }

    markerEl.addTo(map);

    if (!!onMarkerClick) {
      markerEl.getElement().addEventListener("click",onMarkerClick);
    }

    return () => {
      if (markerEl) {
        markerEl.remove(); // Удаление маркера
        el.remove();
      }
    };
  }, [map]);
  return null;
};
