/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styles from "./MapMarker.module.css";

import startIcon from "../../../../assets/img/IconStart1.svg";
import finishIcon from "../../../../assets/img/IconFinish1.svg";
import { Box } from "@mui/material";
import { PlotArrow } from "../../../../assets/icons/PlotArrow";

export const DefaultMapMarker = ({
  number,
  icon,
  selected,
  index,
  start,
  finish,
  plotMarker,
  plotLength,
  filled,
  handleClick,
  isLegend,
}: any) => {
  return plotMarker ? (
    <div
      className={styles.mapPlotIcon}
      style={{
        transform: isLegend ? "" : "translateY(-50%)",
        backgroundColor: selected ? "#89A053" : "#101110",
        color: selected ? "#FFFFFF" : "#7aca14",
        zIndex: 10000,
        border: selected
          ? "2px rgba(255, 255, 255, 0.20) solid"
          : "2px rgba(137, 160, 83) solid",
      }}
    >
      <div
        onClick={handleClick}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "6px",
        }}
      >
        <div>{plotLength}</div>

        <div
          style={{
            width: "1px",
            height: "20px",
            position: "relative",
            left: "2px",
            background: selected
              ? "rgba(255, 255, 255, 0.20)"
              : "rgba(137, 160, 83, 0.20)",
          }}
        />
        <div style={{ position: "relative", left: "2px" }}>
          <PlotArrow color={selected ? "#FFFFFF" : "#7ACA14"} />
        </div>
      </div>
    </div>
  ) : (
    <Box
      onClick={handleClick}
      className={isLegend ? styles.mapIconLegend : styles.mapIcon}
      sx={{
        transform: isLegend ? "" : "translateY(-50%)",
        backgroundColor: selected
          ? "#89A053"
          : filled
          ? "#101110"
          : "rgba(0,0,0,0)",
        zIndex: index ?? 10000,
        color: selected ? "#FFFFFF" : "#7aca14",
        cursor: "pointer",
        border: selected
          ? "2px rgba(255, 255, 255, 0.20) solid"
          : "2px rgba(137, 160, 83) solid",
      }}
    >
      {number}
      {!!start && <img src={startIcon} />}
      {!!finish && <img src={finishIcon} />}
    </Box>
  );
};
