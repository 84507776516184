import { useQuery } from "@tanstack/react-query";
import { fetchWithAuthorization } from "../fetchWithAutorization";

export const useGetUser = ({
  userId,
}: {
  userId?: number;
}) => {
  const { data, refetch, status, error, isLoading } = useQuery({
    queryKey: ["user_data"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await fetchWithAuthorization(`users/${userId}`, {
        method: "GET",
      });

      if (res.ok) {
        return res.json();
      }

      throw new Error();
    },
    gcTime: 0,
  });
  return {
    data,
    refetch,
    status,
    error,
    isLoading,
  };
};
