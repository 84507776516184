/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Stepper from "@mui/material/Stepper";
import { Box, Stack } from "@mui/material";
import styles from "./CreateTour.module.css";
import { useMutationQuery } from "../../../api/useMutationQuery";
import { CreateEditablePoint } from "./CreatePoint/CreateEditablePoint";
import CreateEditablePlot from "./CreatePlot/CreateEditablePlot";
import { CreateTourMain } from "./CreateTourMain/CreateTourMain";
import RouteParts from "./RouteParts/RouteParts";
import CustomStep from "../../ui-kit/StyledStep/StyledStep";
import { CreateTourControl } from "./CreateTourControl/CreateTourControl";
import { TourPreview } from "./TourPreview/TourPreview";

import CreatePlotMap from "./CreateTourMap/CreatePlotMap/CreatePlotMap";
import { useLocation, useNavigate } from "react-router";
import { useGetRoute } from "../../../api/hooks/useGetRoute";
import { useGetRoutePart } from "../../../api/hooks/useGetRoutePart";
import { CreateCheckpointMap } from "./CreateTourMap/CreateCheckpointMap";

const steps = ["Общие данные", "Маршрут", "Предпросмотр"];

export const CreateTour = ({ isEdit }: any) => {
  const navigate = useNavigate();
  const [step, setStep] = React.useState<number>(0);
  const [openMapPointEditor, setOpenMapPointEditor] =
    React.useState<boolean>(false);
  const [openMapPlotEditor, setOpenMapPlotEditor] =
    React.useState<boolean>(false);
  const [openPointEditor, setOpenPointEditor] = React.useState<boolean>(false);
  const [isOnlyCreatePoint, setOnlyCreatePoint] =
    React.useState<boolean>(false);
  const [openGuideEditor, setOpenGuideEditor] = React.useState<boolean>(false);
  const [isOnlyCreatePlot, setOnlyCreatePlot] = React.useState<boolean>(false);

  const [routePartErrors, setRoutePartErrors] = React.useState<any>();

  const [currentRoutePart, setCurrentRoutePart] = React.useState<any>();
  const [mapCoordinates, setMapCoordinates] = React.useState<any>({
    lat: null,
    lng: null,
  });

  const [routePartKey, setRoutePartKey] = React.useState<number>(0);

  const refetchRoutePart = () => {
    setRoutePartKey((prev) => prev + 1);
  };

  const currentLocation = useLocation();

  const {
    mutate: createTour,
    data: tourData,
    isPending: isTourDataLoading,
  } = useMutationQuery({
    url: "trips/",
    method: "POST",
  });

  const {
    data: routeRetrieveData,
    refetch: getRouteData,
    isLoading: isRoutesLoading,
  } = useGetRoute({
    route: tourData?.data?.route,
    enabled: !!tourData?.data?.route && step === 1,
  });

  const { mutate: createPoint, data: createPointData } = useMutationQuery({
    url: "route_parts/create_point/",
    method: "POST",
  });

  const { mutate: createPlot, data: createPlotData } = useMutationQuery({
    url: "route_parts/create_plot/",
    method: "POST",
  });

  const { mutate: editTitle } = useMutationQuery({
    url: `trips/${tourData?.data?.id}/`,
    method: "PATCH",
  });

  const { mutate: editDescription } = useMutationQuery({
    url: `trips_description/${tourData?.data?.trip_description?.id}/`,
    method: "PATCH",
  });

  const { mutate: editCheckpoint } = useMutationQuery({
    url: `check_points/${currentRoutePart?.check_point?.id}/`,
    method: "PATCH",
  });

  const { mutate: editRoutePart } = useMutationQuery({
    url: `route_parts/${currentRoutePart?.id}/`,
    method: "PATCH",
  });

  const { data: previewMapData } = useGetRoutePart({
    tourId: tourData?.data?.id,
    step: step,
    enabled: !!tourData?.data?.id,
  });

  const { mutate: deleteRoutePart, status: deleteRoutePartStatus } =
    useMutationQuery({
      url: `route_parts/${currentRoutePart?.id}/`,
      method: "DELETE",
    });

  const { mutate: deleteTour } = useMutationQuery({
    url: `trips/${tourData?.data?.id}/`,
    method: "DELETE",
  });

  const { mutate: publishTour } = useMutationQuery({
    url: `trips/${tourData?.data?.id}/publish/`,
    method: "POST",
  });

  React.useEffect(() => {
    if (
      deleteRoutePartStatus === "error" ||
      deleteRoutePartStatus === "success"
    ) {
      setCurrentRoutePart(null);
      if (currentRoutePart?.id) {
        getRouteData();
      }
    }
  }, [deleteRoutePartStatus]);

  React.useLayoutEffect(() => {
    const location = window.location.pathname
      .split("/")
      ?.filter((item) => item !== "");

    if (location[1] === "main") {
      setStep(0);
    }
    if (location[1] === "route-parts") {
      setStep(1);
      const urlParams = new URLSearchParams(window.location.search);
      const routePartType = urlParams.get("type");
      if (!!routePartType) {
        if (routePartType === "check_point") {
          setOnlyCreatePoint(true);
          setOpenPointEditor(true);
        }
        if (routePartType === "plot") {
          setOnlyCreatePlot(true);
          setOpenGuideEditor(true);
        }
      }
    }
    if (location[1] === "preview") {
      setStep(2);
    }
    createTour({});
  }, [step, currentLocation]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        width: "100%",
        backgroundColor: "#282822",
        gap: "32px",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          padding:
            openMapPlotEditor || openMapPointEditor
              ? 0
              : step === 2
              ? "0px 0px 32px 112px"
              : "32px 112px",
        }}
      >
        {step === 2 && previewMapData?.data?.length > 0 && (
          <TourPreview
            routeRetrieveData={routeRetrieveData}
            points={previewMapData.data}
            step={step}
            key={step * new Date().getTime()}
          />
        )}

        {openMapPlotEditor && (
          <CreatePlotMap
            routeRetrieveData={currentRoutePart?.plot?.check_points}
            createPlotData={createPlotData?.data?.plot?.check_points}
            plotId={currentRoutePart?.plot?.id}
            isOnlyCreatePlot={isOnlyCreatePlot}
            handleSave={() => {
              setOpenMapPlotEditor(false);
              setOpenGuideEditor(true);
            }}
            handleClose={() => {
              setOpenMapPlotEditor(false);
              setOpenGuideEditor(true);
              getRouteData();
            }}
          />
        )}
        {openMapPointEditor && (
          <CreateCheckpointMap
            handleClose={() => {
              setOpenMapPointEditor(false);
              setOpenPointEditor(true);
            }}
            mapCoordinates={mapCoordinates}
            defaultCoordinates={
              currentRoutePart?.check_point?.coordinate?.coordinates
            }
            handleMapCoordinates={setMapCoordinates}
            handleSave={() => {
              editCheckpoint(
                {
                  coordinate: {
                    type: "Point",
                    coordinates: [
                      Object.values(mapCoordinates)[1],
                      Object.values(mapCoordinates)[0],
                    ],
                  },
                },
                {
                  onSuccess: () => {
                    refetchRoutePart();
                  },
                }
              );
            }}
          />
        )}
        {openPointEditor && !openMapPlotEditor && (
          <CreateEditablePoint
            routePartKey={routePartKey}
            handleOpenPointEditor={setOpenPointEditor}
            handleOpenMap={setOpenMapPointEditor}
            editRoutePart={editRoutePart}
            editCheckpoint={editCheckpoint}
            handleCurrentRoutePart={setCurrentRoutePart}
            getRoutePartData={refetchRoutePart}
          />
        )}

        {openGuideEditor && !openMapPlotEditor && (
          <CreateEditablePlot
            handleOpenMap={() => {
              setOpenGuideEditor(false);
              setOpenMapPlotEditor(true);
            }}
            isOnlyCreatePlot={isOnlyCreatePlot}
            createPlot={createPlot}
            tourData={tourData}
            editRoutePart={editRoutePart}
            handleCurrentRoutePart={setCurrentRoutePart}
            getRoutePartData={refetchRoutePart}
          />
        )}
        {!openPointEditor &&
          !openGuideEditor &&
          !openMapPlotEditor &&
          !openMapPointEditor && (
            <>
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  zIndex: 1000,
                  marginTop: step === 2 ? "32px" : "0",
                }}
              >
                <h1 className={styles.title}>
                  {isEdit ? "Редактирование тура" : "Создание тура"}
                </h1>
              </Stack>
              <Stepper
                activeStep={step}
                sx={{
                  marginTop: "24px",
                  marginBottom: "24px",
                  zIndex: 1000,
                }}
              >
                {steps.map((label, index) => (
                  <CustomStep key={label} label={label} />
                ))}
              </Stepper>
              <Box
                sx={{
                  height: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                {step === 0 && (
                  <CreateTourMain
                    tourData={tourData}
                    editTitle={editTitle}
                    editDescription={editDescription}
                    createTour={createTour}
                    isTourDataLoading={isTourDataLoading}
                  />
                )}
                {step === 1 && (
                  <RouteParts
                    isRoutesLoading={isRoutesLoading}
                    setRoutePartErrors={setRoutePartErrors}
                    routePartErrors={routePartErrors}
                    routeRetrieveData={routeRetrieveData}
                    tourData={tourData}
                    createPoint={createPoint}
                    createPlot={createPlot}
                    getRoutePartData={getRouteData}
                    handleOpenGuideEditor={setOpenGuideEditor}
                    handleOnlyCreatePlot={setOnlyCreatePlot}
                    handleOnlyCreatePoint={setOnlyCreatePoint}
                    handleOpenPointEditor={setOpenPointEditor}
                    handleCurrentRoutePart={setCurrentRoutePart}
                    deleteRoutePart={deleteRoutePart}
                    getRouteData={getRouteData}
                    currentRoutePart={currentRoutePart}
                    createPointData={createPointData}
                  />
                )}
              </Box>
            </>
          )}

        {!openMapPlotEditor && !openMapPointEditor && (
          <CreateTourControl
            routePartErrors={routePartErrors}
            key={routeRetrieveData?.data.route_parts?.length}
            handleOpenGuideEditor={setOpenGuideEditor}
            openGuideEditor={openGuideEditor}
            openPointEditor={openPointEditor}
            handleOpenPointEditor={setOpenPointEditor}
            handleCurrentRoutePart={setCurrentRoutePart}
            handleStep={setStep}
            getRouteData={getRouteData}
            handleOnlyCreatePoint={setOnlyCreatePoint}
            routeParts={routeRetrieveData?.data.route_parts}
            previewMapData={previewMapData?.data}
            step={step}
            handlePublishTour={() => {
              publishTour(
                {},
                {
                  onSuccess: () => {
                    navigate("/tours/");
                    window.location.reload();
                  },
                }
              );
            }}
            handleDeleteTour={() => {
              deleteTour(
                {},
                {
                  onSuccess: () => {
                    if (isEdit) {
                      const urlParams = new URLSearchParams(
                        window.location.search
                      );
                      const userId = urlParams.get("user");
                      navigate(`/profile/${userId}/`);
                    } else {
                      navigate("/create-tour");
                      window.location.reload();
                    }
                  },
                }
              );
            }}
          />
        )}
      </Stack>
      {!openMapPlotEditor && !openMapPointEditor && (
        <Stack
          sx={{
            zIndex: 999,
            minWidth: step === 2 ? "304px" : "191px",
            marginTop: "32px",
          }}
        >
          {/* {step !== 2 && (
            <>
              <h5 className={styles.hintsTitle}>
                Рекомендации по созданию тура
              </h5>
            </>
          )} */}
        </Stack>
      )}
    </Stack>
  );
};
