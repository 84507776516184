/* eslint-disable array-callback-return */
import React, { useLayoutEffect, useMemo, useState } from "react";
import PointDetailDrawer from "../PointDetailDrawer/PointDetailDrawer";
import { PlotPoint } from "../../CreateTour/CreateTourMap/CreatePlotMap/PlotPoint/PlotPoint";
import { sortByPriority } from "../../../../utils/sortByPriority";
import { Box, Stack } from "@mui/material";
import { ImagePreviewModal } from "../../../Modals/ImagePreviewModal/ImagePreviewModal";
import { MapContainer } from "../../../MapKit/MapContainer/MapContainer";
import { Marker } from "../../../MapKit/Marker/Marker";
import { DefaultMapMarker } from "../../CreateTour/MapMarker/MapMarker";
import { MapRoute } from "../../../MapKit/MapRoute/MapRoute";
import { FitBoundsOnMap } from "../../../MapKit/FitBoundsOnMap/FitBoundsOnMap";
import { Tabber } from "../../../ui-kit/Tabber/Tabber";
import { TabberItem } from "../../../ui-kit/Tabber/TabberItem";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import { TourCardFeedback } from "../../../ui-kit/TourCard/TourCardFeedback";
import { useGetReview } from "../../../../api/hooks/useGetReviews";

const tabs = [
  {
    id: 0,
    title: "Этапы",
    value: "steps",
  },
  {
    id: 2,
    title: "Все отзывы",
    value: "reviews",
  },
];

export const TourDetailMap = ({
  points,
  routeRetrieveData,
  reviewDetailData,
}: any) => {
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [openPointDetail, setOpenPointDetail] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

  const [allPoints, setAllPoints] = useState<any>([]);

  const [currentTab, setCurrentTab] = useState<string>("steps");

  const getCurrentTabIcon = (tab: string) => {
    switch (tab) {
      case "reviews":
        return (
          <MessageOutlinedIcon
            sx={{
              width: "24px",
              height: "24px",
              color: "#a1b670",
            }}
          />
        );
      case "steps":
        return (
          <PinDropOutlinedIcon
            sx={{
              width: "24px",
              height: "24px",
              color: "#a1b670",
            }}
          />
        );
    }
  };

  useLayoutEffect(() => {
    const allPoints = points?.reduce((acc: any, item: any) => {
      return [
        ...acc,
        ...item?.points?.map((point: any) => [
          point?.coordinate?.coordinates[1],
          point?.coordinate?.coordinates[0],
        ]),
      ];
    }, []);

    setAllPoints(allPoints);
  }, [points]);

  const currentAllPointsData = useMemo(() => {
    if (routeRetrieveData?.data?.route_parts?.length > 0) {
      return routeRetrieveData?.data?.route_parts
        .sort(sortByPriority)
        ?.map((item: any, index: any) => ({
          id: item?.id,
          type: item?.type_of_route,
          index: index + 1,
          coord:
            item?.type_of_route === "check_point"
              ? item?.check_point?.coordinate?.coordinates?.reverse()
              : item?.plot?.check_points
                  ?.sort(sortByPriority)
                  ?.map((item: any) => [
                    item?.coordinate?.coordinates?.[1],
                    item?.coordinate?.coordinates?.[0],
                  ]),
        }));
    }
  }, [routeRetrieveData]);

  const currentDataRoutePartsPlots = routeRetrieveData?.data?.route_parts
    ?.sort(sortByPriority)
    ?.map((item: any) => {
      if (item?.type_of_route === "plot") {
        return item?.plot?.check_points?.map((point: any) => [
          point?.coordinate?.coordinates[1],
          point?.coordinate?.coordinates[0],
        ]);
      }
    });

  const { data: reviewsData } = useGetReview({
    tripId: routeRetrieveData?.data?.trip,
    enabled: !!routeRetrieveData?.data?.trip,
  });

  return (
    <>
      {allPoints?.length > 0 && (
        <MapContainer
          center={allPoints[0]}
          style={{
            width: "100vw",
            height: "100vh",
            transform: "translateX(-122px)",
          }}
          zoom={10}
        >
          <FitBoundsOnMap
            points={allPoints}
            params={
              allPoints?.length === 1
                ? {
                    maxZoom: 10,
                  }
                : { padding: { top: 64, bottom: 64, left: 465, right: 368 } }
            }
          />
          {selectedPoint?.id && (
            <FitBoundsOnMap
              points={
                selectedPoint?.type_of_route === "check_point"
                  ? [selectedPoint?.check_point?.coordinate?.coordinates]
                  : selectedPoint?.plot?.check_points?.map(
                      (item: { coordinate: any }) => [
                        item?.coordinate?.coordinates[1],
                        item?.coordinate?.coordinates[0],
                      ]
                    )
              }
              params={
                selectedPoint?.type_of_route === "check_point"
                  ? {
                      maxZoom: 18,
                    }
                  : { padding: { top: 64, bottom: 64, left: 595, right: 598 } }
              }
            />
          )}
          {currentDataRoutePartsPlots
            ?.filter((routePart: any) => !!routePart)
            ?.map((item: any, index: number) => {
              return (
                <MapRoute
                  points={item}
                  isDashed={false}
                  id={index}
                  onRouteClick={() => {
                    setSelectedPoint(
                      routeRetrieveData?.data?.route_parts[index]
                    );
                    setOpenPointDetail(true);
                  }}
                />
              );
            })}
          <MapRoute
            buildAllRoutes={true}
            points={allPoints}
            isDashed={true}
            id={new Date().getTime()}
          />
          {currentAllPointsData?.map((item: any, index: number) => {
            if (item?.type === "check_point") {
              return (
                <Marker
                  key={`check_point-${item?.index}-${selectedPoint?.id}`}
                  coordinates={item?.coord}
                  icon={
                    <DefaultMapMarker
                      number={item?.index}
                      filled
                      selected={item?.id === selectedPoint?.id}
                    />
                  }
                  onMarkerClick={() => {
                    setSelectedPoint(
                      routeRetrieveData?.data?.route_parts[index]
                    );
                    setOpenPointDetail(true);
                  }}
                />
              );
            }

            if (item?.type === "plot") {
              return item?.coord?.map((subitem: any, subIndex: any) => (
                <Marker
                  onMarkerClick={() => {
                    setSelectedPoint(
                      routeRetrieveData?.data?.route_parts[index]
                    );
                    setOpenPointDetail(true);
                  }}
                  key={`plot-${item?.index}-${subIndex}-${selectedPoint?.id}`}
                  coordinates={subitem}
                  icon={
                    subIndex === 0 ? (
                      <DefaultMapMarker
                        plotMarker={true}
                        plotLength={subIndex === 0 ? item?.index : null}
                        selected={item?.id === selectedPoint?.id}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "12px",
                          height: "12px",
                          background: "#101110",
                          border: "2px #81C728 solid",
                          borderRadius: "1000px",
                          zIndex: "-1000",
                        }}
                      />
                    )
                  }
                />
              ));
            }

            return null;
          })}
        </MapContainer>
      )}

      <Stack
        sx={{
          position: "absolute",
          paddingTop: "32px",
          right: "16px",
          zIndex: "1000",
          gap: "16px",
          overflowY: "auto",
          maxHeight: "100svh",
        }}
      >
        <Stack direction="column" gap="4px">
          <Tabber>
            {tabs?.map((item) => {
              return (
                <TabberItem
                  selected={item?.value === currentTab}
                  handleClick={() => {
                    setCurrentTab(item?.value);
                  }}
                  title={
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      gap="4px"
                    >
                      {getCurrentTabIcon(item.value)}
                      {item?.title}{" "}
                      <span
                        style={{
                          opacity: "0.6",
                        }}
                      >
                        {" "}
                        ({" "}
                        {item?.value === "steps"
                          ? routeRetrieveData?.data.route_parts?.length
                          : reviewsData?.data?.length}{" "}
                        )
                      </span>
                    </Stack>
                  }
                />
              );
            })}
          </Tabber>
        </Stack>
        {currentTab === "steps" ? (
          <>
            {reviewDetailData && (
              <Box
                sx={{
                  maxWidth: "304px",
                }}
              >
                <TourCardFeedback
                  darkDisplay={true}
                  user={reviewDetailData?.author}
                  createdAt={reviewDetailData?.created_at}
                  review={reviewDetailData}
                />
              </Box>
            )}
            {routeRetrieveData?.data.route_parts
              ?.sort(sortByPriority)
              ?.map((routePart: any, index: number) => (
                <PlotPoint
                  previewImage={routePart?.check_point?.media?.[0]?.media}
                  title={routePart?.title}
                  role="preview"
                  index={index}
                  points={routeRetrieveData?.data.route_parts?.sort(
                    sortByPriority
                  )}
                  audio={
                    routePart?.check_point?.audio_guide ??
                    routePart?.plot?.audio_guide
                  }
                  handleClick={() => {
                    setSelectedPoint(routePart);
                    setOpenPointDetail(true);
                  }}
                />
              ))}
          </>
        ) : (
          <>
            {" "}
            <Stack
              gap="16px"
              sx={{
                width: "304px",
              }}
            >
              {reviewsData?.data?.map((item: any) => (
                <Box
                  sx={{
                    width: "286px",
                  }}
                >
                  <TourCardFeedback
                    darkDisplay
                    user={item?.author}
                    postedAt={item?.posted_at}
                    createdAt={item?.created_at}
                    review={item}
                  />
                </Box>
              ))}
            </Stack>
          </>
        )}
      </Stack>
      <PointDetailDrawer
        openPreviewModal={openPreviewModal}
        activeIndex={activeIndex}
        handleActiveIndex={setActiveIndex}
        handleImagePreviewModal={setOpenPreviewModal}
        open={openPointDetail}
        handleClose={() => {
          setOpenPointDetail(false);
        }}
        selectedPoint={selectedPoint}
        data={routeRetrieveData?.data.route_parts}
      />
      <ImagePreviewModal
        open={openPreviewModal}
        imagesArr={
          selectedPoint?.type_of_route === "plot"
            ? selectedPoint?.plot?.media
            : selectedPoint?.check_point?.media
        }
        activeIndex={activeIndex}
        handleActiveIndex={setActiveIndex}
        handleClose={() => setOpenPreviewModal(false)}
      />
    </>
  );
};
