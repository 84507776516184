import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Stack } from "@mui/material";
import {
  DarkBorderButton,
  DefaultButton,
} from "../../../components/ui-kit/Button/Button";
import styles from "../EditEmailModal/EditEmailModal.module.css";
import { useMutationQuery } from "../../../api/useMutationQuery";

import { useEffect } from "react";
import { useGetQuery } from "../../../api/useGetQuery";
import Cookies from "js-cookie";

export const EditTelegramModal = ({
  open,
  handleClose,
  isTelegramExists,
  meData,
  refetch,
}: any) => {
  useEffect(() => {
    if (isTelegramExists) {
      Cookies.remove("telegram_exists");
    }
  }, [isTelegramExists]);
  const { data: authenficateTelegramData, refetch: authenficateTelegram } =
    useGetQuery({
      url: `users/authenticate_telegram?process=connect&next_url=${window.location.href}`,
      queryKey: ["authenticate_telegram"],
      enabled: false,
    });

  const { mutate: mergeRequest } = useMutationQuery({
    url: `users/merge_request/`,
    method: "POST",
  });
  const { mutate: disconnectTelegram } = useMutationQuery({
    url: `users/disconnect_telegram/`,
    method: "POST",
  });

  useEffect(() => {
    if (authenficateTelegramData?.data?.url) {
      window.location.replace(authenficateTelegramData?.data?.url);
    }
  }, [authenficateTelegramData?.data?.url]);

  return (
    <Dialog
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "366px",
          borderRadius: "20px",
          border: "1px solid rgba(255, 255, 255, 0.00)",

          background: "var(--Surface-Card-2, rgba(255, 255, 255, 0.10))",

          boxShadow: " 0px 8px 12px 0px rgba(0, 0, 0, 0.40)",
          backdropFilter: "blur(8px)",
          padding: "32px",
        },
      }}
      open={open}
    >
      <h2 className={styles.title}>Изменение telegram</h2>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 18,
          top: 20,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <p
        className={styles.content}
        style={{
          paddingTop: "36px",
          paddingBottom: "32px",
        }}
      >
        {!!isTelegramExists
          ? "Аккаунт с таким telegram уже существует. Вы можете объединить аккаунты (информация сохранится из обоих), или использовать другой telegram аккаунт."
          : "Для добавления профиля telegram нажмите прикрепить telegram аккаунт и выберите нужный. "}
      </p>
      <Box>
        <Stack gap="12px">
          {isTelegramExists ? (
            <DefaultButton
              onClick={() => {
                mergeRequest(
                  {
                    source: "social",
                    control: isTelegramExists,
                    next_url: "https://tripper.bulltech.ru/edit-profile/",
                  },
                  {
                    onSuccess: (data) => {
                      window.location.replace(data?.data?.url);
                    },
                  }
                );
              }}
            >
              Объединить аккаунты
              <CheckIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "8px",
                }}
              />{" "}
            </DefaultButton>
          ) : (
            <>
              <DefaultButton
                onClick={() => {
                  authenficateTelegram();
                }}
              >
                Прикрепить telegram аккаунт
                <CheckIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "8px",
                  }}
                />{" "}
              </DefaultButton>

              {meData?.data?.telegram_uid && (
                <DarkBorderButton
                  className={styles.buttonWrapper}
                  onClick={() => {
                    disconnectTelegram(
                      {
                        uid: meData?.data?.telegram_uid,
                      },
                      {
                        onSuccess: () => {
                          handleClose();
                          refetch();
                        },
                      }
                    );
                  }}
                  sx={{
                    width: "100%",
                    color: "white",
                    padding: "10px 16px",
                    border: "1px solid var(--Outline-Button, #89A053)",
                  }}
                >
                  Открепить telegram аккаунт
                  <CloseIcon
                    sx={{
                      width: "16px",
                      height: "16px",
                      marginLeft: "8px",
                    }}
                  />
                </DarkBorderButton>
              )}
            </>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};
