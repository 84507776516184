import { useMutation } from "@tanstack/react-query";
import { fetchWithAuthorization } from "../fetchWithAutorization";
import { FetchError } from "../FetchError";

export const useAddToFavorites = ({ tripId, onSuccess, onError }: any) => {
  const { data, mutate, status, error, isSuccess, isPending } = useMutation({
    mutationFn: async (data: any) => {
      const res = await fetchWithAuthorization(`trips/${tripId}/favorites/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: null,
      });

      if (res.ok) {
        return res.json();
      }

      throw new FetchError(res);
    },
    onSuccess,
    onError,
  });
  return {
    data,
    addToFav: mutate,
    status,
    error,
    isSuccess,
    isPending,
  };
};
