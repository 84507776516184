/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import TourCard from "../../components/ui-kit/TourCard/TourCard";
import ToursMap from "../../components/blocks/Tours/ToursMap/ToursMap";
import styles from "./TourList.module.css";
import { ToursListReview } from "./ToursListReview";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchWithAuthorization } from "../../api/fetchWithAutorization";

export const ToursList = () => {
  const [openReview, setOpenReview] = useState<boolean>(false);

  const { data: toursData, fetchNextPage } = useInfiniteQuery({
    queryKey: ["tours-list"],
    queryFn: async ({ pageParam }) => {
      const res = await fetchWithAuthorization(`feed/?page=${pageParam}`, {
        method: "GET",
      });

      if (res.ok) {
        return res.json();
      }

      throw new Error();
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage.meta.next_url) return undefined;
      return lastPage.meta.page + 1;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const [selectedCard, setSelectedCard] = useState<any>(null);

  useEffect(() => {
    const stack = document.querySelector("#tours");

    const handleScroll = (e: { target: any }) => {
      const target = e.target;

      if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
        fetchNextPage();
      }
    };

    stack?.addEventListener("scroll", handleScroll);

    return () => {
      stack?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!selectedCard?.id) {
      setSelectedCard(
        toursData?.pages.map((page: any) => page?.data)?.flat()[0]
      );
    }
  }, [toursData]);

  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
      }}
    >
      <Stack>
        <Stack id={"tours"} className={styles.tourListWrapper}>
          {toursData?.pages
            .map((page: any) => page?.data)
            ?.flat()
            ?.map((item: any) => {
              return (
                <TourCard
                  displayReviews={true}
                  handleOpenReview={setOpenReview}
                  key={item.id}
                  selected={selectedCard?.id === item?.id}
                  handleClick={() => setSelectedCard(item)}
                  user={item?.user}
                  title={item?.title}
                  posted={item?.posted}
                  postedAt={item?.posted_at}
                  createdAt={item?.created_at}
                  description={item?.trip_description.short_description}
                  altDescription={item?.trip_description.description}
                  expenses={item?.trip_description.expenses}
                  distance={item?.trip_description.distance}
                  time={item?.trip_description.time_costs}
                  points={item?.trip_description.points?.length}
                  picture={item?.trip_description?.media}
                  routePartsCount={item?.route_parts_count}
                  id={item?.trip_description?.id}
                  evaluationCount={item?.evaluation_count}
                  avgRating={item?.avg_rating}
                  review={toursData?.pages
                    .map((page: any) => page?.data)
                    ?.flat()
                    ?.filter((item) => item?.object_type === "review")
                    ?.filter((trip) => trip?.trip_description?.id === item?.id)}
                  inFavorite={item?.in_favorite}
                />
              );
            })}
        </Stack>
        <ToursListReview
          selectedCard={selectedCard}
          open={openReview}
          handleOpenReview={setOpenReview}
        />
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          width: "100vw",
          transform: "translateX(-122px)",
        }}
      >
        <ToursMap
          data={toursData?.pages.map((page: any) => page?.data)?.flat()}
          selectedCard={selectedCard}
        />
      </Stack>
    </Stack>
  );
};
