import { Button, IconButton, Stack } from "@mui/material";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import styles from "./Audio.module.css";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { formatTime } from "../../../utils/timeTrackDisplay";
import DeleteOutline from "@mui/icons-material/DeleteOutline";

const AudioPlayer = ({
  handleUploadPointAudioGuide,
  audioGuide,
  handleDeletePointAudioGuide,
  type,
  isPreview,
  getRoutePartData,
}: any) => {
  const [play, setPlay] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const AudioBlock = useRef<HTMLInputElement | null>(null);
  const [progress, setProgress] = useState<any>();
  const [key, setKey] = useState<number>(0);
  const [name, setName] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      if (file.size / 1024 / 1024 <= 40) {
        setName(file.name);

        // Проверка типа файла
        if (file.type === "video/mp4") {
          // Создаем новый Blob с типом 'audio/mp4'
          const audioBlob = new Blob([file], { type: "audio/mp4" });

          // Создаем новый FormData с аудио файлом
          const formData = new FormData();
          formData.append("audio_guide", audioBlob);

          handleUploadPointAudioGuide(formData, {
            onSuccess: () => {
              getRoutePartData();
            },
            onError: () => {
              getRoutePartData();
            },
          });
        } else {
          // Для остальных типов файлов оставляем исходный файл
          const formData = new FormData();
          formData.append("audio_guide", file);
          handleUploadPointAudioGuide(formData, {
            onSuccess: () => {
              getRoutePartData();
            },
            onError: () => {
              getRoutePartData();
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    audio.src = audioGuide;
  }, [audioGuide]);

  useLayoutEffect(() => {
    setProgress(0);
  }, []);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (!audio) return;

    setKey((prev) => prev + 1);

    setPlay(audio.paused);

    audio.paused ? audio.play() : audio.pause();
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    if (!audio) return;

    const currentTime = audio.currentTime;
    const duration = audio.duration;

    // Update the progress bar
    if (currentTime && duration) {
      const progress = (currentTime / duration) * 100;
      if (AudioBlock.current) AudioBlock.current.value = progress.toString();
      setProgress((currentTime / duration) * 100);
    }
  };

  const handleProgressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const audio = audioRef.current;
    if (!audio) return;

    const progress = parseFloat(event.target.value);
    audio.currentTime = (progress / 100) * audio.duration;
  };

  return (
    <>
      {!audioGuide && (
        <Button
          component="label"
          sx={{
            border: "1px #a1b670 dotted",
            width: "100%",

            height: "55px",
            borderRadius: "20px",
          }}
        >
          <AddIcon
            sx={{
              color: "#a1b670",
            }}
          />
          <input
            type="file"
            value={""}
            hidden
            onChange={handleChange}
            accept=".mp3, .mp4, .ogg, .wav"
          />
        </Button>
      )}
      {audioGuide && (
        <>
          {" "}
          <audio ref={audioRef} onTimeUpdate={handleTimeUpdate}>
            <source src="" type="audio/mpeg" />
          </audio>
          <p className={styles.audioPlayerName}> {name}</p>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
              width: "100%",
              marginTop: "16px",
            }}
          >
            <Stack direction="row" gap="16px">
              {play ? (
                <PauseCircleIcon
                  sx={{
                    color: "#89A053",
                  }}
                  onClick={handlePlayPause}
                />
              ) : (
                <PlayCircleIcon
                  onClick={handlePlayPause}
                  sx={{
                    color: "#89A053",
                  }}
                />
              )}
              <Stack>
                <input
                  ref={AudioBlock}
                  type="range"
                  defaultValue="0"
                  min="0"
                  max="100"
                  onChange={handleProgressChange}
                  className={styles.slider}
                  style={{
                    background:
                      audioRef?.current?.currentTime &&
                      audioRef.current?.duration
                        ? `linear-gradient(to right, #89A053 0%, #89A053 ${Math.ceil(
                            progress
                          )}%, #fff ${progress}%, #fff 100%)`
                        : "#fff",
                    width: isPreview ? "190px" : "246px",
                  }}
                />
                <Stack direction="row" justifyContent="space-between" key={key}>
                  <Stack>
                    <p className={styles.audioPlayerTime}>
                      {audioRef.current?.currentTime
                        ? formatTime(audioRef.current?.currentTime)
                        : "00:00"}
                    </p>
                  </Stack>
                  <Stack>
                    <p className={styles.audioPlayerTime}>
                      {audioRef.current?.currentTime
                        ? formatTime(audioRef.current?.duration)
                        : "00:00"}
                    </p>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {!isPreview && (
              <IconButton
                onClick={handleDeletePointAudioGuide}
                sx={{
                  marginTop: "-8px",
                }}
              >
                <DeleteOutline
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "#89A053",
                  }}
                />
              </IconButton>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default AudioPlayer;
