import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DarkButton, DefaultButton } from "../Button/Button";
import styles from "./DefaultModal.module.css";
import { Button, Stack } from "@mui/material";

export const DefaultModal = ({
  open,
  handleClose,
  title,
  content,
  handleAction,
  actionText,
  altText,
  altAction,
}: any) => {
  return (
    <Dialog
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "366px",
          borderRadius: "20px",
          border: "1px solid rgba(255, 255, 255, 0.00)",

          background: "var(--Surface-Card-2, rgba(255, 255, 255, 0.10))",

          boxShadow: " 0px 8px 12px 0px rgba(0, 0, 0, 0.40)",
          backdropFilter: "blur(8px)",
          padding: "32px",
        },
      }}
      open={open}
    >
      <h2 className={styles.title}>{title}</h2>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 18,
          top: 20,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <p
        className={styles.content}
        style={{
          paddingTop: "36px",
          paddingBottom: "32px",
        }}
      >
        {content}
      </p>

      <Stack gap="12px">
        <DefaultButton onClick={handleAction}>{actionText}</DefaultButton>
        {altAction && altText && (
          <DarkButton
            onClick={() => {
              handleClose();
              if (altAction()) {
                altAction();
              }
            }}
            sx={{
              width: "100%",
            }}
          >
            {altText}
          </DarkButton>
        )}
      </Stack>
    </Dialog>
  );
};
