import { Box } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const TourCardSlider = ({ picture, column }: any) => {
  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slideNext();
  }, []);
  return (
    <Box
      sx={{
        width: "304px",
      }}
    >
      <Box>
        {picture?.length > 1 && (
          <ChevronLeftIcon
            className="prev-arrow"
            onClick={handlePrev}
            sx={{
              position: "fixed",
              zIndex: 10000,
              top: column ? "200px" : "152px",
              left: "36px",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.30)",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
        )}
      </Box>
      <Swiper
        slidesPerView={1}
        modules={[Pagination]}
        pagination={true}
        ref={sliderRef}
        loop={true}
      >
        {picture?.map((item: any) => (
          <SwiperSlide>
            <Box
              sx={{
                width: column ? "288px" : "304px",
                height: "304px",
                objectFit: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                background: `url(${item?.media?.media?.original}) lightgray 50% / cover no-repeat`,
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {picture?.length > 1 && (
        <ChevronRightIcon
          className="next-arrow"
          onClick={handleNext}
          sx={{
            position: "fixed",
            zIndex: 10000,
            color: "white",
            left: column ? "258px" : "276px",
            bottom: column ? "395px" : "160px",
            backgroundColor: "rgba(0, 0, 0, 0.30)",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        />
      )}
    </Box>
  );
};
