import { useEffect } from "react";

export const CreatePlotZoomControl = ({ map, zoom }: any) => {
  useEffect(() => {
    if (map && zoom) {
      map.easeTo({
        center: map.getCenter(),
        zoom: zoom,
        speed: 2,
        curve: 1,
      });
    }
  }, [map, zoom]);

  return null;
};
