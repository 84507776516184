/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import maplibregl, { LngLatLike, Map } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

interface MapContainerProps {
  style: React.CSSProperties;
  children: ReactNode;
  center: LngLatLike | undefined;
  zoom: number;
  onMouseMove?: (event: any) => void;
  onMapClick?: (event: any) => void;
  mapRefSetter?: (map: any) => void;
}

export const MapContainer = ({
  style,
  children,
  center,
  zoom,
  onMapClick,
  onMouseMove,
  mapRefSetter,
}: MapContainerProps) => {
  const [map, setMap] = useState<Map | null>(null);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const map = new maplibregl.Map({
      container: "map",
      style: {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: ["https://tile.openstreetmap.de/{z}/{x}/{y}.png"],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "simple-tiles",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      center: center,
      zoom: zoom,
    });

    if (onMapClick) {
      map.on("click", onMapClick);
    }
    if (onMouseMove) {
      map.on("mousemove", onMouseMove);
    }
    if (mapRefSetter) {
      mapRefSetter(map);
    }
    setMap(map);
  }, []);
  return (
    <div ref={mapContainer} id="map" style={style}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return (React.cloneElement as any)(child, { map });
        }
        return child;
      })}
    </div>
  );
};
