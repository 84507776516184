/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./EditProfile.module.css";
import { Input } from "../../components/ui-kit/TextField/TextField";
import { CustomCheckbox } from "../../components/ui-kit/CustomCheckbox/CustomCheckbox";
import {
  DarkButton,
  DefaultButton,
} from "../../components/ui-kit/Button/Button";
import CheckIcon from "@mui/icons-material/Check";
import { SubmitHandler, useForm } from "react-hook-form";
import { CustomRadio } from "../../components/ui-kit/CustomRadio/CustomRadio";
import { Divider } from "../../components/ui-kit/Divider/Divider";
import { useMutationQuery } from "../../api/useMutationQuery";
import { editProfileValidation } from "./EditProfileValidation";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-crop/src/ReactCrop.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers";
import CustomDatePicker from "../../components/ui-kit/CustomDatePicker/CustomDatePicker";
import { EditEmailModal } from "./EditEmailModal/EditEmailModal";
import UploadIcon from "@mui/icons-material/Upload";
import Avatar from "../../assets/img/Avatar.svg";
import { enqueueSnackbar } from "notistack";
import { AvatarModal } from "./AvatarModal/AvatarModal";
import "react-advanced-cropper/dist/style.css";
import { format, isValid } from "date-fns";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import AvatarPreview from "../../components/ui-kit/AvatarPreview/AvatarPreview";
import DeleteIcon from "@mui/icons-material/Delete";
import { format as formatMask } from "@react-input/mask";
import { imagesFormats } from "../../utils/imgFormats";
import { EditTelegramModal } from "./EditTelegramModal/EditTelegramModal";
import Cookies from "js-cookie";
import { DefaultModal } from "../../components/ui-kit/DefaultModal/DefaultModal";
import { useMe } from "../../api/hooks/useMe";

export const EditProfile = () => {
  const [birthdate, setBirthday] = useState<any>(null);
  const [isEditEmailModal, setIsEditEmailModal] = useState<boolean>(false);
  const [isEditTelegramModal, setIsEditTelegramModal] =
    useState<boolean>(false);
  const [isAvatarModal, setIsAvatarModal] = useState<boolean>(false);
  const [currentAvatarImg, setCurrentAvatarImg] = useState<any>(null);
  const [isDisplayPrefix, setDisplayPrefix] = useState<boolean>(false);
  const isTelegramExists = Cookies.get("telegram_exists");

  const [isEmailChangedModal, setIsEmailChangedModal] =
    useState<boolean>(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isEmailChanged = urlParams.get("email_changed");
    if (isEmailChanged) {
      setIsEmailChangedModal(true);
    }
  }, []);

  const navigate = useNavigate();

  type UserParams = z.infer<typeof editProfileValidation>;
  const { mutate: updateMe } = useMutationQuery({
    url: `users/me/`,
    method: "PATCH",
  });

  const { data: meData, refetch: getMe } = useMe({
    enabled: true,
  });

  useEffect(() => {
    if (!!isTelegramExists && isTelegramExists !== meData?.data?.telegram_uid) {
      setIsEditTelegramModal(true);
    }
  }, [isTelegramExists, meData?.data?.telegram_uid]);

  const { mutate: deleteAvatar } = useMutationQuery({
    url: `users/${meData?.data?.id}/avatar_delete/`,
    method: "DELETE",
  });

  const handleImageUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && evt.target.files.length > 0) {
      if (imagesFormats?.includes(evt.target.files[0].type?.split("/")[1])) {
        if (evt.target.files[0]?.size / 1024 / 1024 <= 40) {
          setCurrentAvatarImg(evt.target.files[0]);
          setIsAvatarModal(true);
        } else {
          enqueueSnackbar("Размер файла не может превышать 40МБ", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Неподдерживаемый формат изображения", {
          variant: "error",
        });
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },

    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<UserParams>({
    resolver: zodResolver(editProfileValidation),
    mode: "all",
  });

  const regexRus = /^7/;

  useEffect(() => {
    setValue("username", meData?.data?.username);
    setValue("email", meData?.data?.email ?? "");
    setValue("first_name", meData?.data?.first_name);
    setValue("last_name", meData?.data?.last_name);
    setValue("middle_name", meData?.data?.middle_name);
    setValue("gender", meData?.data?.gender);
    setValue("description", meData?.data?.description ?? "");
    setValue("show_email", meData?.data?.show_email);
    setValue("show_telegram", meData?.data?.show_telegram);
    setValue("telegram_username", meData?.data?.telegram_username ?? "");
    register("birthdate", {
      value: meData?.data?.birthdate
        ? format(meData?.data?.birthdate, "yyyy-MM-dd")
        : "",
    });
    setBirthday(meData?.data?.birthdate);
    if (meData?.data?.phone_number) {
      setDisplayPrefix(true);
      if (regexRus.test(meData?.data?.phone_number)) {
        setValue(
          "phone_number",
          formatMask(meData?.data?.phone_number, {
            mask: "_ (___) ___-__-__",
            replacement: { _: /\d/ },
          })
        );
      } else {
        setValue("phone_number", meData?.data?.phone_number);
      }
    }
    if (meData?.data?.gender !== "male" && meData?.data?.gender !== "female") {
      setValue("gender", "not_specified");
    }
  }, [meData?.data]);

  const dirtyValues = (dirtyFields: any, allValues: any): object => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => {
        // Получаем текущее значение
        let currentValue =
          key === "phone_number"
            ? allValues[key].replace(/[^+\d]/g, "")
            : allValues[key];

        // Если значение является пустой строкой, заменяем на null
        if (currentValue === "") {
          currentValue = null;
        }

        // Возвращаем ключ и измененное значение
        return [key, dirtyValues(dirtyFields[key], currentValue)];
      })
    );
  };

  const filedsToSend: any = useMemo(() => {
    return dirtyValues(dirtyFields, watch());
  }, [dirtyFields, watch()]);

  useEffect(() => {
    if (watch("phone_number") && meData?.data?.phone_number) {
      if (
        formatMask(watch("phone_number"), {
          mask: "_ (___) ___-__-__",
          replacement: { _: /\d/ },
        }) ===
        formatMask(meData?.data?.phone_number, {
          mask: "_ (___) ___-__-__",
          replacement: { _: /\d/ },
        })
      ) {
        delete filedsToSend["phone_number"];
      }
    }
    if (watch("username") && meData?.data?.username) {
      if (watch("username") === meData?.data?.username) {
        delete filedsToSend["username"];
      }
    }
  }, [dirtyFields, watch()]);

  const onSubmit: SubmitHandler<UserParams> = (data) => {
    updateMe(filedsToSend, {
      onSuccess: () => {
        if (Object.keys(filedsToSend)?.length > 0) {
          enqueueSnackbar("Изменения сохранены", {
            variant: "success",
          });
        }
        navigate(`/profile/${meData?.data?.id}`);
      },
    });
  };

  const imgSrc = meData?.data?.avatar_data?.media?.original;

  return (
    <>
      <Box className={styles.editProfileWrapper}>
        <Stack direction="column">
          <h2 className={styles.editProfileTitle}>Редактирование профиля</h2>
          <Box
            sx={{
              height: "calc(100vh - 138px)",
              overflowY: "auto",
              width: "640px",
            }}
          >
            <Box component="form" onSubmit={handleSubmit(onSubmit)} id="edit">
              <Stack
                sx={{
                  gap: "16px",
                  marginTop: "16px",
                }}
              >
                <Stack direction="row" gap="16px">
                  {imgSrc && (
                    <Box>
                      <AvatarPreview
                        imgSrc={imgSrc}
                        options={meData?.data?.avatar_data?.options}
                      />
                      <Button component="label" className={styles.avatarButton}>
                        <UploadIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <input
                          type="file"
                          value={""}
                          hidden
                          onChange={(evt) => {
                            handleImageUpload(evt);
                          }}
                        />
                      </Button>
                      <Button
                        onClick={() => {
                          deleteAvatar(
                            {},
                            {
                              onSuccess: () => {
                                getMe();
                              },
                            }
                          );
                        }}
                        component="label"
                        sx={{
                          position: "relative",
                          transform: "translateY(-35px)",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                          maxWidth: "28px",
                          maxHeight: "28px",
                          minWidth: "28px",
                          minHeight: "28px",
                          left: "37px",
                          padding: "4px",
                          borderBottomRightRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomLeftRadius: "0%",
                          borderTopLeftRadius: "0%",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0,0,0,0.5)",
                          },
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </Button>
                    </Box>
                  )}

                  {!imgSrc && (
                    <Box
                      sx={{
                        minWidth: "128px",
                        width: "128px",
                        height: "128px",
                        background: `url(${Avatar}) lightgray 50% / cover no-repeat`,
                        backgroundSize: "70%",
                        backgroundColor: "rgba(0,0,0,0)",
                        borderRadius: "128px",
                        border: "2px solid rgba(255, 255, 255, 0.10)",
                        boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.40)",
                      }}
                    >
                      <Button
                        component="label"
                        sx={{
                          position: "relative",
                          transform: "translateY(88px)",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                          maxWidth: "28px",
                          maxHeight: "28px",
                          minWidth: "28px",
                          minHeight: "28px",
                          left: "50px",
                          padding: "4px",
                          borderRadius: "1000px",
                          cursor: "pointer",
                        }}
                      >
                        <UploadIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <input
                          type="file"
                          value={""}
                          hidden
                          onChange={(evt) => {
                            handleImageUpload(evt);
                          }}
                        />
                      </Button>
                    </Box>
                  )}
                  <Stack
                    direction="column"
                    gap="16px"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Input
                      label="Ваш ник"
                      onKeyPress={(event) => {
                        if (!/^[a-zA-Z0-9@._-]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("username")}
                      InputLabelProps={{ shrink: !!watch("username") }}
                      inputProps={{ style: { color: "white" }, maxLength: 150 }}
                      helperText={
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            className={styles.formDescription}
                            style={{
                              opacity: "0.5",
                            }}
                          >
                            {watch("username")?.length ?? 0} / 150
                          </p>
                        </Stack>
                      }
                    />
                    <Input
                      inputProps={{ maxLength: 21 }}
                      type="phone"
                      error={!!errors["phone_number"]}
                      helperText={errors["phone_number"]?.message}
                      autoComplete="off"
                      label="Ваш телефон"
                      {...register("phone_number")}
                      onFocus={() => {
                        setDisplayPrefix(true);
                      }}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setDisplayPrefix(false);
                        }
                      }}
                      onChange={(e) => {
                        if (regexRus.test(e.target.value)) {
                          setValue(
                            "phone_number",
                            formatMask(e.target?.value, {
                              mask: "_ (___) ___-__-__",
                              replacement: { _: /\d/ },
                            }),
                            {
                              shouldDirty: true,
                            }
                          );
                          if (e.target.value.length < 17) {
                            setError("phone_number", {
                              type: "custom",
                              message: "Введен неверный номер телефона",
                            });
                          }
                          if (
                            e.target.value?.length === 17 ||
                            e.target.value?.length === 0
                          ) {
                            clearErrors("phone_number");
                          }
                        } else {
                          setValue(
                            "phone_number",
                            e.target.value.replace(/[^0-9\s]+/, ""),
                            {
                              shouldDirty: true,
                            }
                          );
                          if (
                            e.target.value?.length > 15 ||
                            e.target.value.length < 11
                          ) {
                            setError("phone_number", {
                              type: "custom",
                              message: "Введен неверный номер телефона",
                            });
                          }
                          if (
                            (e.target.value?.length >= 11 &&
                              e.target.value?.length <= 15) ||
                            e.target.value?.length === 0
                          ) {
                            clearErrors("phone_number");
                          }
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              sx={{
                                color: "white",
                                opacity: isDisplayPrefix ? 1 : 0,
                              }}
                            >
                              +
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: !!watch("phone_number") || isDisplayPrefix,
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack direction="column" gap="16px">
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      color: "white",
                      gap: "16px",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Input
                      sx={{
                        opacity: "0.5",
                        width: "fit-content",
                        pointerEvents: "none",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      label="Ваш e-mail"
                      {...register("email")}
                      InputLabelProps={{ shrink: !!watch("email") }}
                    />
                    <FormControlLabel
                      {...register("show_email")}
                      control={
                        <CustomCheckbox checked={!!watch("show_email")} />
                      }
                      label={
                        <Box
                          maxWidth="156px"
                          className={styles.editProfileLabel}
                        >
                          <span>Показывать e-mail в профиле</span>
                        </Box>
                      }
                    />
                    <DefaultButton
                      onClick={() => {
                        setIsEditEmailModal(true);
                      }}
                      sx={{
                        height: "fit-content",
                        flexGrow: "1",
                        whiteSpace: "nowrap",
                        minWidth: "195px",
                        maxWidth: "195px",
                      }}
                    >
                      <CheckIcon
                        sx={{
                          width: "16px",
                          height: "16px",
                          color: "white !important",
                          marginRight: "8px !important",
                        }}
                      />
                      {watch("email")?.length > 0
                        ? " Изменить e-mail"
                        : "Добавить e-mail"}
                    </DefaultButton>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      color: "white",
                      gap: "16px",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Input
                      sx={{
                        opacity: "0.5",
                        width: "fit-content",
                        pointerEvents: "none",
                      }}
                      label="Telegram аккаунт"
                      inputProps={{
                        readOnly: true,
                      }}
                      {...register("telegram_username")}
                      InputLabelProps={{ shrink: !!watch("telegram_username") }}
                    />
                    <FormControlLabel
                      {...register("show_telegram")}
                      control={
                        <CustomCheckbox checked={!!watch("show_telegram")} />
                      }
                      label={
                        <Box
                          maxWidth="156px"
                          className={styles.editProfileLabel}
                        >
                          <span>Показывать telegram в профиле</span>
                        </Box>
                      }
                    />
                    <DefaultButton
                      onClick={() => {
                        setIsEditTelegramModal(true);
                      }}
                      sx={{
                        height: "fit-content",
                        flexGrow: "1",
                        whiteSpace: "nowrap",
                        minWidth: "195px",
                        maxWidth: "195px",
                      }}
                    >
                      <CheckIcon
                        sx={{
                          width: "16px",
                          height: "16px",
                          color: "white !important",
                          marginRight: "8px !important",
                        }}
                      />
                      {watch("telegram_username")?.length > 0
                        ? " Изменить telegram"
                        : "Добавить telegram"}
                    </DefaultButton>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    rowGap: "16px",
                    columnGap: "16px",
                    gridTemplateRows: "1fr 1fr",
                  }}
                >
                  <Input
                    label="Ваше имя"
                    {...register("first_name")}
                    InputLabelProps={{ shrink: !!watch("first_name") }}
                    onKeyPress={(event) => {
                      if (!/^[a-zA-Zа-яА-Я\s\-]$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <Input
                    label="Ваша фамилия"
                    onKeyPress={(event) => {
                      if (!/^[a-zA-Zа-яА-Я\s\-]$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register("last_name")}
                    InputLabelProps={{ shrink: !!watch("last_name") }}
                  />
                  <Input
                    label="Ваше отчество"
                    onKeyPress={(event) => {
                      if (!/^[a-zA-Zа-яА-Я\s\-]$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register("middle_name")}
                    InputLabelProps={{ shrink: !!watch("middle_name") }}
                  />
                  {!!birthdate && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        label="Дата рождения"
                        format="DD.MM.YYYY"
                        defaultValue={dayjs(birthdate)}
                        onChange={(newValue: any) => {
                          if (newValue?.$d && isValid(newValue?.$d)) {
                            setValue(
                              "birthdate",
                              format(newValue?.$d, "yyyy-MM-dd"),
                              { shouldDirty: true }
                            );
                          }
                        }}
                      />
                    </LocalizationProvider>
                  )}
                  {!birthdate && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        label="Дата рождения"
                        format="DD.MM.YYYY"
                        onChange={(newValue: any) => {
                          if (newValue?.$d && isValid(newValue?.$d)) {
                            setValue(
                              "birthdate",
                              format(newValue?.$d, "yyyy-MM-dd"),
                              { shouldDirty: true }
                            );
                          }
                        }}
                      />
                    </LocalizationProvider>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" gap="16px">
                  <span className={styles.editProfileLabel}>Ваш пол:</span>{" "}
                  <RadioGroup
                    row
                    onChange={(e) => {
                      setValue("gender", e.target.value, { shouldDirty: true });
                    }}
                  >
                    <FormControlLabel
                      value="male"
                      control={
                        <CustomRadio checked={watch("gender") === "male"} />
                      }
                      label={
                        <span className={styles.editProfileLabel}>Мужской</span>
                      }
                    />
                    <FormControlLabel
                      value="female"
                      control={
                        <CustomRadio checked={watch("gender") === "female"} />
                      }
                      label={
                        <span className={styles.editProfileLabel}>Женский</span>
                      }
                    />
                    <FormControlLabel
                      value="not_specified"
                      control={
                        <CustomRadio
                          checked={watch("gender") === "not_specified"}
                        />
                      }
                      label={
                        <span className={styles.editProfileLabel}>
                          Не указывать
                        </span>
                      }
                    />
                  </RadioGroup>
                </Stack>
                <Divider
                  sx={{
                    marginTop: "16px",
                    marginBottom: "16px",
                    background: "var(--Surface-Tag, rgba(137, 160, 83, 0.20))",
                  }}
                />
                <Input
                  helperText={
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p
                        className={styles.formDescription}
                        style={{
                          opacity: "0.5",
                        }}
                      >
                        {watch("description")?.length ?? 0} / 320
                      </p>
                    </Stack>
                  }
                  label="Краткое описание профиля"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  multiline
                  inputProps={{ style: { color: "white" }, maxLength: 320 }}
                  {...register("description")}
                />
              </Stack>
            </Box>
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              padding: "16px 32px",
              width: "100%",
              background: "#181A16",
              zIndex: "1000",
            }}
          >
            <DarkButton
              onClick={() => {
                navigate(`/profile/${meData?.data?.id}`);
              }}
              sx={{
                width: "100%",
              }}
            >
              Назад
            </DarkButton>
            <DefaultButton
              form="edit"
              type="submit"
              sx={{
                width: "100%",
              }}
            >
              Сохранить
              <CheckIcon
                className={styles.buttonIcon}
                sx={{
                  height: "16px",
                  color: "white !important",
                  marginRight: "0 !important",
                  marginLeft: "8px",
                }}
              />
            </DefaultButton>
          </Stack>
        </Stack>
      </Box>
      <EditEmailModal
        open={isEditEmailModal}
        handleClose={() => {
          setIsEditEmailModal(false);
        }}
      />
      <DefaultModal
        open={isEmailChangedModal}
        handleClose={() => setIsEmailChangedModal(false)}
        handleAction={() => setIsEmailChangedModal(false)}
        actionText="Ок"
        title="Изменение e-mail"
        content={
          <>
            Ваши аккаунты успешно объединены. <br />
            <span>
              Ваш текущий e-mail:{" "}
              <span
                style={{
                  color: "#89A053",
                }}
              >
                {watch("email")}{" "}
              </span>
            </span>
          </>
        }
        // content={`Ваши аккаунты успешно объединены${watch("email")}`}
      />
      <EditTelegramModal
        open={isEditTelegramModal}
        isTelegramExists={isTelegramExists}
        handleClose={() => {
          setIsEditTelegramModal(false);
        }}
        meData={meData}
        getMe={getMe}
      />
      <AvatarModal
        open={isAvatarModal}
        handleClose={() => {
          setIsAvatarModal(false);
        }}
        currentAvatarImg={currentAvatarImg}
        getMe={getMe}
      />
    </>
  );
};
