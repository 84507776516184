import { z } from "zod";

export const createTourMainValidation = z.object({
  title: z.string(),
  description: z.string(),
  expenses: z.union([
    z.number({
      message: "Для ввода доступны только числовые значения",
    }).min(0, {
      message: "Для ввода доступны только числовые значения",
    }),
    z.string().optional().transform((value) => (value ? value : undefined)), // Allow empty string
  ]),
  hours: z.union([
    z.number({
      message: "Для ввода доступны только числовые значения",
    }).min(0, {
      message: "Для ввода доступны только числовые значения",
    }),
    z.string().optional().transform((value) => (value ? value : undefined)), // Allow empty string
  ]),
  days: z.union([
    z.number({
      message: "Для ввода доступны только числовые значения",
    }).min(0, {
      message: "Для ввода доступны только числовые значения",
    }),
    z.string().optional().transform((value) => (value ? value : undefined)), // Allow empty string
  ]),
  duplicate_description: z.boolean(),
  short_description: z.boolean(),
});
