import { Box } from "@mui/material";
import React from "react";
import styles from "./Tabber.module.css";

export const TabberItem = ({ title, selected, handleClick, sx }: any) => {
  return (
    <Box
      onClick={handleClick}
      className={styles.tabberItem}
      sx={{
        cursor: "pointer",
        borderRadius: "4px 4px 0px 0px",
        borderBottom: selected ? "2px #7ACA14 solid" : "",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,0.3)",
        },
        ...sx,
      }}
    >
      {title}
    </Box>
  );
};
