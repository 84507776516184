import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const DefaultButton = styled(Button)(({ theme }) => ({
  padding: "10px 16px",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "12px",
  letterSpacing: "0.12px",
  backgroundColor: "#81C728",
  border: "2px solid rgba(0,0,0,0)",
  color: "black",
  "&:hover": {
    background:
      "linear-gradient(0deg, var(--Surface-Hover, rgba(255, 255, 255, 0.20)) 0%, var(--Surface-Hover, rgba(255, 255, 255, 0.20)) 100%), var(--Surface-Button, #81C728)",
    color: "black",
  },
}));

export const DarkBorderButton = styled(Button)(({ theme }) => ({
  padding: "10px 16px",
  border: "1px solid var(--Outline-Button, #89A053)",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "12px",
  letterSpacing: "0.12px",
  color: "white",
  // "&:focus": {
  //   border: "1px solid var(--Outline-Input-enable, rgba(137, 160, 83, 0.50))",
  // },
  "&:hover": {
    border: "1px solid var(--Outline-Button, #89A053)",
    background: "var(--Surface-Hover, rgba(255, 255, 255, 0.20))",
  },
}));

export const DarkButton = styled(Button)(({ theme }) => ({
  padding: "10px 16px",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "12px",
  letterSpacing: "0.12px",
  color: "white",
  "&:hover": {
    background: "var(--Surface-Hover, rgba(255, 255, 255, 0.20))",
  },
  "&:active": {
    border: "1px rgba(0,0,0,0)",
  },
}));
