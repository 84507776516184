import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import "swiper/scss";
import "swiper/css/free-mode";

export const MediaSlider = ({ media }: any) => {
  return (
    <Swiper
      spaceBetween={8}
      freeMode
      loop={true}
      modules={[FreeMode]}
      slidesPerView="auto"
      style={{
        height: "48px",
        width: "100%",
      }}
    >
      {media?.map((item: string, index: number) => (
        <SwiperSlide
          key={index}
          style={{
            minWidth: "48px",
            minHeight: "48px",
            maxWidth: "48px",
            maxHeight: "48px",
            objectFit: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            background: `url(${item}) lightgray 50% / cover no-repeat`,
          }}
        ></SwiperSlide>
      ))}
    </Swiper>
  );
};
