import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Stack } from "@mui/material";
import {
  DarkBorderButton,
  DefaultButton,
} from "../../../components/ui-kit/Button/Button";
import styles from "./EditEmailModal.module.css";
import { Input } from "../../../components/ui-kit/TextField/TextField";
import { useMutationQuery } from "../../../api/useMutationQuery";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useEffect } from "react";
import { DefaultModal } from "../../../components/ui-kit/DefaultModal/DefaultModal";
import CallMergeIcon from "@mui/icons-material/CallMerge";

type Email = {
  email: string;
};

export const EditEmailModal = ({ open, handleClose }: any) => {
  const [successMailSend, setSuccessMailSend] = useState<boolean>(false);
  const [mailDeleteError, setMailDeleteError] = useState<boolean>(false);

  const { mutate: editEmail } = useMutationQuery({
    url: `users/email_change/`,
    method: "POST",
  });

  const { mutate: mergeRequest } = useMutationQuery({
    url: `users/merge_request/`,
    method: "POST",
  });

  const [seconds, setSeconds] = useState<number>(15);
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const [isMailDublicate, setIsMailDublicate] = useState<boolean>(false);

  const [wasSent, setWasSent] = useState<boolean>(false);

  // useEffect(() => {
  //   const socket = new WebSocket("ws://tripper.bulltech.ru/ws/user/");
  //   socket.onopen = () => {
  //     console.log("WebSocket connection established");
  //   };

  //   socket.onmessage = (event) => {
  //     const newMessage = event.data;
  //     console.log(newMessage);
  //   };

  //   socket.onclose = () => {
  //     console.log("WebSocket connection closed");
  //   };

  //   socket.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  useEffect(() => {
    if (seconds > 0 && timerActive) {
      setTimeout(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      setTimerActive(false);
    }
  }, [seconds, timerActive]);

  const regex = /^[0-9a-zA-Z_.:-]+@[a-zA-Z_]+\.[a-zA-Z_]{2,}$/;

  const schema = z.object({
    email: z.string().regex(regex, { message: "Некорректный email" }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<Email>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<Email> = (data) => {
    setSuccessMailSend(false);
    setWasSent(true);

    editEmail(data, {
      onError: (res: any) => {
        if (res?.response?.errors[0]?.message === "Почта уже занята") {
          setIsMailDublicate(true);
        }
      },
    });
    setSuccessMailSend(true);

    setTimerActive(true);
    setSeconds(15);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "366px",
            borderRadius: "20px",
            border: "1px solid rgba(255, 255, 255, 0.00)",

            background: "var(--Surface-Card-2, rgba(255, 255, 255, 0.10))",

            boxShadow: " 0px 8px 12px 0px rgba(0, 0, 0, 0.40)",
            backdropFilter: "blur(8px)",
            padding: "32px",
          },
        }}
        open={open}
      >
        <h2 className={styles.title}>Изменение e-mail</h2>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 18,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <p
          className={styles.content}
          style={{
            paddingTop: "36px",
            paddingBottom: "32px",
            alignSelf: "stretch",
            textWrap: "balance",
          }}
        >
          {isMailDublicate ? (
            <p
              className={styles.content}
              style={{
                alignSelf: "stretch",
                textWrap: "balance",
              }}
            >
              Аккаунт с таким e-mail уже существует. Вы можете объединить
              аккаунты (информация сохранится из обоих), или использовать другой
              e-mail.
            </p>
          ) : successMailSend ? (
            ""
          ) : (
            <p
              className={styles.content}
              style={{
                alignSelf: "stretch",
                textWrap: "balance",
              }}
            >
              Введите новый адрес электронной почт
            </p>
          )}
          {successMailSend && !isMailDublicate && (
            <p
              className={styles.content}
              style={{
                alignSelf: "stretch",
                textWrap: "balance",
              }}
            >
              {" "}
              На вашу почту отправлено письмо со ссылкой, пожалуйста перейдите
              по ней для подтверждения новой почты.
            </p>
          )}
        </p>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Email"
            sx={{
              marginBottom: "32px",
              width: "100%",
            }}
            {...register("email")}
          />

          <Stack gap="12px">
            <DefaultButton type="submit" disabled={timerActive}>
              {!timerActive
                ? wasSent
                  ? "Отправить письмо ещё раз"
                  : "Отправить письмо"
                : "Отправить письмо ещё раз"}{" "}
              {timerActive ? seconds : ""}
              <CheckIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "8px",
                }}
              />{" "}
            </DefaultButton>
            {isMailDublicate && (
              <DefaultButton
                onClick={() => {
                  mergeRequest({
                    source: "email",
                    control: watch("email"),
                    next_url:
                      "http://localhost:3000/edit-profile/?email_changed=true&email=",
                  });
                }}
              >
                Объединить аккаунты
                <CallMergeIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "8px",
                  }}
                />{" "}
              </DefaultButton>
            )}
            <DarkBorderButton
              onClick={() => {
                editEmail(
                  {
                    email: null,
                  },
                  {
                    onSuccess: () => {
                      window.location.reload();
                    },
                    onError: (res: any) => {
                      if (
                        res?.response?.errors[0]?.message ===
                        "Нельзя удалять почту если аккаунт не привязан к Telegram"
                      ) {
                        setMailDeleteError(true);
                      } else {
                        window.location.reload();
                      }
                    },
                  }
                );
              }}
              sx={{
                width: "100%",
              }}
            >
              Открепить почту{" "}
              <CloseIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "8px",
                }}
              />
            </DarkBorderButton>
            <p className={styles.hint}>
              Если вы хотите убрать почту из профиля, нажмите открепить
            </p>
          </Stack>
        </Box>
      </Dialog>
      <DefaultModal
        open={mailDeleteError}
        handleClose={() => setMailDeleteError(false)}
        handleAction={() => setMailDeleteError(false)}
        actionText="Ок"
        title="Изменение e-mail"
        content="Ваши аккаунты успешно объединены. Ваш текущий e-mail: mail@mail.ru"
      />
      <DefaultModal
        open={mailDeleteError}
        handleClose={() => setMailDeleteError(false)}
        handleAction={() => setMailDeleteError(false)}
        actionText="Ок"
        title="Изменение e-mail"
        content="Невозможно открепить e-mail, так как не указан telegram аккаунт. "
      />
    </>
  );
};
